import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";
import { profilebadge, profilebadgeText } from "../components/profile";

export default class extends Controller {
  connect() {
    const locationsData = this.data.get("locations");
    const badge = this.data.get("globalBadge");
    const search_histroy = this.data.get("searchHistroy");
    const tooltip_content = this.data.get("message");
    if (tooltip_content) {
      this.setupTooltip(this.generateCustomTooltipContent(tooltip_content));
    }
    if (locationsData) {
      this.setupTooltip(this.generateLocationsContent(locationsData));
    }
    if (badge) {
      this.setupTooltip(
        this.generateBadgeContent(
          badge,
          this.data?.element?.dataset?.totalBadges,
          this.data?.element?.dataset?.tippyView
        )
      );
    }
    if (search_histroy) {
      this.setupTooltip(this.generateSearchHistoryContent(search_histroy));
    }
  }

  setupTooltip(content) {
    tippy(this.element, {
      content: content,
      allowHTML: true,
      appendTo: () => document.body,
      arrow: true,
      placement: "top",
      interactive: true,
    });
  }

  generateLocationsContent(locationsData) {
    const locations = JSON.parse(locationsData);
    return `
      <div class=" bg-white rounded-[10px]">
        <ul class="list-none inline-flex flex-wrap gap-[10px] m-0 shadow-shadow_two rounded-[10px] p-[12px]">
          ${locations
            .map(
              (location) =>
                `<li> <span class='experencise-tag'>${location.trim()}</span>  </li>`
            )
            .join("")}
        </ul>
      </div>
    `;
  }

  generateBadgeContent(badge, profileBadges, view) {
    if (!profileBadges) return false;

    const parsedBadges = this.parseJSON(profileBadges);
    if (!parsedBadges || parsedBadges?.length == 0) return false;

    if (view == "list") {
      if (parsedBadges.length == 1) {
        return `
        <div class=" bg-white rounded-[6px]">
          <span class="experencise-tag rounded-[6px] tippydark hover:bg-[#051237]! hover:text-[#fff]!">${profilebadgeText(
            parsedBadges[0]
          )}</span>
        </div>`;
      } else {
        return `
        <div class=" bg-white rounded-[6px]">
          <span class="experencise-tag rounded-[6px] tippydark hover:bg-[#051237]! hover:text-[#fff]!">${badge}</span>
        </div>`;
      }
    }

    if (view == "grid" && parsedBadges.length == 1) {
      return `
        <div class=" bg-white rounded-[6px]">
          <span class="experencise-tag rounded-[6px] tippydark hover:bg-[#051237]! hover:text-[#fff]!">${profilebadgeText(
            parsedBadges[0]
          )}</span>
        </div>`;
    }

    let badgeHTML = ``;

    parsedBadges.forEach((badgeNumber) => {
      const { badgeSVG, text } = profilebadge(badgeNumber);
      badgeHTML += `<li>
              <div class="flex justify-start items-center gap-[6px]">
                <span>${badgeSVG}</span>
                <span>${text}</span>
              </div>
            </li>`;
    });

    return `
      <div>
        <div class="rounded-[6px] w-[120px] flex flex-col experencise-tag tippydark hover:bg-[#051237]! hover:text-[#fff]! p-0!">
          <h3 class="w-full text-[12px] p-[8px_10px] font-medium leading-[14px] border-b-[1px] border-[#FFFFFF33] font-text_font text-[#fff] flex items-center justify-between gap-[6px]">
            Badges Earned
            <span class="text-[10px] font-medium leading-[12px] font-text_font text-[#fff] opacity-70">${parsedBadges.length}</span>
          </h3>
          <ul class="flex flex-col gap-[8px] p-[10px]"> ${badgeHTML}</ul>
        </div>
      </div>
    `;
  }

  parseJSON(value) {
    try {
      return JSON.parse(value);
    } catch (error) {
      return null;
    }
  }

  generateSearchHistoryContent(badge) {
    return `<div class="rounded-[6px]"> <span class="tippydark max-w-max inline-block text-center leading-5">${badge}</span> </div>`;
  }

  generateCustomTooltipContent(message) {
    return `<div class="rounded-[6px]"> <span class="tippydark max-w-max inline-block text-center leading-5">${message}</span> </div>`;
  }
}
