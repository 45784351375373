import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["asideDrawer", "asideDrawerChildren", "form"];

  connect() {
    this.timeout = null;
    if (!window.location.href.includes("ai_search")) {
      document.addEventListener("click", this.handleClickOutside.bind(this));
    }
  }

  disconnect() {
    if (!window.location.href.includes("ai_search")) {
      document.removeEventListener("click", this.handleClickOutside.bind(this));
    }
  }

  openDrawer() {
    var body = document.body;
    body.classList.add("overflow-hidden");

    this.asideDrawerTarget.classList.remove("hidden");
    this.asideDrawerTarget.classList.add("flex", "opacity-0");

    void this.asideDrawerTarget.offsetWidth;

    this.asideDrawerTarget.classList.remove("opacity-0");
    this.asideDrawerChildrenTarget.classList.add("translate-x-0!");
  }

  searchByName(event) {
    clearTimeout(this.timeout);
    const value = event.currentTarget.value;

    this.timeout = setTimeout(() => {
      const form =
        document.getElementById("company-filters-form") ||
        this.aiSearchFormElement();
      if (form) {
        form.requestSubmit();
      }
    }, 600);

    const toggleFilterActiveness = (element_id) => {
      const element = document.getElementById(element_id);
      element && value.trim() == ""
        ? element.classList.remove("has-dot")
        : element.classList.add("has-dot");
    };

    toggleFilterActiveness("name-filter-label");
  }

  aiSearchFormElement() {
    if (window.location.href.includes("ai_search")) {
      return document.getElementById("search-filter-form");
    }
  }

  submitDrawerForm(event) {
    document.getElementById("show_results_count").value = false;

    const loading_icon = event.currentTarget.lastElementChild;
    loading_icon.classList.remove("hidden");

    const current_target = event.currentTarget;
    const current_form = document.getElementById("company-filters-form");

    if (!current_form) {
      return;
    }

    let header_input = document.getElementById("search-button");
    let filters_query_search = null;
    if (header_input) {
      header_input = header_input.previousElementSibling;
      filters_query_search = current_form.querySelector("input[name='q']");

      if (header_input && filters_query_search) {
        filters_query_search.value = header_input.value;
      }
    }

    current_target.disabled = true;
    current_form.addEventListener(
      "turbo:submit-end",
      () => {
        this.closeDrawer();
        loading_icon.classList.add("hidden");
        current_target.disabled = false;
        if (header_input && filters_query_search) {
          filters_query_search.value = "";
        }
      },
      { once: true }
    );
    current_form.requestSubmit();
  }

  closeDrawer() {
    this.asideDrawerChildrenTarget.classList.remove("translate-x-0!");
    this.asideDrawerTarget.classList.add("opacity-0");

    setTimeout(() => {
      this.asideDrawerTarget.classList.add("hidden");
      this.asideDrawerTarget.classList.remove("flex", "opacity-0");
      var body = document.body;
      body.classList.remove("overflow-hidden");
    }, 300);
  }

  handleClickOutside(event) {
    if (
      !this.asideDrawerChildrenTarget.contains(event.target) &&
      !event.target.closest(".opendrawer")
    ) {
      this.closeDrawer();
    }
  }

  noOfEmployeesFilter(element_id) {
    const no_of_employees = document.getElementsByClassName("no_of_employees");
    const element = document.getElementById(element_id);
    if (!element) {
      return false;
    }
    let checkedCount = 0;
    Array.from(no_of_employees).forEach((checkbox) => {
      if (checkbox.checked) {
        checkedCount++;
      }
    });
    checkedCount == 0
      ? element.classList.remove("has-dot")
      : element.classList.add("has-dot");
  }

  submitAndActiveFilter(event) {
    const form = event.target.form;
    if (form) {
      form.requestSubmit();
    }
    this.noOfEmployeesFilter("no-of-employees-filter-label");
  }
}
