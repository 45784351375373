import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="portal--navbar"
export default class extends Controller {
  static targets = ["headerLayer", "navbarDropdown", "credit", "searchBar"];

  connect() {
    document.addEventListener("click", function (event) {
      const dropDownButton = document.getElementById("navbarDropDownAction");
      const dropDown = document.getElementById("navbarDropdown");
      if (
        dropDownButton &&
        dropDown &&
        !dropDownButton.contains(event.target) &&
        !dropDown.contains(event.target)
      ) {
        dropDown.classList.remove("show");
      }

      // Credit dropdown
      const toggleButton = document.getElementById("credittoggleDiv");
      const credit =
        this.creditTarget || document.getElementById("creditDropdown");

      if (
        toggleButton &&
        credit &&
        !toggleButton.contains(event.target) &&
        !credit.contains(event.target)
      ) {
        credit.classList.remove("show");
      }
    });

    document.addEventListener("keydown", this.handleKeyPress.bind(this));
  }

  handleKeyPress(event) {
    if ((event.ctrlKey || event.metaKey) && event.key === "k") {
      event.preventDefault();
      this.searchBarTarget.click();
    }
  }

  toggleHeaderOne() {
    if (!this.hasHeaderLayerTarget) {
      return false;
    }
    if (this.headerLayerTarget.classList.contains("-translate-y-full")) {
      this.headerLayerTarget.classList.remove("-translate-y-full");
      this.headerLayerTarget.classList.add("translate-y-0");
    } else {
      this.headerLayerTarget.classList.remove("translate-y-0");
      this.headerLayerTarget.classList.add("-translate-y-full");
    }
  }

  toggleNavbarDropdown(event) {
    if (!this.hasNavbarDropdownTarget) {
      return;
    }
    this.navbarDropdownTarget.classList.contains("show")
      ? this.navbarDropdownTarget.classList.remove("show")
      : this.navbarDropdownTarget.classList.add("show");
  }

  // New method to toggle the Credit dropdown
  toggleCredit(event) {
    if (!this.hasCreditTarget) {
      return;
    }
    this.creditTarget.classList.contains("show")
      ? this.creditTarget.classList.remove("show")
      : this.creditTarget.classList.add("show");
  }
}
