import { Controller } from "@hotwired/stimulus";
import { initSelect2, destroySelect2 } from "../components/select2";

export default class extends Controller {
  connect() {
    if (!this.element.id) return false;
    initSelect2(this.element.id);
  }

  disconnect() {
    destroySelect2(this.element.id);
  }
}
