import { Controller } from "@hotwired/stimulus";
import {
  openModal,
  closeModal,
  closeOnOutsideClick,
} from "../../../components/modal";

export default class extends Controller {
  static targets = [
    "appendSearchedValue",
    "dropdown",
    "input",
    "shortlistFilters",
    "shortlistMoreButton",
    "shortlist_sort_order",
    "shortlist_sort_by",
    "shortlistMoreValues",
  ];

  connect() {
    this.timeout = null;
    document.addEventListener("click", this.handleOutsideClick.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.handleOutsideClick.bind(this));
  }

  shortlistSearch() {
    this.loadTableSkeleton(true);
    const targetDiv = this.appendSearchedValueTarget;
    const targetUl = targetDiv.querySelector("ul");
    const newtargetDiv = this.shortlistMoreValuesTarget;
    const newtargetUl = newtargetDiv.querySelector("ul");

    const namesArray1 = Array.from(targetUl.querySelectorAll("li span")).map(
      (span) => span.textContent.trim().replace(/\s*<i.*<\/i>/, "")
    );

    const namesArray2 = Array.from(newtargetUl.querySelectorAll("li h6")).map(
      (h6) => h6.textContent.trim()
    );

    const namesArray3 = [
      document.getElementById("shortlist-search-input").value,
    ];

    const allNames = [...namesArray1, ...namesArray2, ...namesArray3];
    const name = targetDiv.getAttribute("data-type");
    const fieldId = `shortlist_search_${name}`;
    this.submitSearchForm(fieldId, allNames);
    this.updateFiltersCount(allNames, name);
  }

  searchDropdownForm(event) {
    clearTimeout(this.timeout);
    const value = event.currentTarget.value;
    if (value.trim() === "") {
      return false;
    }

    if (event.key === "Enter") {
      event.preventDefault();
      this.appendSearchValue(event);
    } else {
      this.timeout = setTimeout(() => {
        const form = document.getElementById("shortlist_dropdown_form");
        if (form) {
          form.requestSubmit();
        }
      }, 400);
    }
  }

  appendSearchValue(event) {
    const targetDiv = this.appendSearchedValueTarget;
    const targetUl = targetDiv.querySelector("ul");
    const liElement = event.currentTarget;
    let itemValue = "";
    if (liElement.type == "text") {
      itemValue = liElement.value;
    } else {
      itemValue = liElement.querySelector("h6").textContent.trim();
    }
    targetDiv.classList.remove("hidden");

    const newLi = document.createElement("li");
    newLi.className = "leading-[23px]";

    if (targetUl && targetUl.children.length < 2) {
      newLi.innerHTML = `
        <span class="db-tags text-[#05123799]! bg-[#0512370A]! font-normal! border-0! p-[5px_12px]! rounded-[100px]!">
          ${itemValue}
          <i class="icon-x text-[#05123799] text-[12px]" data-action="click->portal--shortlist--search#removeSearchValue"></i>
        </span>
      `;
      targetUl.appendChild(newLi);
    } else {
      const newtargetDiv = this.hasShortlistMoreValuesTarget
        ? this.shortlistMoreValuesTarget
        : document.getElementById("more_filters");
      const button = this.hasShortlistMoreButtonTarget
        ? this.shortlistMoreButtonTarget
        : document.getElementById("more_filters_button");
      const newtargetUl = newtargetDiv.querySelector("ul");

      button.classList.remove("hidden!");

      newLi.innerHTML = `
        <a class="flex db-profile relative min-h-[44px] items-center gap-[10px] p-[10px_12px]!">
          <div class="flex items-center gap-2.5">
            <h6 class="text-[14px] leading-5 font-normal text-[#051237]/[0.76] font-text_font m-0 line-clamp-1">${itemValue}</h6>
          </div>
          <i class="anron-cross text-[#05123752] text-[12px] ml-auto" data-action="click->portal--shortlist--search#removeSearchValue"></i>
        </a>
      `;
      newtargetUl.appendChild(newLi);

      button.textContent = `${newtargetUl.children.length} More`;
    }
    this.clearSearchInput();
  }

  handleOutsideClick(event) {
    this.hideFilterDropdown(event);

    const dropdown = document.getElementById("search-drop-down");
    if (dropdown && !dropdown.contains(event.target)) {
      this.closeDropdown(dropdown);
    }

    const add_more_filter_btn = document.getElementById("add_more_filter");
    const shortlist_all_filters = document.getElementById(
      "shortlist_all_filters"
    );

    if (add_more_filter_btn && shortlist_all_filters) {
      closeOnOutsideClick(
        add_more_filter_btn,
        shortlist_all_filters,
        event.target
      );
    }
  }

  clearSearchInput() {
    const input = document.getElementById("shortlist-search-input");
    const frame = document.getElementById("search-drop-down");
    if (input) input.value = "";
    if (frame) frame.innerHTML = "";
  }

  hideFilterDropdown(event) {
    const filterDropdownBtn = document.getElementById("shortlist-filter-btn");
    if (!this.hasShortlistFiltersTarget) {
      return;
    }
    closeOnOutsideClick(
      filterDropdownBtn,
      this.shortlistFiltersTarget,
      event.target
    );
  }

  closeDropdown(dropdown) {
    dropdown.innerHTML = "";
  }

  OpenFiltersDropdown() {
    if (!this.hasShortlistFiltersTarget) {
      return false;
    }
    this.shortlistFiltersTarget.classList.toggle("hidden");
  }

  toggleDropdown(target) {
    target.classList.toggle("show");
  }

  openSmartFilterModal() {
    openModal("add-new-filters-popup");
    this.openFiltersDropdown();
  }

  closeSmartFilterModal() {
    closeModal("add-new-filters-popup");
  }

  openFiltersDropdown() {
    if (!this.hasShortlistMoredropdownTarget) {
      return false;
    }
    this.toggleDropdown(this.shortlistMoredropdownTarget);
  }

  openMoreDropdown() {
    if (!this.hasShortlistMoreValuesTarget) {
      return false;
    }
    this.toggleDropdown(this.shortlistMoreValuesTarget);
  }

  handleOnChange(event) {
    const fieldId = `shortlist_search_${event.currentTarget.id}`;
    const value = event.currentTarget.checked ? "true" : "";
    this.loadTableSkeleton(true);
    this.submitSearchForm(fieldId, value);
  }

  keywordSearch(event) {
    const value = event.currentTarget.value;

    clearTimeout(this.timeout);
    this.loadTableSkeleton(true);

    this.timeout = setTimeout(() => {
      this.submitSearchForm("shortlist_keyword_search", value);
    }, 1000);
  }

  loadTableSkeleton(showSkeleton) {
    const table = document.getElementById("shortlistTable");
    if (table && showSkeleton) {
      table.classList.add("istableloading");
    } else {
      table.classList.remove("istableloading");
    }
  }

  submitFiltersForm(event) {
    const modal = document.getElementById("add-new-filters-popup");
    const form = event.currentTarget.form;
    if (form && modal) {
      form.addEventListener("turbo:submit-end", (event) => {
        form.reset();
        closeModal("add-new-filters-popup");
      });
    }
  }

  submitSearchForm(fieldId, value) {
    const hiddenField = document.getElementById(fieldId);
    const form = document.getElementById("shortlist_person_search_form");
    if (hiddenField) {
      hiddenField.value = value;
    }
    if (form) {
      form.addEventListener("turbo:submit-end", (event) => {
        this.loadTableSkeleton(false);
      });

      form.requestSubmit();
    }
  }

  updateFiltersCount(names, type) {
    const fieldId = `shortlist_filter_${type}_count`;
    const length = names.length;
    const filter = document.getElementById(fieldId);

    if (filter && length) {
      filter.textContent = length;
    }
  }

  removeSearchValue(event) {
    const li = event.currentTarget.closest("li");
    if (li) {
      li.remove();
    }
    this.resetSearchCount();
  }

  resetSearchCount() {
    const button = document.getElementById("more_filters_button");
    if (!this.hasShortlistMoreValuesTarget) {
      return;
    }
    const children =
      this.shortlistMoreValuesTarget?.querySelector("ul")?.children;
    console.log(children.length);
    if (children) {
      if (children.length === 0) {
        button.classList.add("hidden!");
      }
      button.innerText = `${children.length} More`;
    }
  }

  clearFilter(event) {
    this.loadTableSkeleton(true);
    const fieldId = `shortlist_search_${event.currentTarget.id}`;
    this.submitSearchForm(fieldId, null);
  }

  applySorting(event) {
    const dataset = event.currentTarget.dataset;
    const hidden1 = document.getElementById("shortlist_sort_by");
    const hidden2 = document.getElementById("shortlist_sort_order");
    if (dataset) {
      hidden1.value = dataset.type;
      hidden2.value = dataset.sortOrder;
      const form = document.getElementById("shortlist_person_search_form");
      if (form) {
        form.requestSubmit();
      }
    }
  }

  UpdateActiveFilters(event) {
    const target = event.currentTarget;
    if (target) {
      const boolean = target.checked ? 1 : 0;
      this.submitPinnedShortListForm(boolean, target.value);
    }
  }

  removeActiveFilter(event) {
    const id = event.currentTarget.dataset.id;
    this.submitPinnedShortListForm(0, id);
  }

  submitPinnedShortListForm(is_pinned, value) {
    document.getElementById("shortlist_active_state").value = is_pinned;
    document.getElementById("shortlist_id_active_form").value = value;
    const form = document.getElementById("activeShortlistForm");
    if (form) {
      form.requestSubmit();
    }
  }

  exportCSV() {
    const modal = document.getElementById("export-popup");
    const form = document.getElementById("shortlist-csv-export-form");
    if (form && modal) {
      form.requestSubmit();
      closeModal("export-popup");
    }
  }

  disabledAction(event) {
    event.preventDefault();
    const disabledItems = document.getElementsByClassName("should_disabled");
    Array.from(disabledItems).forEach((item) => {
      if (event.currentTarget.dataset.tab == "myProjects") {
        item.classList.add("db-disablebtn");
      } else {
        if (event.currentTarget.dataset.disabled == "false") {
          item.classList.remove("db-disablebtn");
        }
      }
    });
  }

  userShortlistTitleSearch(event) {
    const value = event.currentTarget.value;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.submitShortlistTitleSearch(value, "user_shortlist");
    }, 1000);
  }

  shareShortlistTitleSearch(event) {
    const search_value = event.currentTarget.value;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.submitShortlistTitleSearch(search_value, "share_shortlist");
    }, 1000);
  }

  submitShortlistTitleSearch(title, type) {
    const form = document.getElementById("shortlistTitleSearchForm");
    if (form) {
      document.getElementById("shortlist_title_search_type").value = type;
      document.getElementById("shortlist_title_search_title").value = title;

      form.requestSubmit();
    }
  }

  searchStatusDropdown(event) {
    clearTimeout(this.timeout);
    const target = event.currentTarget;
    if (target && target.dataset?.searchType === "status") {
      this.timeout = setTimeout(() => {
        const form = document.getElementById("shortlist_dropdown_form");
        if (form) {
          form.requestSubmit();
        }
      }, 400);
    }
  }
}
