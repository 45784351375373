import { Controller } from "@hotwired/stimulus";
import { showElement, hideElement } from "../components/modal";
import { toggleClearOption } from "../components/filters";

let is_form_submit = true;
var tags = [];
export default class extends Controller {
  static values = {
    jobTitle: Array,
    pastJobTitle: Array,
    skills: Array,
    // companyTitle: Array,
    pastCompanyTitle: Array,
    universityFilter: Array,
    degreeFilter: Array,
    industryFilter: Array,
    stateFilter: Array,
    fullName: String,
    companyFullName: String,
    companyLocation: Array,
  };

  connect() {
    this.timeout = null;
    const filters = [
      "country_filter",
      "language_filter",
      "company_filter",
      // "company_title",
      "university_filter",
      "job_title_filter",
      "degree_filter",
      "industry_filter",
      "state_filter",
      "skill_search",
    ];

    filters.forEach((filter) => $(`#${filter}`).select2());
    this.select2tags();

    const commonSelect2Config = {
      ajax: {
        delay: 250,
        dataType: "json",
        cache: false,
      },
      multiple: true,
      placeholder: "Select an option",
    };

    const createSelect2Config = (url, type) => ({
      ...commonSelect2Config,
      ajax: {
        ...commonSelect2Config.ajax,
        url,
        data: (params) => ({
          q: params.term,
          ...(type && { type }),
        }),
        processResults: (data) => {
          if (data && typeof data === "object" && Array.isArray(data)) {
            return {
              results: data.map((item) => {
                return { id: item[0], text: item[0] };
              }),
            };
          }
          return { results: [] };
        },
      },
    });

    $("#skill_search").select2(createSelect2Config("/search/skills_search"));

    // $("#company_title").select2(
    //   createSelect2Config("/ai_search/index_search", "company_title")
    // );

    $("#past_company_title_filter").select2(
      createSelect2Config("/ai_search/index_search", "company_title")
    );

    $("#company_keywords_filter").select2(
      createSelect2Config("/ai_search/index_search", "company_keyword")
    );

    $("#university_filter").select2(
      createSelect2Config("/search/facet_search", "schools")
    );

    $("#job_title_filter").select2(
      createSelect2Config("/search/facet_search", "position_title")
    );

    $("#past_job_title_filter").select2(
      createSelect2Config("/search/facet_search", "position_title")
    );

    $("#degree_filter").select2(
      createSelect2Config("/search/facet_search", "degrees")
    );

    $("#industry_filter").select2(
      createSelect2Config("/companies/facet_search", "industry")
    );

    $("#state_filter").select2(
      createSelect2Config("/search/facet_search", "state_name")
    );

    if (this.jobTitleValue.length > 0) {
      const jobTitleFilter = $("#job_title_filter");

      this.jobTitleValue.forEach((jobTitle) => {
        if (
          jobTitleFilter.find("option[value='" + jobTitle + "']").length === 0
        ) {
          const newOption = new Option(jobTitle, jobTitle, true, true);
          jobTitleFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.pastJobTitleValue.length > 0) {
      const jobTitleFilter = $("#past_job_title_filter");

      this.pastJobTitleValue.forEach((jobTitle) => {
        if (
          jobTitleFilter.find("option[value='" + jobTitle + "']").length === 0
        ) {
          const newOption = new Option(jobTitle, jobTitle, true, true);
          jobTitleFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.skillsValue.length > 0) {
      const skillFilter = $("#skill_search");
      this.skillsValue.forEach((skill) => {
        if (skillFilter.find("option[value='" + skill + "']").length === 0) {
          const newOption = new Option(skill, skill, true, true);
          skillFilter.append(newOption).trigger("change");
        }
      });
    }

    // if (this.companyTitleValue.length > 0) {
    //   const companyTitleFilter = $("#company_title");
    //   this.companyTitleValue.forEach((companyTitle) => {
    //     if (
    //       companyTitleFilter.find("option[value='" + companyTitle + "']")
    //         .length === 0
    //     ) {
    //       const newOption = new Option(companyTitle, companyTitle, true, true);
    //       companyTitleFilter.append(newOption).trigger("change");
    //     }
    //   });
    // }

    if (this.pastCompanyTitleValue.length > 0) {
      const companyTitleFilter = $("#past_company_title_filter");
      this.pastCompanyTitle.forEach((companyTitle) => {
        if (
          companyTitleFilter.find("option[value='" + companyTitle + "']")
            .length === 0
        ) {
          const newOption = new Option(companyTitle, companyTitle, true, true);
          companyTitleFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.universityFilterValue.length > 0) {
      const universityFilter = $("#university_filter");
      this.universityFilterValue.forEach((university) => {
        if (
          universityFilter.find("option[value='" + university + "']").length ===
          0
        ) {
          const newOption = new Option(university, university, true, true);
          universityFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.degreeFilterValue.length > 0) {
      const degreeFilter = $("#degree_filter");
      this.degreeFilterValue.forEach((degree) => {
        if (degreeFilter.find("option[value='" + degree + "']").length === 0) {
          const newOption = new Option(degree, degree, true, true);
          degreeFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.industryFilterValue.length > 0) {
      const industryFilter = $("#industry_filter");
      this.industryFilterValue.forEach((industry) => {
        if (
          industryFilter.find("option[value='" + industry + "']").length === 0
        ) {
          const newOption = new Option(industry, industry, true, true);
          industryFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.stateFilterValue.length > 0) {
      const stateFilter = $("#state_filter");
      this.stateFilterValue.forEach((state) => {
        if (stateFilter.find("option[value='" + state + "']").length === 0) {
          const newOption = new Option(state, state, true, true);
          stateFilter.append(newOption).trigger("change");
        }
      });
    }

    if (this.companyLocationValue.length > 0) {
      this.submitFormById("company-filters-form");
    }

    if (this.fullNameValue != "") {
      this.submitFormById("search-filter-form");
    }

    if (this.companyFullNameValue != "") {
      this.submitFormById("company-filters-form");
    }

    this.updateYearsFilter();
    this.updateEmployeeRange();
  }

  submitFormById(selected_id) {
    const form = document.getElementById(selected_id);
    if (form && is_form_submit) {
      form.requestSubmit();
      is_form_submit = false;
    }
  }

  disconnect() {
    is_form_submit = true;

    const filters = [
      "country_filter",
      "language_filter",
      "company_filter",
      // "company_title",
      "university_filter",
      "job_title_filter",
      "degree_filter",
      "industry_filter",
      "state_filter",
      "skill_search",
    ];

    const destroySelect2 = (selector) => {
      const element = $(this.element).find(selector);
      if (element.length && $.fn.select2 && element.data("select2")) {
        element.select2("destroy");
      }
    };

    filters.forEach((filter) => {
      destroySelect2(`#${filter}`);
    });
  }

  updateEmployeeRange() {
    const yearsContainer = document.querySelector("[data-emplyees-range]");
    if (!yearsContainer) return;

    const range = yearsContainer.dataset.emplyeesRange;
    if (range == "" || range == "[]") return;

    const form_id = yearsContainer.dataset.formId;
    const form = document.getElementById(form_id);
    if (form) {
      form.requestSubmit();
    }
  }

  updateYearsFilter() {
    const yearsContainer = document.querySelector("[data-years-selected]");
    if (!yearsContainer) return;

    const selectedYears = yearsContainer.dataset.yearsSelected;
    if (selectedYears == "") return;

    const form = document.getElementById("search-filter-form");
    if (form && is_form_submit) {
      form.requestSubmit();
      is_form_submit = false;
    }
  }

  searchByName(event) {
    clearTimeout(this.timeout);
    const value = event.currentTarget.value;

    const filterKey = event.currentTarget.dataset.filterKey;
    const selectorId =
      filterKey == "company_name"
        ? "company_name_active_filter"
        : "full_name_active_filter";
    if (value.trim() == "") {
      $(`#${selectorId}`).removeClass("has-dot");
      toggleClearOption(filterKey, false);
      const form = document.getElementById("search-filter-form");
      if (form) {
        form.requestSubmit();
      }
    } else {
      this.timeout = setTimeout(() => {
        const form = document.getElementById("search-filter-form");
        if (form) {
          form.requestSubmit();
          $(`#${selectorId}`).addClass("has-dot");
          toggleClearOption(filterKey, true);
        }
      }, 600);
    }
  }

  select2tags() {
    var placeholder = "Select an option";

    $(".select2multiple select").each(function (e) {
      const $select = $(this);
      $select
        .select2({
          placeholder: placeholder,
        })
        .on("select2:select", function (e) {
          const selector = e.target.parentNode.parentNode.nextElementSibling;
          if (!tags[selector.id]) {
            tags[selector.id] = [];
          }

          const __selector = format_selector(selector.id);
          isFilterActive(__selector, selector.id, e.target?.dataset?.filterKey);

          prepare_tags_array(selector.id);
          const exists = tags[selector.id].some(
            (tag) => tag.id === e.params.data.id
          );
          if (!exists) {
            tags[selector.id].push({
              value: e.params.data.text,
              id: e.params.data.id,
              key: selector.id,
            });
          }

          updateCustomPlaceholder($select);
          displayTags(
            selector,
            e.target?.dataset?.filterKey,
            e.currentTarget.id
          );
        })
        .on("select2:unselect", function (e) {
          const selector = e.target.parentNode.parentNode.nextElementSibling;
          if (tags[selector.id]) {
            const indexToRemove = tags[selector.id].findIndex(
              (tag) => tag.id === e.params.data.id
            );
            if (indexToRemove !== -1) {
              tags[selector.id].splice(indexToRemove, 1);
            }

            const $select = $(
              `.select2multiple #${e.currentTarget.id.replace("_tags", "")}`
            );
            updateCustomPlaceholder($select);
            displayTags(
              selector,
              e.target?.dataset?.filterKey,
              e.currentTarget.id
            );
          }
          clearDisplayTags(
            e.target.dataset.filterKey,
            e.currentTarget.id,
            e.params.data.id
          );
        });
    });

    $(".tags-area").on("click", ".tag", function (e) {
      const selectorId = e.target.parentNode.dataset.element;
      const tagIdToRemove = e.target.parentNode.dataset.select;
      prepare_tags_array(selectorId);

      const __selector = format_selector(selectorId);
      isFilterActive(
        __selector,
        selectorId,
        e.currentTarget?.dataset?.filterKey
      );

      const check_keys = Object.keys(tags);
      if (check_keys.length == 0) {
        return false;
      }

      const indexToRemove = tags[selectorId].findIndex(
        (tag) => tag.id === tagIdToRemove
      );

      if (indexToRemove !== -1) {
        tags[selectorId].splice(indexToRemove, 1);
      }
      const $select = $(`.select2multiple #${selectorId.replace("_tags", "")}`);
      let selectedValues = $select.val() || [];
      selectedValues = selectedValues.filter((val) => val !== tagIdToRemove);
      $select.val(selectedValues).trigger("change");

      e.target.parentNode.remove();

      const remain = tags[selectorId].map((item) => item.id);
      $(`#${e.currentTarget.dataset.select2Id}`).val(remain).trigger("change");
      const search_filter_form = document.getElementById("search-filter-form");
      if (search_filter_form) {
        search_filter_form.requestSubmit();
      }

      return false;
    });

    function clearDisplayTags(filterKey, selector, text) {
      const allSelector = document.querySelectorAll(
        `a[data-select2-id="${selector}"]`
      );

      if (allSelector.length > 0) {
        allSelector.forEach((element) => {
          if (element.textContent.trim() === text.trim()) {
            element.remove();
          }
        });
      }
      const allSelectors = document.querySelectorAll(
        `a[data-select2-id="${selector}"]`
      );
      if (allSelectors.length == 0) {
        const activeness = format_selector(selector.trim() + "_tags");
        if (activeness) {
          $(`#${activeness}`).removeClass("has-dot");
        }
        toggleClearOption(filterKey, false);
      }
    }

    function displayTags(element, filterKey = null, select2ID) {
      const check_keys = Object.keys(tags);
      if (check_keys.length == 0) {
        return false;
      }
      element.innerHTML = "";
      tags[element.id].forEach((tag) => {
        if (tag.key == element.id) {
          element.innerHTML += `<a href="javascript:void(0)" class="tag" data-select="${tag.id}" data-element="${element.id}" data-select2-id="${select2ID}" data-filter-key="${filterKey}">
          <span class="value">${tag.value}</span> <i class='anron-cross text-[15px] text-[#05123752]'></i>
          </a>`;
        }
      });
    }

    function prepare_tags_array(selectorId) {
      if (!tags[selectorId]) {
        tags[selectorId] = [];
      }
      const tag_items = document.getElementsByClassName("tag");
      if (tag_items.length > 0) {
        for (const item of tag_items) {
          const value = item.dataset.select;

          const find_value = tags[item.dataset.element]
            ? tags[item.dataset.element].find((tag) => tag.id === value)
            : undefined;
          if (!find_value && tags[item.dataset.element]) {
            tags[item.dataset.element].push({
              value: value,
              id: value,
              key: item.dataset.element,
            });
          }
        }
      }
    }

    function updateCustomPlaceholder($select) {
      const selectedValues = $select.val();
      const val =
        selectedValues && selectedValues.length > 0
          ? selectedValues[0] + " (" + selectedValues.length + ")"
          : placeholder;

      $select.next().find(".select2-selection__custom").html(val);
    }

    function format_selector(selector) {
      if (
        selector == "job_title_filter_tags" ||
        selector == "past_job_title_filter_tags"
      ) {
        return "job_title_active_filter";
      }

      if (selector == "skill_search_tags") {
        return "skills_active_filter";
      }

      if (selector == "language_filter_tags") {
        return "languages_active_filter";
      }

      if (selector == "language_filter_tags") {
        return "languages_active_filter";
      }

      if (selector == "university_filter_tags") {
        return "university_active_filter";
      }

      if (
        selector == "company_title_tags" ||
        selector == "past_company_title_filter_tags"
      ) {
        return "company_name_active_filter";
      }

      if (selector == "degree_filter_tags") {
        return "field_of_study_active_filter";
      }

      if (selector == "state_tags") {
        return "state_active_filter";
      }

      if (selector == "country_filter_tags") {
        return "location_active_filter";
      }

      if (selector == "state_filter_tags") {
        return "state_active_filter";
      }

      if (selector == "industry_filter_tags") {
        return "industry_active_filter";
      }

      if (
        selector == "industries_filter_tags" ||
        selector == "company_keywords_filter_tags"
      ) {
        return "industries_active_filter";
      }

      return selector;
    }

    function isFilterActive(is_active, selector, filterKey = null) {
      const elements = document.querySelectorAll(
        `[data-element="${selector}"]`
      );

      if (elements.length == 0) {
        $(`#${is_active}`).removeClass("has-dot");
        toggleClearOption(filterKey, false);
      } else {
        $(`#${is_active}`).addClass("has-dot");
        toggleClearOption(filterKey, true);
      }
    }
  }

  select2TagsKey(filterKey) {
    if (
      filterKey == "industries" &&
      document.getElementById("company_keywords_checkbox").checked
    ) {
      return "company_keywords_filter_tags";
    }
    if (
      filterKey == "industries" &&
      !document.getElementById("company_keywords_checkbox").checked
    ) {
      return "industries_filter_tags";
    }

    if (
      filterKey == "job_title" &&
      document.getElementById("job_title_is_any_of").checked &&
      !document.getElementById("past_job_title_checkbox").checked
    ) {
      return "job_title_filter_tags";
    }
    if (
      filterKey == "job_title" &&
      document.getElementById("job_title_is_any_of").checked &&
      document.getElementById("past_job_title_checkbox").checked
    ) {
      return "past_job_title_filter_tags";
    }

    if (
      filterKey == "company_name" &&
      document.getElementById("is_any_of").checked &&
      !document.getElementById("past_company_title_checkbox").checked
    ) {
      return "company_title_tags";
    }
    if (
      filterKey == "company_name" &&
      document.getElementById("is_any_of").checked &&
      document.getElementById("past_company_title_checkbox").checked
    ) {
      return "past_company_title_filter_tags";
    }

    switch (filterKey) {
      case "university":
        return "university_filter_tags";
      case "languages":
        return "language_filter_tags";
      case "skills":
        return "skill_search_tags";
      case "field_of_study":
        return "degree_filter_tags";
      default:
        return null;
    }
  }

  clearFilter(event) {
    event.preventDefault();
    event.stopPropagation();

    let filterKey = event.target.dataset.filterKey;
    const tagKey = this.select2TagsKey(filterKey);

    if (tagKey) {
      delete tags[tagKey];
    }

    if (
      [
        "skills",
        "location",
        "languages",
        "country_codes",
        "industry_filter",
        "industries",
        "country_filter",
        "field_of_study",
        "university",
        "job_title",
        "past_job_title_filter",
      ].includes(filterKey)
    ) {
      const select =
        event.currentTarget.parentNode.nextElementSibling.querySelector(
          "select"
        );

      if (filterKey == "industries") {
        $(`#industry_filter`).val([]).trigger("change");
        $(`#industries_filter_tags`).html("");
      } else {
        $(`#${select.id}`).val([]).trigger("change");
        $(`#${select.id}_tags`).html("");
      }

      if (filterKey == "job_title") {
        const is_any_ofs = document.querySelectorAll(
          'input[name="job_title_is_known"]'
        );
        if (is_any_ofs.length > 0) {
          is_any_ofs.forEach((is_any_of) => {
            is_any_of.checked = false;
          });
        }

        const postJobTitleCheckbox = document.getElementById(
          "past_job_title_checkbox"
        );
        if (postJobTitleCheckbox) {
          postJobTitleCheckbox.checked = false;
        }

        const pastJobTitleFilterTags = document.getElementById(
          "past_job_title_filter_tags"
        );
        if (pastJobTitleFilterTags) {
          pastJobTitleFilterTags.innerHTML = "";
        }

        hideElement("past_job_title_div");
        $(`#past_job_title_filter`).val([]).trigger("change");
      }

      if (filterKey == "company_name") {
        const is_any_ofs = document.querySelectorAll(
          'input[name="company_is_known"]'
        );
        if (is_any_ofs.length > 0) {
          is_any_ofs.forEach((is_any_of) => {
            is_any_of.checked = false;
          });
        }

        const postCompanyTitleCheckbox = document.getElementById(
          "past_company_title_checkbox"
        );
        if (postCompanyTitleCheckbox) {
          postCompanyTitleCheckbox.checked = false;
        }

        const pastCompanyTitleFilterTags = document.getElementById(
          "past_company_title_filter_tags"
        );
        if (pastCompanyTitleFilterTags) {
          pastCompanyTitleFilterTags.innerHTML = "";
        }

        hideElement("past_company_title_div");
        $(`#past_company_title_filter`).val([]).trigger("change");
      }

      if (filterKey == "industries") {
        const companyKeywordCheckbox = document.getElementById(
          "company_keywords_checkbox"
        );
        if (companyKeywordCheckbox) {
          companyKeywordCheckbox.checked = false;
        }

        const companyKeywordFilterTags = document.getElementById(
          "company_keywords_filter_tags"
        );
        if (companyKeywordFilterTags) {
          companyKeywordFilterTags.innerHTML = "";
        }

        hideElement("company_keywords_div");
        $(`#company_keywords_filter`).val([]).trigger("change");
      }
    }

    if (
      ["company_name_filter", "full_name", "company_name"].includes(filterKey)
    ) {
      if (filterKey == "full_name") {
        filterKey = "full_name_filter";
      }

      if (filterKey == "company_name") {
        filterKey = "company_title";
      }
      const element = document.getElementById(filterKey);
      if (element) {
        element.value = "";
        element.form.requestSubmit();
      }
    }

    if (
      [
        "profile_features",
        "score",
        "years_of_experience",
        "company_size",
        "no_of_employees",
        "contact_info",
        "education_level",
      ].includes(filterKey)
    ) {
      $(`.${filterKey}`).prop("checked", false);
      this.submitFilterForm();
    }

    if (["location_radius"].includes(filterKey)) {
      const location_input = document.getElementById("search-location-input");
      if (location_input) {
        location_input.value = "";
      }

      const location_tags = document.getElementById("location_radius_tags");
      if (location_tags) {
        location_tags.innerHTML = "";
      }

      const location_data = document.getElementById("location_radius_data");
      if (location_data) {
        location_data.value = "";
      }

      this.submitFilterForm();

      const radiusSlider = document.getElementById("db-rangeslider");
      if (radiusSlider) {
        radiusSlider.noUiSlider.set(5);
      }
    }

    const span_tag = event.currentTarget.previousElementSibling;
    if (span_tag) {
      span_tag.classList.remove("has-dot");
    }
    toggleClearOption(filterKey, false);
  }

  submitFilterForm() {
    const search_filter_form = document.getElementById("search-filter-form");
    if (search_filter_form) {
      search_filter_form.requestSubmit();
    }
  }

  toggleSearchFilter(event) {
    const contentWrapper = event.target?.parentNode?.querySelector(
      ".faq-drawer__content-wrapper"
    );
    if (contentWrapper) {
      if (event.target.checked) {
        contentWrapper.classList.add("overflow-visible!");
      } else {
        contentWrapper.classList.remove("overflow-visible!");
      }
    }
  }
}
