import { Controller } from "@hotwired/stimulus";
import { closeModal } from "../components/modal";
export default class extends Controller {
  static targets = ["phoneInput"];

  connect() {
    this.initializeIntlTelInput();
  }

  initializeIntlTelInput() {
    const phone = document.getElementById("contactPhoneInput");
    if (phone) {
      import(
        "https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.1/build/js/utils.js"
      ).then(() => {
        this.intlTelInput = window.intlTelInput(phone, {
          initialCountry: "auto",
          geoIpLookup: (success, failure) => {
            fetch("https://ipinfo.io/json")
              .then((res) => res.json())
              .then((data) => success(data.country))
              .catch(() => failure());
          },
          loadUtils: () =>
            import(
              "https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.1/build/js/utils.js"
            ),
          nationalMode: false,
          formatOnDisplay: true,
          separateDialCode: true,
          strictMode: true,
        });
      });
    }
  }

  formatPhoneNumber(event) {
    const num = this.intlTelInput.getNumber(
      intlTelInput.utils.numberFormat.INTERNATIONAL
    );
    const isValidNumber = this.intlTelInput.isValidNumber();
    const phoneError = document.getElementById("contact-phone-number-error");
    if (!isValidNumber) {
      event.preventDefault();
      phoneError.classList.remove("hidden");
    } else {
      document.getElementById("formattedNumber").value = num;
      phoneError.classList.add("hidden");
      closeModal("edit-contact-info-modal");
    }
  }
}
