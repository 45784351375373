import { Controller } from "@hotwired/stimulus";
import {
  closeOnOutsideClick,
} from "../../../components/modal";
import { toast } from "../../../components/snackbar";


export default class extends Controller {
  static targets = ["internalShareDropdown", "selectedList", "shareInternalShortlistSearchInput"];

  connect() {
    document.addEventListener("click", (event) => {
      if (this.hasInternalShareDropdownTarget) {
        closeOnOutsideClick(
        this.shareInternalShortlistSearchInputTarget,
        this.internalShareDropdownTarget,
        event.target
      );
      }
    });
  }
  searchTeamMembers(event) {
    event.preventDefault();
    const shortlist_id = document.getElementById("shareShortlistSelectedId").value;
    if (!shortlist_id){
      toast("Please select a shortlist", "error");
      return;
    };
    const form = event.currentTarget.form;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      form.requestSubmit();
    }, 1000);
  }

  shareInternalShortlist(event) {
    const frame = document.getElementById("share-team-dropdown");

   const target = event.currentTarget;
   const id = target.dataset.id;
   const form = document.getElementById("createSharedShortlistForm");
    if (form && frame) {
      frame.innerHTML = "";
      document.getElementById("shareShortlistSelectedUsers").value = id;
      form.requestSubmit();
      toast("Your shortlist has been shared", "success");
    }
  }
}