import { Controller } from "@hotwired/stimulus";
import {
  openModal,
  closeModal,
  closeOnOutsideClick,
} from "../../components/modal";
import { toast } from "../../components/snackbar";
import tippy from "tippy.js";
import {checkedCheckboxesCount, getCheckedValues} from '../../components/validation';

select_all_from_listing = false;
let enrichButtonTippyInstance = null;
let shortlistPersonId = null;

export default class extends Controller {
  static values = { menuId: String };

  static targets = [
    "enrichmentButton",
    "enrichmentDropdown",
    "shortlistCount",
    "shortlistTotalCount",
    "shortlistCountDiv",
  ];

  connect() {
    if (enrichButtonTippyInstance == null) {
      this.initTippy();
    }

    if (!this.hasGlobalListeners) {
      // document.addEventListener("click", this.closeAllDropdowns.bind(this));
      window.addEventListener("scroll", this.repositionDropdowns.bind(this));
      this.hasGlobalListeners = true;
    }

    document.addEventListener("click", function (event) {
      document.querySelectorAll("[data-dropdown-target]").forEach((menu) => {
        if (menu.classList.contains("shortlist-status")) {
          menu.classList.add("hidden");
        } else {
          if (!menu.contains(event.target)) {
            menu.classList.add("hidden");
          }
        }
      });

      const enrichmentButton = document.getElementById("enrichmentButton");
      const enrichmentDropdown = document.getElementById("enrichment-dropdown");
      closeOnOutsideClick(enrichmentButton, enrichmentDropdown, event.target);
    });

    const all_checkboxes = document.querySelectorAll(".shortlist-checkbox");
    if (all_checkboxes.length > 0) {
      all_checkboxes.forEach((checkbox, index) => {
        checkbox.addEventListener("change", (event) => {
          this.hightLightCheckBoxes(false);
          this.toggleEnrichTippy(event.target.checked);
          this.getShortlistPeopleIds();
          this.toggglDeleteButton();
        });
      });
    }
  }

  disconnect() {
    if (this.element.dataset.controllerInstances === "1") {
      document.removeEventListener("click", this.closeAllDropdowns.bind(this));
      window.removeEventListener("scroll", this.repositionDropdowns.bind(this));
    }
  }

  toggle(event) {
    event.stopPropagation();
    const button = event.currentTarget;
    const menuId =
      this.menuIdValue || button.getAttribute("data-dropdown-toggle");
    const menu = document.getElementById(menuId);

    if (!menu) return;

    const parentDivId = "shortlist-dropdown-list";
    let parentDiv = document.getElementById(parentDivId);

    if (!parentDiv) {
      parentDiv = document.createElement("div");
      parentDiv.id = parentDivId;
      parentDiv.className = "shortlist-dropdown-list";
      parentDiv.setAttribute(
        "data-controller",
        "portal--shortlist portal--dropdown portal--shortlist--search"
      );
      document.body.appendChild(parentDiv);
    }

    if (!parentDiv.contains(menu)) {
      parentDiv.appendChild(menu);
    }

    const isAlreadyOpen = !menu.classList.contains("hidden");

    this.closeAllDropdowns();
    if (!isAlreadyOpen) {
      if (menu.classList.contains("shortlist-dropdown")) {
        const allPhoneDropdowns =
          document.getElementsByClassName("shortlist-dropdown");

        Array.from(allPhoneDropdowns).forEach((e) => {
          e.classList.add("hidden");
        });
      }
      menu.classList.remove("hidden");
      this.updateDropdownPosition(button, menu);
    } else {
      menu.classList.add("hidden");
    }
  }

  closeAllDropdowns() {
    document.querySelectorAll("[data-dropdown-target]").forEach((menu) => {
      if (menu.classList.contains("shortlist-status")) {
        menu.classList.add("hidden");
      } else {
        if (!menu) {
          menu.classList.add("hidden");
        }
      }
    });
  }

  repositionDropdowns() {
    document.querySelectorAll("[data-dropdown-target]").forEach((menu) => {
      if (!menu.classList.contains("hidden")) {
        const buttonId = menu.getAttribute("aria-labelledby");
        const button = document.querySelector(
          `[data-dropdown-toggle="${buttonId}"]`
        );
        if (button) {
          this.updateDropdownPosition(button, menu);
        }
      }
    });
  }

  updateDropdownPosition(button, dropdownMenu) {
    const buttonRect = button.getBoundingClientRect();
    const menuRect = dropdownMenu.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let top = buttonRect.bottom + window.scrollY + 10;
    let left = buttonRect.left + window.scrollX - 10;

    if (menuRect.width > viewportWidth - buttonRect.left) {
      left = viewportWidth - menuRect.width - 10;
    }
    if (menuRect.height > viewportHeight - buttonRect.bottom) {
      top = buttonRect.top + window.scrollY - menuRect.height - 10;
    }
    if (top < 0) top = 10;
    if (left < 0) left = 10;

    dropdownMenu.style.top = `${top}px`;
    dropdownMenu.style.left = `${left}px`;
    dropdownMenu.style.position = "absolute";
    dropdownMenu.style.zIndex = 1000;
  }

  openStatusModal(event) {
    const shortlist = event.currentTarget.dataset.shortlist;
    document.getElementById("shortlist_person_id").value = shortlist;
    openModal("add-new-status-popup");
  }

  closeStatusModal() {
    closeModal("add-new-status-popup");
  }

  submitStatusForm(event) {
    const modal = document.getElementById("add-new-status-popup");
    const form = event.currentTarget.form;
    if (form && modal) {
      form.addEventListener("turbo:submit-end", (event) => {
        form.reset();
        closeModal("add-new-status-popup");
      });
    }
  }

  selectAllCheckbox(event) {
    this.selectAllTableCheckboxes(event.currentTarget.checked);

    if (!this.hasShortlistCountDivTarget) {
      return;
    }

    const { currentPageCount, totalCount } = this.getCounts();
    this.shortlistCountTarget.innerText = `(${currentPageCount})`;
    Array.from(this.shortlistTotalCountTargets).map((target) => {
      if (totalCount == 0) {
        target.parentElement.classList.add("hidden");
      } else {
        target.classList.remove("hidden");
        target.innerText = totalCount;
      }
    });
    this.shortlistCountDivTarget.classList.toggle("hidden");

    this.getShortlistPeopleIds();
    this.toggleEnrichTippy(event.currentTarget.checked);
    this.hightLightCheckBoxes(false);
    this.toggglDeleteButton();
  }

  getCounts() {
    const totalRowsInTable = document.querySelectorAll(
      "#shortlist-table-body tr"
    ).length;
    const currentPageCount =
      document.getElementById("pagy-count")?.innerText ?? totalRowsInTable;
    const totalCount =
      document.getElementById("pagy-total-count")?.innerText ?? 0;

    return { currentPageCount, totalCount };
  }

  toggleSelectAll() {
    const select_all_dropdown = this.selectAllDropdownTarget;
    document.body.appendChild(select_all_dropdown);
    select_all_dropdown.classList.remove("hidden");
  }

  selectAll(event) {
    const parentCheckbox = document.getElementById("parent-checkbox");
    const selectAllDropdown = document.getElementById("select-all-dropdown");

    this.selectAllTableCheckboxes(true);
    parentCheckbox.checked = true;

    select_all_from_listing =
      event.currentTarget.dataset.type === "select-all" ? true : false;

    if (selectAllDropdown) {
      selectAllDropdown.classList.add("hidden");
    }
  }

  selectAllTableCheckboxes(toggle) {
    const all_checkboxes =
      document.getElementsByClassName("shortlist-checkbox");
    Array.from(all_checkboxes).forEach((checkbox) => {
      checkbox.checked = toggle;
    });
  }

  enrichContact(event) {
    const person_keys = this.getPersonKeys();
    if (person_keys.length == 0) {
      toast("Please select at least one person.", "error");
      return false;
    }
    const shortlist_peoples_id = document.getElementById(
      "shortlist_peoples_id"
    );
    const enrich_select_all = document.getElementById("enrich_select_all");
    const enrichment_type = document.getElementById("enrichment_type");
    const enrichment_dropdown = document.getElementById("enrichment-dropdown");
    const credit_modal = document.getElementById("load_credit_modal");
    const form = document.getElementById("enrichment-form");

    if (
      !["shortlist_phone", "shortlist_email", "email", "phone"].includes(
        event.currentTarget.dataset.enrichment
      )
    ) {
      return false;
    }

    shortlist_peoples_id.value = JSON.stringify(person_keys);
    enrich_select_all.value = select_all_from_listing;
    enrichment_type.value = event.currentTarget.dataset.enrichment;
    credit_modal.value = event.currentTarget.dataset.credit;
    form.requestSubmit();
    this.closeCreditUsageModal();
    enrichment_dropdown.classList.add("hidden");
  }

  getPersonKeys() {
    const all_checkboxes =
      document.getElementsByClassName("shortlist-checkbox");

    return Array.from(all_checkboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.dataset.key);
  }

  openEnrichmentDropdown(event) {
    const person_keys = this.getPersonKeys();
    if (person_keys.length == 0) {
      this.hightLightCheckBoxes(true);
      return false;
    }
    if (!this.hasEnrichmentDropdownTarget) {
      return;
    }
    this.hightLightCheckBoxes(false);
    this.enrichmentDropdownTarget.classList.toggle("hidden");
  }

  selectAllRecords(event) {
    const type = event.currentTarget.dataset.type;
    const shortlist_person_id = document.getElementById("shortlist_person_id");
    const shortlist_peoples_id = document.getElementById(
      "shortlist_peoples_id"
    );

    event.currentTarget.classList.add("hidden");
    if (type === "select-all") {
      event.currentTarget.nextElementSibling.classList.remove("hidden");
      if (shortlist_person_id){
        shortlist_person_id.value = "";
      }
      shortlist_peoples_id.value = "";
      select_all_from_listing = true;
    } else {
      this.getShortlistPeopleIds();
      select_all_from_listing = false;
      event.currentTarget.previousElementSibling.classList.remove("hidden");
    }
    this.selectionState();
    this.toggglDeleteButton(type);
  }

  selectionState() {
    if (!this.hasShortlistCountDivTarget) {
      return;
    }
    this.shortlistCountDivTarget.children[0].classList.toggle("hidden");
    this.shortlistCountDivTarget.children[1].classList.toggle("hidden");
  }

  getShortlistPeopleIds() {
    const shortlist_person_id = document.getElementById("shortlist_person_id");
    const all_checkboxes =
      document.getElementsByClassName("shortlist-checkbox");
    const enrich_select_all = document.getElementById("enrich_select_all");
    const shortlist_peoples_id = document.getElementById(
      "shortlist_peoples_id"
    );

    enrich_select_all.value = select_all_from_listing;

    const ids = Array.from(all_checkboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.dataset.key);
    if (shortlist_person_id){
      shortlist_person_id.value = JSON.stringify(ids);
    }
    shortlist_peoples_id.value = JSON.stringify(ids);
  }

  initTippy() {
    enrichButtonTippyInstance = tippy("#enrichmentButton", {
      content: this.tippyContent(),
      allowHTML: true,
      appendTo: () => document.body,
      theme: "light",
      arrow: false,
      placement: "bottom",
      interactive: true,
      trigger: "click",
    });
  }

  tippyContent() {
    return `<div class="flex gap-2">
        <div class="max-w-max inline-flex gap-[10px] border-l-[4px]  border-[#f87171] justify-start items-center bg-[#fef2f2] text-[#ef4444] p-[10px] rounded-[4px] shadow-shadow_eight  text-center leading-5 font-text_font">
          <span class="inline-flex w-[16px] h-[16px] text-left p-[2px] items-left justify-start rounded-[50%] cursor-pointer bg-[#F04438]">
            <i class="anron-cross text-[#fff] text-[12px]"></i>
          </span>
          <span class="text-left">Please select at least one profile to enrich</span>
        </div>
      </div>`;
  }

  toggleEnrichTippy(flag) {
    if (enrichButtonTippyInstance == null) return;
    if(flag) {
      enrichButtonTippyInstance[0].disable()
    }else{
      const checkedCount = checkedCheckboxesCount('shortlist-checkbox');
      checkedCount == 0 && enrichButtonTippyInstance[0].enable();
    }
  }

  hightLightCheckBoxes(flag) {
    const allCheckBoxes = document.querySelectorAll(".shortlist-checkbox");

    allCheckBoxes.forEach((checkbox) => {
      checkbox.style.border = flag ? "1px solid #F04438" : "";
    });
  }

  toggglDeleteButton(type = "select") {
    const deleteButton = document.getElementById("shortlistDeleteAllButton");
    if (!deleteButton) {
      return;
    }

    const checkedCount = checkedCheckboxesCount('shortlist-checkbox');
    if (checkedCount > 0 && type !== "select-all") {
      deleteButton.classList.remove("hidden!");
    } else {
      deleteButton.classList.add("hidden!");
    }
    shortlistPersonId = null;
  }

  deleteShortlistPeople() {
    let values = null;
    if (shortlistPersonId){
       values = [shortlistPersonId];
    }else{
      values = getCheckedValues("shortlist-checkbox", "data-key");
    }
    this.submitdelteShortlistPersonForm(values);
  }

  submitdelteShortlistPersonForm(values) {
    const form = document.getElementById("shortlist_person_delete_form");

    if (form && values.length > 0) {
      form.querySelector("#delete_person_ids").value = JSON.stringify(values);
      form.requestSubmit();
    }
    shortlistPersonId = null;
  }

  openDeleteShortlistModal(event) {
    const dataset = event.currentTarget.dataset;
    const spanElement = document.getElementById("shortlist_person_delete_message");
    const name = dataset.name;
    const shortlist = dataset.shortlist;
    shortlistPersonId = dataset.id;
    spanElement.innerHTML = `Are you sure you want to remove <strong>${name}</strong> from the <strong>${shortlist}</strong>?`;
    openModal("delete-shortlist--people-modal");
  }

  closeDeleteShortlistModal() {
    closeModal("delete-shortlist--people-modal");
    shortlistPersonId = null;
  }

  openDeleteShortlistPeopleModal(event){
    const name = event.currentTarget.dataset.name;
    const spanElement = document.getElementById("shortlist_person_delete_message");
    spanElement.innerHTML = `Are you sure you want to remove selected people from the <strong>${name}</strong>?`;
    openModal("delete-shortlist--people-modal");

  }

  closeCreditUsageModal() {
    document.getElementById("credit-usage-modal-frame").innerHTML = "";
  }

  GenerateToplinerate(event) {
    const person_keys = this.getPersonKeys();
    if (person_keys.length == 0) {
      toast("Please select at least one person.", "error");
      return false;
    }

    const shortlist_peoples_id = document.getElementById("topline_rate_shortlist_peoples_id");
    const enrich_select_all = document.getElementById("rate_enrich_select_all");
    const enrichment_dropdown = document.getElementById("enrichment-dropdown");
    const credit_modal = document.getElementById("topline_rate_credit_modal");

    shortlist_peoples_id.value = JSON.stringify(person_keys);
    enrich_select_all.value = select_all_from_listing;
    credit_modal.value = event.currentTarget.dataset.credit;
    this.closeCreditUsageModal();
    enrichment_dropdown.classList.add("hidden");

    const form = document.getElementById("shortlistPersonToplineRateForm");
    if (form) {
      form.requestSubmit();
    }
  }
}
