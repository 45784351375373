import { Controller } from "@hotwired/stimulus";

let single_profile_id = null;

export default class extends Controller {
  static targets = [
    "customDropDown",
    "dropDownTitle",
    "dropDownCustomCheckbox",
    "saveAndAssignButton",
    "connectTest",
    "selectAllLabel",
    "categorySelectAllTitle",
  ];
  connect() {
    $(this.element).find("#select5").select2();
  }

  disconnect() {
    $(this.element).find("#select5").select2("destroy");
  }

  showManageTagsModal(event) {
    const parameter = event.currentTarget.dataset.parameter;

    if (parameter) {
      const saveAndAssignButton = document.getElementById(
        "saveAndAssignButton"
      );
      if (parameter === "manage") {
        saveAndAssignButton.classList.add(
          "disabled:opacity-50",
          "disabled:cursor-not-allowed"
        );
        saveAndAssignButton.setAttribute("disabled", true);
      } else {
        saveAndAssignButton.classList.remove(
          "disabled:opacity-50",
          "disabled:cursor-not-allowed"
        );
        saveAndAssignButton.removeAttribute("disabled");
      }
    }
    this.handleEmptyCase();
    this.showAssignTagModal();
  }

  handleEmptyCase() {
    let count = 0;
    $("#people_manage_tags_list > div").each(function () {
      count += $(this).length;
    });
    if (count == 0) {
      $("#no-folders").removeClass("hidden");
      $("#folder-listing").addClass("hidden");
    } else {
      $("#no-folders").addClass("hidden");
      $("#folder-listing").removeClass("hidden");
    }
  }

  showAssignTagModal() {
    document.getElementById("manage-tag-modal").classList.remove("hidden");
    document
      .getElementById("tagForm")
      .setAttribute("action", `${window.location.origin}/tags`);
    const errorMessage = document.getElementById("showErrorMessage");
    errorMessage.textContent = "";
    single_profile_id = null;
  }

  showtagForm() {
    document.getElementById("tagForm").classList.toggle("hidden");
  }

  handleKeyup(element) {
    const parentDIV = element.target.parentNode;
    const spanErrorTag = element.target.parentNode.nextElementSibling;

    if (parentDIV && parentDIV.tagName === "DIV") {
      parentDIV.classList.remove("border-2", "border-rose-500");
    }

    if (spanErrorTag && spanErrorTag.tagName === "SPAN") {
      spanErrorTag.textContent = "";
    }
  }

  addTag(element) {
    element.preventDefault();

    const targetInputField =
      element.currentTarget.form.querySelector("input[data-action]");

    if (targetInputField && targetInputField.value == "") {
      if (element.currentTarget.parentNode.tagName === "DIV") {
        element.currentTarget.parentNode.classList.add(
          "border-2",
          "border-rose-500"
        );
        const errorMessage =
          element.currentTarget.parentNode.nextElementSibling;
        if (errorMessage) {
          errorMessage.textContent = "This field is required";
        }
      }
      return;
    }

    const currentForm = element.currentTarget.form;

    currentForm.requestSubmit();

    $("#no-folders").addClass("hidden");
    $("#folder-listing").removeClass("hidden");

    if (
      currentForm.previousElementSibling &&
      currentForm.previousElementSibling.tagName === "SPAN"
    ) {
      currentForm.previousElementSibling.classList.remove("hidden");
      currentForm.remove();
    }

    targetInputField.value = "";
  }

  deleteTag(element) {
    const tagID = element.target.dataset.id;
    fetch(`${window.location.origin}/tags/${tagID}`, {
      method: "Delete",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        setTimeout(() => {
          this.handleEmptyCase();
        }, 100);
      });
  }

  editTag(element) {
    const tagID = element.target.dataset.id;
    const editForm = document.getElementById("editTagForm");
    const tagRow = document.getElementById(`tag_row_${tagID}`);

    const cloneEditForm = editForm.cloneNode(true);
    cloneEditForm.classList.remove("hidden");

    // update form action
    cloneEditForm.setAttribute(
      "action",
      `${window.location.origin}/tags/${tagID}`
    );

    const labelElement = document.querySelector(`label[for="tag_${tagID}"]`);
    if (labelElement) {
      const titleValue = labelElement.getAttribute("title");

      const editInputField = cloneEditForm.querySelector("input[data-action]");
      editInputField.value = titleValue;
    }

    tagRow.firstElementChild.classList.add("hidden");
    tagRow.append(cloneEditForm);
  }

  resetEditForm(element) {
    const findEditForm = element.currentTarget.parentNode.parentNode;
    const tagSpanRow = findEditForm.previousElementSibling;
    tagSpanRow.classList.remove("hidden");
    findEditForm.remove();
  }

  saveAndAssignTags(event) {
    event.preventDefault();

    const profile = document.querySelectorAll(`.profile_select`);
    const profile_ids = Array.from(profile).map((p) =>
      p.getAttribute("data-id")
    );

    const tag_ids = this.getCheckedValues("tag_checkbox");
    const errorMessage = document.getElementById("showErrorMessage");

    if (tag_ids.length == 0) {
      errorMessage.textContent = "Please select alteast one tag";
      return;
    }

    if (single_profile_id == null) {
      if (profile_ids.length == 0) {
        errorMessage.textContent = "Please select at least one profile";
        return;
      }
      document.getElementById("assign_profile_ids").value =
        JSON.stringify(profile_ids);
    } else {
      if (single_profile_id == null) {
        errorMessage.textContent = "profile not selected";
        return;
      }
      document.getElementById("assign_profile_ids").value = JSON.stringify([
        single_profile_id,
      ]);
    }
    errorMessage.textContent = "";
    document.getElementById("assign_tag_ids").value = JSON.stringify(tag_ids);
    const form = event.target.form;
    form.addEventListener(
      "turbo:submit-end",
      () => {
        document.getElementById("manage-tag-modal").classList.add("hidden");
      },
      { once: true }
    );
    form.requestSubmit();
  }

  saveHideProfile(event) {
    event.preventDefault();

    const profile = document.querySelectorAll(`.profile_select`);
    const profile_ids = Array.from(profile).map((p) =>
      p.getAttribute("data-id")
    );

    const errorMessage = document.getElementById("showErrorUnverifiedMessage");

    if (single_profile_id == null) {
      if (profile_ids.length == 0) {
        errorMessage.textContent = "Please select at least one profile";
        return;
      }
      document.getElementById("profile_hide_ids").value =
        JSON.stringify(profile_ids);
    } else {
      if (single_profile_id == null) {
        errorMessage.textContent = "profile not selected";
        return;
      }
      document.getElementById("profile_hide_ids").value = JSON.stringify([
        single_profile_id,
      ]);
    }
    errorMessage.textContent = "";
    event.target.form.requestSubmit();
  }

  saveUnhideProfile(event) {
    event.preventDefault();

    const profile = document.querySelectorAll(`.profile_select`);
    const profile_ids = Array.from(profile).map((p) =>
      p.getAttribute("data-id")
    );

    const errorMessage = document.getElementById("showErrorUnverifiedMessage");

    if (single_profile_id == null) {
      if (profile_ids.length == 0) {
        errorMessage.textContent = "Please select at least one profile";
        return;
      }
      document.getElementById("profile_unhide_ids").value =
        JSON.stringify(profile_ids);
    } else {
      if (single_profile_id == null) {
        errorMessage.textContent = "profile not selected";
        return;
      }
      document.getElementById("profile_unhide_ids").value = JSON.stringify([
        single_profile_id,
      ]);
    }
    errorMessage.textContent = "";
    event.target.form.requestSubmit();
  }

  savePreVettedProfile(event) {
    this.saveVetted(event, "profile_vetted_ids");
  }

  saveUnassignPreVettedProfile(event) {
    this.saveVetted(event, "profile_unassign_vetted_ids");
  }

  saveVetted(event, inputId) {
    event.preventDefault();

    const profiles = document.querySelectorAll(".profile_select");
    const profileIds = Array.from(profiles).map((p) =>
      p.getAttribute("data-id")
    );
    const errorMessage = document.getElementById("showErrorUnverifiedMessage");

    if (
      typeof single_profile_id === "undefined" ||
      single_profile_id === null
    ) {
      if (profileIds.length === 0) {
        errorMessage.textContent = "Please select at least one profile";
        return;
      }
      document.getElementById(inputId).value = JSON.stringify(profileIds);
    } else {
      if (single_profile_id === null) {
        errorMessage.textContent = "Profile not selected";
        return;
      }
      document.getElementById(inputId).value = JSON.stringify([
        single_profile_id,
      ]);
    }

    errorMessage.textContent = "";
    event.target.form.requestSubmit();
  }

  assignTag(element) {
    const saveAndAssignButton = document.getElementById("saveAndAssignButton");
    saveAndAssignButton.classList.remove(
      "disabled:opacity-50",
      "disabled:cursor-not-allowed"
    );
    saveAndAssignButton.removeAttribute("disabled");

    this.showAssignTagModal();

    this.unCheckAllCheckBoxes("profile_checkbox");
    this.unCheckAllCheckBoxes("tag_checkbox");

    single_profile_id = element.currentTarget.dataset.id;
  }

  unCheckAllCheckBoxes(element) {
    const record = document.querySelectorAll(`.${element}`);
    record.forEach((checkbox) => {
      checkbox.checked = false;
    });
  }

  selectAllProfiles(el) {
    const profileListElements = document.querySelectorAll(".profile_list");
    const manageButton = document.getElementById("manageButton");
    const assignButton = document.getElementById("assignButton");
    const customDropDown = document.getElementById("custom-dropdown");
    const itemsValue = customDropDown ? customDropDown.dataset.items : null;
    const advanceButtons = document.getElementById("advance-action-buttons");
    const profileCheckBoxes = document.querySelectorAll(".profile_checkbox");
    const customselect = document.getElementById("custom-dropdown");
    const selectAllOptionMessage = document.getElementById(
      "select-all-option-message"
    );

    let checkAllSelect = document.querySelectorAll(".checkAllSelect");
    checkAllSelect.forEach((element) => {
      element.value = "";
    });

    profileListElements.forEach((element) => {
      if (itemsValue === "all") {
        this.closeDropdown();
        if (assignButton) assignButton.classList.add("hidden");
        element.classList.remove("profile_select");
        this.handleCheckBoxSelection("uncheck");

        if (manageButton) manageButton.classList.remove("hidden");
        if (customDropDown) customDropDown.dataset.items = "select";
        if (advanceButtons) advanceButtons.classList.add("hidden");

        if (el.currentTarget?.dataset?.view != "list") {
          profileCheckBoxes.forEach((checkbox) =>
            checkbox.classList.add("hidden")
          );
        }
        if (customselect) customselect.dataset.items = "select";
        if (selectAllOptionMessage)
          selectAllOptionMessage.classList.add("hidden");
      } else {
        this.closeDropdown();
        element.classList.add("profile_select");
        this.handleCheckBoxSelection("check");

        if (manageButton) manageButton.classList.add("hidden");
        if (assignButton) assignButton.classList.remove("hidden");
        if (customDropDown) customDropDown.dataset.items = "all";

        if (advanceButtons) advanceButtons.classList.remove("hidden");
        if (el.currentTarget?.dataset?.view != "list") {
          profileCheckBoxes.forEach((checkbox) =>
            checkbox.classList.add("hidden")
          );
        }
        if (customselect) customselect.dataset.items = "all";
        if (selectAllOptionMessage)
          selectAllOptionMessage.classList.remove("hidden");
      }

      if (el.currentTarget?.dataset?.view != "list") {
        const elements = element.getElementsByClassName("profile_checkbox");
        Array.from(elements).forEach(function (el) {
          el.classList.add("hidden");
        });
      }
    });

    if (this.hasSelectAllLabelTarget) {
      const isChecked = el.currentTarget.checked;
      const results = this.total_cards();

      // Set the text content
      this.selectAllLabelTarget.textContent = isChecked
        ? ""
        : `Select all (${results})`;

      // Toggle display based on the content
      this.selectAllLabelTarget.style.display = isChecked ? "none" : "inline"; // or "block" depending on layout
    }
  }

  checkProfile(el) {
    const profileID = el.currentTarget.dataset.id;
    const currentProfile = document.getElementById(`person_${profileID}`);
    if (currentProfile) {
      el.currentTarget.checked
        ? currentProfile.classList.add("profile_select")
        : currentProfile.classList.remove("profile_select");
    }

    const manageButton = document.getElementById("manageButton");
    const assignButton = document.getElementById("assignButton");
    const advanceButtons = document.getElementById("advance-action-buttons");

    if (el.currentTarget.checked) {
      manageButton && manageButton.classList.add("hidden");
      assignButton && assignButton.classList.remove("hidden");
      advanceButtons && advanceButtons.classList.remove("hidden");
    } else {
      const totalCheckedProfile =
        document.querySelectorAll(".profile_select").length;
      if (totalCheckedProfile == 0) {
        manageButton && manageButton.classList.remove("hidden");
        assignButton && assignButton.classList.add("hidden");
        advanceButtons && advanceButtons.classList.add("hidden");
      }
    }

    const totalProfileCheckboxes =
      document.querySelectorAll(".profile_checkbox").length;
    const totalCheckedProfile =
      document.querySelectorAll(".profile_select").length;

    const target = document.getElementById("custom-dropdown").firstElementChild;
    const isAllChecked = totalProfileCheckboxes === totalCheckedProfile;

    target.classList.remove("border-gray-400");
    target.innerHTML = `<i class="icon-${
      isAllChecked ? "check" : "minus"
    } text-white"></i>`;
  }

  getCheckedValues(targetClass) {
    const checkboxes = document.querySelectorAll(`.${targetClass}`);
    return Array.from(checkboxes)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.getAttribute("data-id"));
  }

  assignTagsToProfile(event) {
    this.handleEmptyCase();
    this.showAssignTagModal();
    single_profile_id = event.currentTarget.dataset.id;
  }

  customDropdown() {
    const dropDownListing = this.customDropDownTarget.nextElementSibling;
    if (dropDownListing) {
      dropDownListing.classList.contains("hidden")
        ? dropDownListing.classList.remove("hidden")
        : dropDownListing.classList.add("hidden");
    }
  }

  closeDropdown() {
    const dropDownListing = document.getElementById("toggle-custom-dropdown");
    if (dropDownListing) {
      dropDownListing.classList.add("hidden");
    }
  }

  selectDropDownItem(element) {
    const text = element.currentTarget.textContent.toLowerCase();
    const profileListElements = document.querySelectorAll(".profile_list");
    const profileCheckBoxes = document.querySelectorAll(".profile_checkbox");
    const manageButton = document.getElementById("manageButton");
    const assignButton = document.getElementById("assignButton");
    const customselect = document.getElementById("custom-dropdown");
    const advanceButtons = document.getElementById("advance-action-buttons");
    const selectAllRecords = document.getElementById("select-all-records");
    const unselect = document.getElementById("un-select");
    const selectAllOptionMessage = document.getElementById(
      "select-all-option-message"
    );

    const replaceText = document.getElementById("replace-text");

    this.dropDownTitleTarget.textContent = text;

    if (text === "select all") {
      if (manageButton) manageButton.classList.add("hidden");
      this.handleCheckBoxSelection("check");
      if (assignButton) assignButton.classList.remove("hidden");
      profileCheckBoxes.forEach((element) => element.classList.add("hidden"));
      profileListElements.forEach((element) =>
        element.classList.add("profile_select")
      );
      if (customselect) customselect.dataset.items = "all";
      if (advanceButtons) advanceButtons.classList.remove("hidden");
      if (selectAllOptionMessage)
        selectAllOptionMessage.classList.remove("hidden");
      if (replaceText)
        replaceText.innerHTML =
          "Selected only visible <strong>15</strong>  records";
    } else {
      if (assignButton) assignButton.classList.add("hidden");
      this.handleCheckBoxSelection("minus");
      if (manageButton) manageButton.classList.remove("hidden");
      profileCheckBoxes.forEach((element) =>
        element.classList.remove("hidden")
      );
      profileListElements.forEach((element) =>
        element.classList.remove("profile_select")
      );
      if (customselect) customselect.dataset.items = "select";
      if (selectAllOptionMessage)
        selectAllOptionMessage.classList.add("hidden");

      if (advanceButtons) advanceButtons.classList.add("hidden");
      let checkAllSelect = document.querySelectorAll(".checkAllSelect");
      checkAllSelect.forEach((element) => {
        element.value = "";
      });

      if (selectAllRecords) selectAllRecords.classList.remove("hidden");
      if (unselect) unselect.classList.add("hidden");
    }

    this.unCheckAllCheckBoxes("profile_checkbox");

    const dropdownListing = element.currentTarget.parentNode.parentNode;
    if (dropdownListing) {
      dropdownListing.classList.add("hidden");
    }
  }

  selectAllRecords(event) {
    this.closeDropdown();
    const total = event.currentTarget.dataset.records;
    const replaceText = document.getElementById("replace-text");
    const records = event.currentTarget;
    const unselect = document.getElementById("un-select");
    let search_params =
      document.getElementById("search_params").dataset.searchParams;
    records.classList.add("hidden");
    unselect.classList.remove("hidden");
    let checkAllSelect = document.querySelectorAll(".checkAllSelect");
    checkAllSelect.forEach((element) => {
      element.value = "selectAll";
    });

    const total_records = this.total_records();
    unselect.innerText = `Unselect all ${total_records} records`;
    replaceText.innerHTML =
      "Selected all <strong>" + total_records + "</strong> records";

    const hiddenInput = document.querySelectorAll(".search_data");
    hiddenInput.forEach((element) => {
      element.value = search_params;
    });
  }

  unSelectAllRecords(event) {
    this.closeDropdown();
    const replaceText = document.getElementById("replace-text");
    const selectAllRecords = document.getElementById("select-all-records");
    const unselect = document.getElementById("un-select");

    let search_params =
      document.getElementById("search_params").dataset.searchParams;
    selectAllRecords.classList.remove("hidden");
    unselect.classList.add("hidden");
    let checkAllSelect = document.querySelectorAll(".checkAllSelect");
    checkAllSelect.forEach((element) => {
      element.value = "";
    });
    const hiddenInput = document.querySelectorAll(".search_data");
    hiddenInput.forEach((element) => {
      element.value = search_params;
    });
    const records = this.total_cards();
    replaceText.innerHTML = `Selected only visible <strong>${records}</strong> Records`;
  }

  handleCheckBoxSelection(type) {
    let target = this?.dropDownCustomCheckboxTarget;
    if (!target) {
      target = document.getElementById("custom-dropdown").firstElementChild;
    }

    // Reset to the default state first
    target.classList.remove("bg-[#0066F9]", "border-[#0066F9]");
    target.classList.add("border-gray-400");
    target.innerHTML = "";

    switch (type) {
      case "check":
        target.classList.add("bg-[#0066F9]", "border-[#0066F9]");
        target.classList.remove("border-gray-400");
        target.innerHTML = `<i class="icon-check text-white"></i>`;
        break;
      case "minus":
        target.classList.add("bg-[#0066F9]", "border-[#0066F9]");
        target.classList.remove("border-gray-400");
        target.innerHTML = `<i class="icon-minus text-white"></i>`;
        break;
    }
  }

  total_records() {
    const records = document.getElementById("people_search_count");
    if (records) {
      return records.innerText;
    } else {
      return this.total_cards();
    }
  }

  total_cards() {
    return document.getElementsByClassName("profile_list").length;
  }

  closeModal() {
    document.getElementById("manage-tag-modal").classList.add("hidden");
  }
}
