import { Controller } from "@hotwired/stimulus";
import { toggleDropdown } from "../components/dropdown";
import { closeOnOutsideClick } from "../components/modal";
import noUiSlider from "nouislider";
import { openModal, closeModal } from "../components/modal";

let radius_slider = null;
let location_radius_set = new Set();
export default class extends Controller {
  static targets = [
    "nameInput",
    "nameInputError",
    "companyInput",
    "companyInputError",
    "companyList",
    "locationInput",
    "noUISlider",
    "locationList",
  ];

  connect() {
    this.timeout = null;

    document.addEventListener("click", (el) => {
      this.handleOutsideClick(el);

      if (el.target.closest(".close-modal")) {
        closeModal("claim-profile");
        closeModal("one-click-claim-profile");
      }
    });

    if (this.hasLocationInputTarget) {
      setTimeout(() => {
        this.initGooglePlaces();
      }, 1500);
    }
  }

  truncateSummary(el) {
    const summaryElement = document.getElementById("person_summary");
    const summary = document.getElementById("complete_summary").value;

    if (el.target.innerText.toLowerCase() == "read more") {
      summaryElement.querySelector("span").innerText = summary;
      el.target.innerText = "Show Less";
      return;
    }

    if (el.target.innerText.toLowerCase() == "show less") {
      el.target.innerText = "Read More";
      if (summary.length == 150) {
        summaryElement.querySelector("span").innerText = summary;
      } else {
        summaryElement.querySelector("span").innerText =
          summary.slice(0, 150) + "...";
      }
      return;
    }
  }

  disconnect() {
    document.removeEventListener("click", this.handleOutsideClick.bind(this));
    this.destroyNoUiSlider();
  }

  initGooglePlaces() {
    const search_location_input = this.hasLocationInputTarget
      ? this.locationInputTarget
      : document.getElementById("locationInput");

    if (!search_location_input) {
      return;
    }

    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }

    if (!window.google || !google.maps || !google.maps.places) {
      console.error("Google Places API is not loaded");
      return;
    }

    this.autocomplete = new google.maps.places.Autocomplete(
      search_location_input,
      {
        types: ["locality", "administrative_area_level_1", "country"],
      }
    );

    this.autocomplete.addListener(
      "place_changed",
      this.populateLocation.bind(this)
    );
  }

  populateLocation() {
    const place = this.autocomplete.getPlace();

    if (!place.geometry) {
      console.error("No location data for this place");
      return;
    }

    let country = "";
    let state = "";
    let city = "";

    this.autocomplete.setTypes(["(cities)"]);

    place.address_components.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (
        component.types.includes("administrative_area_level_2") &&
        !city
      ) {
        city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.short_name;
      }
    });

    location_radius_set.add({
      city: city,
      state: state,
      country: country,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    });

    this.initRadiusSlider();

    const text = city || state || country;
    const item = this.preparebadge(text);

    if (this.hasLocationInputTarget) {
      this.locationInputTarget.value = "";
    } else {
      const locationINput = document.getElementById("locationInput");
      if (locationINput) {
        locationINput.value = "";
      }
    }

    if (this.hasLocationListTarget) {
      this.locationListTarget.insertAdjacentHTML("beforeend", item);
    } else {
      const loc = document.getElementById("locationList");
      loc && loc.insertAdjacentHTML("beforeend", item);
    }
    this.setValuesAndSubmitForm();
  }

  initRadiusSlider() {
    if (radius_slider) {
      return false;
    }
    radius_slider = this.HasNoUISliderTarget
      ? this.noUISliderTarget
      : document.getElementById("noUISlider");
    if (radius_slider && !radius_slider.noUiSlider) {
      const labels = {
        5: "5",
        10: "•",
        20: "•",
        30: "•",
        50: "•",
        70: "•",
        100: "•",
        130: "•",
        160: "•",
        190: "•",
        200: "200",
      };

      noUiSlider.create(radius_slider, {
        start: 5,
        connect: [true, false],
        tooltips: {
          to: function (value) {
            return value > 200 ? "200+" : `${parseInt(value)}mi`;
          },
        },
        range: {
          min: 5,
          "10%": 10,
          "20%": 20,
          "30%": 30,
          "40%": 50,
          "50%": 70,
          "60%": 100,
          "70%": 130,
          "80%": 160,
          "90%": 190,
          max: 200,
        },
        pips: {
          mode: "steps",
          filter: function (value, type) {
            return type === 0 ? -1 : 1;
          },
          format: {
            to: function (value) {
              return labels[value] !== undefined ? labels[value] : "";
            },
          },
        },
      });

      radius_slider.noUiSlider.on("change", (values, handle) => {
        if (location_radius_set.size == 0) return;
        for (const item of location_radius_set) {
          item.radius = parseInt(values[handle]);
        }
        this.setValuesAndSubmitForm();
      });
    }
  }

  handleOutsideClick(event) {
    const nameButton = document.getElementById("name_filter");
    const nameFilter = document.getElementById("claim_name_filter");

    if (nameButton && nameFilter) {
      closeOnOutsideClick(nameButton, nameFilter, event.target);
    }

    const companyButton = document.getElementById("company_filter");
    const companyFilter = document.getElementById("claim_company_filter");

    if (companyButton && companyFilter) {
      closeOnOutsideClick(companyButton, companyFilter, event.target);
    }

    const locationButton = document.getElementById("location_radius_filter");
    const locationFilter = document.getElementById(
      "claim_location_radius_filter"
    );

    if (locationButton && locationFilter) {
      closeOnOutsideClick(locationButton, locationFilter, event.target);
    }
  }

  closeTurboModal() {
    const portalModals = document.getElementById("portal_modals");
    if (portalModals) {
      portalModals.innerHTML = "";
    }
  }

  toggleDropdown(el) {
    const selector = el.currentTarget;
    const dropdownID = selector?.dataset?.dropdownElementId;
    if (!selector || !dropdownID) return;

    toggleDropdown(selector, "match-profiles", 1);
  }

  debounceSearch(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setValuesAndSubmitForm();
    }, 500);
  }

  setValuesAndSubmitForm() {
    const form = document.getElementById("filterProfilesForm");
    if (form) {
      form.querySelector("#name").value = this.hasNameInputTarget
        ? this.nameInputTarget.value
        : document.getElementById("nameInput").value;
      form.querySelector("#locations").value =
        this.stringifyData(location_radius_set);
      form.querySelector("#companies").value = this.hasCompanyInputTarget
        ? this.companyInputTarget.value
        : document.getElementById("companyInput")?.value;

      form.requestSubmit();
    }
  }

  stringifyData(item) {
    try {
      return JSON.stringify([...item]);
    } catch (error) {
      return [];
    }
  }

  preparebadge(name) {
    return `<a href="javascript:void(0)">
                  <span class="value">${name}</span>
                  <i data-action="click->match-profiles#removeBadge" class="anron-cross text-[15px] text-[#05123752] cursor-pointer"></i>
                </a>`;
  }

  removeBadge(event) {
    const text = event.target.closest("a").innerText.trim();

    if (this.hasCityOrState(text)) {
      this.removeCityOrState(text);
      event.target.closest("a").remove();
      this.setValuesAndSubmitForm();

      if (location_radius_set.size == 0) {
        this.destroyNoUiSlider();
      }
      return;
    }
  }

  hasCityOrState(text) {
    return [...location_radius_set].some(
      (item) =>
        item.city === text || item.state === text || item.country === text
    );
  }

  removeCityOrState(text) {
    for (let item of location_radius_set) {
      if (item.city === text || item.state === text || item.country === text) {
        location_radius_set.delete(item);
        return true;
      }
    }
    return false;
  }

  showErrorMessage(message, type = "name") {
    if (type == "company") {
      this.companyInputTarget.value = "";
      this.companyInputErrorTarget.classList.remove("hidden");
      this.companyInputErrorTarget.innerText = message;
      return;
    }
    this.nameInputTarget.value = "";
    this.nameInputErrorTarget.classList.remove("hidden");
    this.nameInputErrorTarget.innerText = message;
  }

  destroyNoUiSlider() {
    if (radius_slider) {
      radius_slider.noUiSlider.destroy();
    }
    radius_slider = null;
  }

  clearFilter({ params: { type } }) {
    if (type == "name") {
      this.nameInputTarget.value = "";
    }

    if (type == "company") {
      this.companyInputTarget.value = "";
    }

    if (type == "location") {
      location_radius_set.clear();
      this.locationInputTarget.value = "";
      this.locationListTarget.innerHTML = "";
      this.destroyNoUiSlider();
    }
    this.setValuesAndSubmitForm();
  }

  claimProfile() {
    openModal("claim-profile");
  }

  oneClickClaim() {
    openModal("one-click-claim-profile");
  }
}
