import { Controller } from "@hotwired/stimulus";
import { initSelect2, destroySelect2 } from "../../components/select2";

// Connects to data-controller="v2--ai-search-filters"
export default class extends Controller {
  connect() {
    initSelect2(this.select2IDS());
  }

  disconnect() {
    destroySelect2(this.select2IDS());
  }

  select2IDS() {
    return [
      "country_filter",
      "language_filter",
      "company_filter",
      "company_title",
      "university_filter",
      "job_title_filter",
      "degree_filter",
      "industry_filter",
      "state_filter",
      "skill_search",
    ];
  }
}
