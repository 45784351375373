import { Controller } from "@hotwired/stimulus";
import {
  openModal,
  closeModal,
  closeOnOutsideClick,
} from "../../../components/modal";

export default class extends Controller {
  static targets = [
    "smartFilterTitle",
    "smartFilterTitleError",
    "smartFilterModal"
  ]

  createSmartFilter(e) {
    e.preventDefault();
    if (!this.smartFilterTitleTarget.value) {
      this.smartFilterTitleErrorTarget.classList.remove('hidden')
      this.smartFilterTitleTarget.classList.add('border')
      this.smartFilterTitleTarget.classList.add('border-red-300!')
    } else {
      let form = document.getElementById('lead_person_search_form')
      let origin = new URL(form.action).origin;
      form.action = `${origin}/portal/leads/smart_filters`
      form.method = 'post'
      let titleInput = document.getElementById('lead_smart_filter_title');
      titleInput.value = this.smartFilterTitleTarget.value;
      this.smartFilterTitleTarget.value = "";
      form.requestSubmit();
      titleInput.value = "";
      this.closeSmartFilterModal();
    }
  }

  openSmartFilterModal() {
    openModal("lead-new-smart-filters-popup");
    this.openFiltersDropdown();
  }

  closeSmartFilterModal() {
    this.smartFilterTitleErrorTarget.classList.add('hidden')
    this.smartFilterTitleTarget.classList.remove('border')
    this.smartFilterTitleTarget.classList.remove('border-red-300!')
    closeModal("lead-new-smart-filters-popup");
  }

  openFiltersDropdown() {
    if (!this.hasLeadMoredropdownTarget) {
      return false;
    }
    this.toggleDropdown(this.leadMoredropdownTarget);
  }
}
