import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="packages"
export default class extends Controller {
  connect() {
    this.hideAllLoaders();
  }

  disconnect() {
    this.hideAllLoaders();
  }

  buyPackage(event) {
    event.currentTarget.classList.add("pointer-events-none", "opacity-50");
    this.showLoader(event.currentTarget.lastElementChild);
  }

  showLoader(element) {
    element.classList.remove("hidden!");
  }

  hideAllLoaders() {
    const loaders = document.getElementsByClassName("db-loader");
    if (loaders.length == 0) return;

    loaders.forEach((loader) => {
      const el = loader.parentNode;
      if (el) {
        el.classList.remove("pointer-events-none", "opacity-50");
      }
      loader.classList.add("hidden!");
    });
  }
}
