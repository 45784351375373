import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "textArea", "button", "icon", "aiRephraseButton", "tab", "tabContent", "titleSearchShimmer",
                    "aiRephraseSkeleton", "navbarFormSearchQuery", "pagination", "searchFormQueryValue",
                    "searchCompanies", "searchProfessionals", "pagination", "companyPagination"];

  static values = { url: String }

  connect() {
    this.typingTimeout = null;
    this.buttonTarget.disabled = true;

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadMore();
        }
      });
    });
    this.observePagination();
  }

  handleTyping() {
    this.buttonTarget.disabled = true;
    this.buttonTarget.classList.add("bg-[#051237]");
    this.buttonTarget.classList.remove("bg-[rgba(5,18,55,0.04)]");
    this.buttonTarget.classList.add("disabled:opacity-50");
    this.buttonTarget.classList.remove("disabled:opacity-80");
    this.iconTarget.classList.add("text-[#fff]");
    this.iconTarget.classList.remove("text-[rgba(5,18,55,0.32)]");

    clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(() => {
      this.buttonTarget.disabled = false;
    }, 1000);
  }

  closeSearchModal(){
    const modal = document.getElementById("portal_modals");
    if (modal){
      modal.innerHTML = "";
    }
  }

  aiRephraseSubmit(event) {
    event.preventDefault();
    const value = this.inputTarget.value;
    if(value){
      document.getElementById("ai_phrase_search_query").value = value;
      event.currentTarget.form.requestSubmit();
      this.aiRephraseButtonTarget.disabled = true;
      this.aiRephraseSkeletonTarget.classList.remove("hidden");
    }
  }

  switch(event) {
    const tab = event.currentTarget
    this.tabTargets.forEach(tab => tab.classList.remove('isactive'))
    tab.classList.add('isactive')
    const textArea = document.getElementById("job_description_textarea");
     if (tab && tab.dataset.tab == "companies") {
       textArea.placeholder = "Search Companies with your job description";
       document.getElementById("navbar-search-intent").value = "company";
     }
     else {
       textArea.placeholder = "Search Professionals with your job description";
       document.getElementById("navbar-search-intent").value = "professional";
     }
  }

  replaceInputToAiPhrase(event){
    const target = event.currentTarget;
    const aiPhrase = target.querySelector("h3").innerHTML
    if (aiPhrase){
      this.inputTarget.value = aiPhrase;
    }
  }

  submitHeaderSearch(){
    const value = this.inputTarget.value
    if (value){
      this.buttonTarget.innerHTML = `<span class="db-loader border-bg-white w-[10px]! h-[10px]!"></span>`
      this.buttonTarget.classList.add("bg-[rgba(5,18,55,0.04)]");
      const target = this.navbarFormSearchQueryTarget;
      target.value = value;
      target.form.requestSubmit();
    }
  }

  submitJobSearch(){
    const value = this.inputTarget.value
    if (value){
      this.buttonTarget.innerHTML = `<span class="db-loader border-bg-white w-[10px]! h-[10px]!"></span>`
      this.buttonTarget.classList.add("bg-[rgba(5,18,55,0.04)]");
      const form = document.getElementById("job_based_search_form");
      form.submit();
    }
  }

  uploadJobDocument(event) {
    const fileInput = document.getElementById("job_description_file");
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(selectedFile);

      fileInput.files = dataTransfer.files;
      document.getElementById("close-search-popup").classList.add("hidden");
      document.getElementById("ImageParserLoader").classList.remove("hidden");
      event.currentTarget.form.submit();
    }
  }

  submitCriteriaForm() {
    document.getElementById("criteria_modal_close_icon").classList.add("hidden");
    document.getElementById("ImageParserLoader").classList.remove("hidden");
  }


  showProfessionals() {
    this.activateTab(this.tabTargets[0])

    if (this.hasSearchCompaniesTarget && this.hasSearchProfessionalsTarget) {
      this.searchCompaniesTarget.classList.add("hidden")
      this.searchProfessionalsTarget.classList.remove("hidden")
    }
  }

  showCompanies() {
    this.activateTab(this.tabTargets[1])
    if (this.hasSearchCompaniesTarget && this.hasSearchProfessionalsTarget) {
      this.searchCompaniesTarget.classList.remove("hidden")
      this.searchProfessionalsTarget.classList.add("hidden")
    }
  }


  activateTab(selectedTab) {
    this.tabTargets.forEach(tab => tab.classList.remove("isactive"))
    selectedTab.classList.add("isactive")
  }

  handleAiSearchTyping() {
    this.buttonTarget.disabled = true;
    this.titleSearchShimmerTarget.classList.remove("hidden");
    document.getElementById("professional_search_by_title").classList.add("hidden")
    clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(() => {
      this.buttonTarget.disabled = false;
      if (this.inputTarget.value) {
        const form = document.getElementById("search_by_title_form");
        this.searchFormQueryValueTarget.value = this.inputTarget.value;
        form.addEventListener("turbo:submit-end", () => {
          this.titleSearchShimmerTarget.classList.add("hidden");
          document.getElementById("professional_search_by_title").classList.remove("hidden");
        }, { once: true });
        form.requestSubmit();
      }
    }, 1000);
  }

  observePagination() {
    if (this.hasPaginationTarget) {
      this.observer.observe(this.paginationTarget);
    }

    if (this.hasCompanyPaginationTarget) {
      this.observer.observe(this.companyPaginationTarget);
    }
  }

  loadMore() {
    if (this.hasPaginationTarget) {
      const turboFrame = document.getElementById("pagination");
      const nextPageUrl = this.paginationTarget.getAttribute("src");
      if (nextPageUrl && turboFrame) {
        turboFrame.src = nextPageUrl;
      }
    }
    if (this.hasCompanyPaginationTarget) {
      const turboFrame = document.getElementById("companyPagination");
      const nextPageUrl = this.companyPaginationTarget.getAttribute("src");
      if (nextPageUrl && turboFrame) {
        turboFrame.src = nextPageUrl;
      }
    }
  }

}