import { Controller } from "@hotwired/stimulus";
import { toggleClearOption } from "../components/filters";
import noUiSlider from "nouislider";
let location_radius = [];
let radius_slider = null;
export default class extends Controller {
  connect() {
    this.geoSlider();
    setTimeout(() => {
      this.initGooglePlaces();
      this.populateLocationRadius();
    }, 2000);
  }

  disconnect() {
    if (radius_slider) {
      radius_slider.noUiSlider.destroy();
    }
    radius_slider = null;
  }

  geoSlider() {
    const locationRadius = document.getElementById("location_radius_data");
    const locationSearchInput = document.getElementById(
      "search-location-input"
    );
    if (locationSearchInput) {
      locationSearchInput.value = "";
    }
    if (locationRadius && locationRadius.value == "") {
      return false;
    }

    this.initRadiusSlider();

    const parse_location = JSON.parse(locationRadius.value);
    location_radius = parse_location;
    this.setRadiusValue();
  }

  initGooglePlaces() {
    const search_location_input = document.getElementById(
      "search-location-input"
    );

    if (!search_location_input) {
      return;
    }

    // Clear any existing autocomplete instance
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }

    // Ensure Google Places library is loaded
    if (!window.google || !google.maps || !google.maps.places) {
      console.error("Google Places API is not loaded");
      return;
    }

    // Initialize Google Places Autocomplete
    this.autocomplete = new google.maps.places.Autocomplete(
      search_location_input,
      {
        types: ["locality", "administrative_area_level_1", "country"],
      }
    );

    // Add event listener for place selection
    this.autocomplete.addListener(
      "place_changed",
      this.populateLocation.bind(this)
    );
  }

  populateLocation() {
    const place = this.autocomplete.getPlace();

    if (!place.geometry) {
      console.error("No location data for this place");
      return;
    }

    let country = "";
    let state = "";
    let city = "";

    this.autocomplete.setTypes(["(cities)"]);

    place.address_components.forEach((component) => {
      if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (
        component.types.includes("administrative_area_level_2") &&
        !city
      ) {
        city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (component.types.includes("country")) {
        country = component.short_name;
      }
    });

    // prepare location radius data
    location_radius.push({
      search: document.getElementById("search-location-input").value,
      country: country,
      state: state,
      city: city,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    });

    this.initRadiusSlider();
    this.toggleFilterActiveness(true);
    this.setLocationRadiusValue(location_radius);
    this.displayLocationBadges();
    this.submitFilterForm();
  }

  initRadiusSlider() {
    if (radius_slider) {
      return false;
    }
    radius_slider = document.getElementById("db-rangeslider");
    if (radius_slider && !radius_slider.noUiSlider) {
      const labels = {
        5: "5",
        10: "•",
        20: "•",
        30: "•",
        50: "•",
        70: "•",
        100: "•",
        130: "•",
        160: "•",
        190: "•",
        200: "200",
      };

      noUiSlider.create(radius_slider, {
        start: 5,
        connect: [true, false],
        tooltips: {
          to: function (value) {
            return value > 200 ? "200+" : `${parseInt(value)}mi`;
          },
        },
        range: {
          min: 5,
          "10%": 10,
          "20%": 20,
          "30%": 30,
          "40%": 50,
          "50%": 70,
          "60%": 100,
          "70%": 130,
          "80%": 160,
          "90%": 190,
          max: 200,
        },
        pips: {
          mode: "steps",
          filter: function (value, type) {
            return type === 0 ? -1 : 1;
          },
          format: {
            to: function (value) {
              return labels[value] !== undefined ? labels[value] : "";
            },
          },
        },
      });

      radius_slider.noUiSlider.on("change", (values, handle) => {
        if (location_radius.length == 0) return;
        for (const item of location_radius) {
          item.radius = parseInt(values[handle]);
        }

        this.setLocationRadiusValue(location_radius);
        this.displayLocationBadges();
        this.submitFilterForm();
      });
    }
  }

  submitFilterForm() {
    const form = document.getElementById("search-filter-form");
    if (form) {
      form.requestSubmit();
    }
  }

  displayLocationBadges() {
    const location_radius_tags = document.getElementById(
      "location_radius_tags"
    );

    if (location_radius.length == 0) {
      location_radius_tags.innerHTML = "";
      return;
    }

    if (!location_radius_tags) return;

    location_radius_tags.innerHTML = "";
    let html = "";
    for (let i = 0; i < location_radius.length; i++) {
      const locationString = this.getLocationString(location_radius[i]);
      html += `
        <a href="javascript:void(0)">
          <span class="value">${locationString}</span>
          <i class="anron-cross text-[15px] text-[#05123752] cursor-pointer" data-action="click->location-radius#destroyLocation" data-index="${i}"></i>
        </a>`;
    }

    location_radius_tags.innerHTML = html;
  }

  getLocationString(item) {
    if (item.city) return item.city;
    if (item.state) return item.state;
    return item.country;
  }

  populateLocationRadius() {
    const has_location = this.element.dataset.locationRadius;
    if (has_location && has_location != "") {
      const jsonString = has_location
        .replace(/=>/g, ":")
        .replace(/nil/g, "null");
      const parse_location = JSON.parse(jsonString);
      location_radius = parse_location;
      this.setLocationRadiusValue(parse_location);
      this.initRadiusSlider();
      this.setRadiusValue();
      if (window.location.pathname == "/ai_search") {
        this.submitFilterForm();
      }
    }
  }

  setLocationRadiusValue(data) {
    document.getElementById("location_radius_data").value =
      JSON.stringify(data);
  }

  toggleFilterActiveness(flag) {
    if (flag) {
      $("#location_radius_active_filter").addClass("has-dot");
      toggleClearOption("location_radius", true);
    } else {
      $("#location_radius_active_filter").removeClass("has-dot");
      toggleClearOption("location_radius", false);
    }
  }

  destroyLocation(e) {
    e.preventDefault();
    e.stopPropagation();

    const index = e.currentTarget.dataset.index;

    const find_location = location_radius[index];
    if (find_location) {
      location_radius.splice(index, 1);
      this.setLocationRadiusValue(location_radius);
      this.displayLocationBadges();
      this.submitFilterForm();
    }

    if (location_radius.length == 0) {
      this.toggleFilterActiveness(false);
    }
  }

  setRadiusValue() {
    if (location_radius.length > 0) {
      const radiusValue = location_radius[0].radius || null;
      if (radiusValue) {
        const radiusSlider = document.getElementById("db-rangeslider");
        if (radiusSlider) {
          radiusSlider.noUiSlider.set(radiusValue);
        }
      }
    }
  }
}
