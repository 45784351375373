import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  closeCreditUsageModal() {
    document.getElementById("credit-usage-modal-frame").innerHTML = "";
  }

  closePortalModal() {
    document.getElementById("portal_modals").innerHTML = "";
  }
}
