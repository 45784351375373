import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { interval: { type: Number, default: 10 } };

  connect() {
    console.log("Reload controller connected");
    this.startReloading();
  }

  disconnect() {
    this.stopReloading();
  }

  startReloading() {
    this.intervalId = setInterval(() => {
      location.reload();
    }, this.intervalValue * 1000);
  }

  stopReloading() {
    clearInterval(this.intervalId);
  }
}
