import { Controller } from "@hotwired/stimulus";
import { openModal } from "../components/modal";

let userBio = null;

export default class extends Controller {
  static targets = [
    "userBio",
    "bioReadMore",
    "userBioModal",
    "userBioTextArea",
  ];

  connect() {
    if (this.hasUserBioTarget) {
      const shouldStream = this.userBioTarget.dataset.shouldStream;
      userBio = this.userBioTarget.dataset.bio;

      // Ensure new lines are converted to <br> before processing
      userBio = userBio.replace(/\n/g, "<br>");

      if (shouldStream === "true") {
        this.typing(userBio);
      } else {
        this.userBioTarget.removeAttribute("data-bio");
        this.userBioTarget.innerHTML = userBio;
      }
    }
  }

  typing(htmlString) {
    // Convert HTML string into a DOM structure
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    // Save all original child nodes (preserve structure)
    const originalNodes = Array.from(tempDiv.childNodes);

    // Clear the target element before typing starts
    this.userBioTarget.innerHTML = "";

    // Start the typing effect on the saved nodes
    this.typeNodes(originalNodes, this.userBioTarget);
  }

  typeTextNode(textNode, container, callback) {
    const text = textNode.textContent;
    let charIndex = 0;
    const typingSpeed = 10; // Adjust typing speed

    const typeChar = () => {
      if (charIndex < text.length) {
        container.append(text.charAt(charIndex));
        charIndex++;
        setTimeout(typeChar, typingSpeed);
      } else if (callback) {
        callback();
      }
    };
    typeChar();
  }

  typeNode(node, container, callback) {
    if (node.nodeType === Node.TEXT_NODE) {
      this.typeTextNode(node, container, callback);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const newElement = document.createElement(node.tagName);

      // Copy all attributes to maintain styles, classes, etc.
      Array.from(node.attributes).forEach((attr) =>
        newElement.setAttribute(attr.name, attr.value)
      );

      container.appendChild(newElement);

      // Recursively type child nodes of the element
      this.typeNodes(Array.from(node.childNodes), newElement, callback);
    } else {
      if (callback) callback();
    }
  }

  typeNodes(nodeList, container, callback) {
    let index = 0;
    const next = () => {
      if (index < nodeList.length) {
        this.typeNode(nodeList[index], container, () => {
          index++;
          next();
        });
      } else if (callback) {
        callback();
      }
    };
    next();
  }

  openUserBioModal(e) {
    e.preventDefault();
    document.getElementById("userBioTextArea").value = userBio;
    openModal("userBioModal");
  }
}
