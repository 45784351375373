function insertConfirmModal(message, element, button, path = "") {
  let description =
    button?.dataset?.turboConfirmDescription ||
    element?.dataset?.turboConfirmDescription ||
    "";
  let id = `confirm-modal-${Date.now()}`;

  let content = `
    <div id="${id}" class="z-50 fixed top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur-xs bg-[rgba(0,0,0,0.4)]">
      <div class="bg-white mx-auto rounded-[10px] shadow-sm p-[30px] flex flex-col justify-center items-center max-w-[500px]">
        <h5 class="text-heading_color font-medium font-text_font text-[20px] leading-8">${message}</h5>
        <p class="text-[16px] font-normal leading-6 font-text_font text-[rgba(5,18,55,0.6)] text-center">${description}</p>
        <div class="flex w-full items-center gap-[10px] mt-5">
          <button data-behavior="cancel" class="db-secbtn w-full">Cancel</button>
          <button data-behavior="commit" class="db-primary-button db-delete-button w-full">Delete</button>
        </div>
      </div>
    </div>
  `;

  // Insert the modal into the DOM
  document.body.insertAdjacentHTML("beforeend", content);
  let modal = document.getElementById(id);

  // Focus on the cancel button after rendering
  const cancelButton = modal.querySelector('button[data-behavior="cancel"]');
  cancelButton.focus();

  // Cancel button closes the modal
  cancelButton.addEventListener("click", () => {
    modal.remove();
  });

  // Commit button triggers redirect
  const commitButton = modal.querySelector('button[data-behavior="commit"]');
  commitButton.addEventListener("click", () => {
    modal.remove();
    if (path) {
      window.location.href = path; // Redirect to the specified path
    }
  });

  return modal;
}

// Turbo.js integration
Turbo.config.forms.confirm = (message, element, button) => {
  const path =
    button?.dataset?.redirectUrl || element?.dataset?.redirectUrl || ""; // Retrieve the redirect URL
  insertConfirmModal(message, element, button, path);

  return new Promise((resolve) => {
    // Simulate dialog resolution for Turbo
    document.addEventListener("turbo:confirm:commit", () => resolve(true), {
      once: true,
    });

    document.addEventListener("turbo:confirm:cancel", () => resolve(false), {
      once: true,
    });
  });
};
