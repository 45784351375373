export function toggleDropdown(
  currentElement,
  controllerWrapper,
  zIndex = 1000
) {
  const elementID = currentElement?.dataset?.dropdownElementId;
  const menu = document.getElementById(elementID);
  if (!elementID || !menu) return;

  const parentDivId = "current-dropdown-list";
  let parentDiv = document.getElementById(parentDivId);

  if (!parentDiv) {
    parentDiv = document.createElement("div");
    parentDiv.id = parentDivId;
    parentDiv.className = "current-dropdown-list";
    parentDiv.setAttribute("data-controller", controllerWrapper);
    document.body.appendChild(parentDiv);
  }

  if (!parentDiv.contains(menu)) {
    parentDiv.appendChild(menu);
  }

  const isAlreadyOpen = !menu.classList.contains("hidden");

  closeAllDropdowns();
  if (!isAlreadyOpen) {
    if (menu.classList.contains("shortlist-dropdown")) {
      const allPhoneDropdowns =
        document.getElementsByClassName("shortlist-dropdown");

      Array.from(allPhoneDropdowns).forEach((e) => {
        e.classList.add("hidden");
      });
    }
    menu.classList.remove("hidden");
    updateDropdownPosition(currentElement, menu, zIndex);
  } else {
    menu.classList.add("hidden");
  }
}

export function closeDropdowns() {
  closeAllDropdowns();
}

const closeAllDropdowns = () => {
  document.querySelectorAll(".__portal_dropdowns").forEach((menu) => {
    menu.classList.add("hidden");
  });
};

const updateDropdownPosition = (button, dropdownMenu, zIndex) => {
  const buttonRect = button.getBoundingClientRect();
  const menuRect = dropdownMenu.getBoundingClientRect();
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  let top = buttonRect.bottom + window.scrollY + 10;
  let left = buttonRect.left + window.scrollX - 10;

  if (menuRect.width > viewportWidth - buttonRect.left) {
    left = viewportWidth - menuRect.width - 10;
  }
  if (menuRect.height > viewportHeight - buttonRect.bottom) {
    top = buttonRect.top + window.scrollY - menuRect.height - 10;
  }
  if (top < 0) top = 10;
  if (left < 0) left = 10;

  dropdownMenu.style.top = `${top}px`;
  dropdownMenu.style.left = `${left}px`;
  dropdownMenu.style.position = "absolute";
  dropdownMenu.style.zIndex = zIndex;
};
