import { Controller } from "@hotwired/stimulus";
import {
  openModal,
  closeModal,
  closeOnOutsideClick,
} from "../../../components/modal";

export default class extends Controller {
  static targets = [
    "appendSearchedValue",
    "dropdown",
    "input",
    "leadFilters",
    "leadMoreButton",
    "lead_sort_order",
    "lead_sort_by",
    "leadMoreValues",
  ];

  connect() {
    this.timeout = null;
    document.addEventListener("click", this.handleOutsideClick.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.handleOutsideClick.bind(this));
  }

  handleOutsideClick(event) {
    this.hideFilterDropdown(event);

    const dropdown = document.getElementById("search-drop-down");
    if (dropdown && !dropdown.contains(event.target)) {
      this.closeDropdown(dropdown);
    }

    const add_more_filter_btn = document.getElementById("add_more_filter");
    const shortlist_all_filters = document.getElementById(
      "shortlist_all_filters"
    );

    if (add_more_filter_btn && shortlist_all_filters) {
      closeOnOutsideClick(
        add_more_filter_btn,
        shortlist_all_filters,
        event.target
      );
    }
  }

  leadSearch(event) {
    this.loadTableSkeleton(true);
    const targetDiv = this.appendSearchedValueTarget;
    const targetUl = targetDiv.querySelector("ul");
    const newtargetDiv = this.leadMoreValuesTarget;
    const newtargetUl = newtargetDiv.querySelector("ul");

    const namesArray1 = Array.from(targetUl.querySelectorAll("li span")).map(
      (span) => span.textContent.trim().replace(/\s*<i.*<\/i>/, "")
    );

    const namesArray2 = Array.from(newtargetUl.querySelectorAll("li h6")).map(
      (h6) => h6.textContent.trim()
    );

    const namesArray3 = [document.getElementById("lead-search-input").value];

    const allNames = [...namesArray1, ...namesArray2, ...namesArray3];
    const name = targetDiv.getAttribute("data-type");
    const fieldId = `lead_search_${name}`;
    this.submitSearchForm(fieldId, allNames);
    this.updateFiltersCount(allNames, name);
  }

  searchDropdownForm(event) {
    clearTimeout(this.timeout);
    const value = event.currentTarget.value;
    const leadSearchDropdown = document.getElementById("lead-search-drop-down");
    if (value.trim() === "") {
      leadSearchDropdown.innerHTML = "";
      return false;
    }

    if (event.key === "Enter") {
      event.preventDefault();
      leadSearchDropdown.innerHTML = "";
      this.appendSearchValue(event);
    } else {
      this.timeout = setTimeout(() => {
        const form = document.getElementById("lead_dropdown_form");
        if (form) {
          form.requestSubmit();
        }
      }, 400);
    }
  }

  appendSearchValue(event) {
    const targetDiv = this.appendSearchedValueTarget;
    const targetUl = targetDiv.querySelector("ul");
    const liElement = event.currentTarget;
    let itemValue = "";
    if (liElement.type == "text") {
      itemValue = liElement.value;
    } else {
      itemValue = liElement.querySelector("h6").textContent.trim();
    }
    targetDiv.classList.remove("hidden");

    const newLi = document.createElement("li");
    newLi.className = "leading-[23px]";
    if (targetUl && targetUl.children.length < 2) {
      newLi.innerHTML = `
        <span class="db-tags text-[#05123799]! bg-[#0512370A]! font-normal! border-0! p-[5px_12px]! rounded-[100px]!">
          ${itemValue}
          <i class="icon-x text-[#05123799] text-[12px]" data-action="click->portal--lead--search#removeSearchValue"></i>
        </span>
      `;
      targetUl.appendChild(newLi);
    } else {
      const newtargetDiv = this.hasLeadMoreValuesTarget
        ? this.leadMoreValuesTarget
        : document.getElementById("more_filters");
      const button = this.hasLeadMoreButtonTarget
        ? this.leadMoreButtonTarget
        : document.getElementById("more_filters_button");
      const newtargetUl = newtargetDiv.querySelector("ul");

      button.classList.remove("hidden!");

      newLi.innerHTML = `
        <span class="flex justify-between no-wrap p-0!" data-controller="portal--lead--search">
          <div class="flex db-profile relative min-h-[44px] items-center gap-[10px] flex-1 px-[10px]">
            <div class="flex items-center gap-1.5">
              <h6 class="text-[14px] leading-5 font-normal text-[#051237]/[0.76] font-text_font m-0 line-clamp-1">${itemValue}</h6>
            </div>
          </div>
          <i class="icon-x text-[#05123799] text-[12px] mr-2" data-action="click->portal--lead--search#removeMoreSearchValue"></i>
        </span>
      `;
      newtargetUl.appendChild(newLi);

      button.textContent = `+ ${newtargetUl.children.length} More`;
    }

    this.clearSearchInput();
  }

  clearSearchInput() {
    const input = document.getElementById("lead-search-input");
    const frame = document.getElementById("lead-search-drop-down");

    if (input) input.value = "";
    if (frame) frame.innerHTML = "";
  }

  hideFilterDropdown(event) {
    const filterDropdownBtn = document.getElementById("lead-filter-btn");
    if (!this.hasLeadFiltersTarget) {
      return;
    }
    closeOnOutsideClick(
      filterDropdownBtn,
      this.leadFiltersTarget,
      event.target
    );
  }

  closeDropdown(dropdown) {
    dropdown.innerHTML = "";
  }

  OpenFiltersDropdown() {
    if (!this.hasLeadFiltersTarget) {
      return false;
    }
    this.leadFiltersTarget.classList.toggle("hidden");
  }

  toggleDropdown(target) {
    target.classList.toggle("show");
  }

  openMoreDropdown() {
    if (!this.hasLeadMoreValuesTarget) {
      return false;
    }
    this.toggleDropdown(this.leadMoreValuesTarget);
  }

  handleOnChange(event) {
    const fieldId = `lead_search_${event.currentTarget.id}`;
    const value = event.currentTarget.checked ? "true" : "";
    this.loadTableSkeleton(true);
    this.submitSearchForm(fieldId, value);
  }

  keywordSearch(event) {
    const value = event.currentTarget.value;

    clearTimeout(this.timeout);
    this.loadTableSkeleton(true);

    this.timeout = setTimeout(() => {
      this.submitSearchForm("lead_keyword_search", value);
    }, 1000);
  }

  loadTableSkeleton(showSkeleton) {
    const table = document.getElementById("leadTable");
    if (table && showSkeleton) {
      table.classList.add("istableloading");
    } else {
      table && table.classList.remove("istableloading");
    }
  }

  submitFiltersForm(event) {
    const modal = document.getElementById("add-new-filters-popup");
    const form = event.currentTarget.form;
    if (form && modal) {
      form.addEventListener("turbo:submit-end", (event) => {
        form.reset();
        closeModal("add-new-filters-popup");
      });
    }
  }

  submitSearchForm(fieldId, value) {
    const hiddenField = document.getElementById(fieldId);
    const form = document.getElementById("lead_person_search_form");
    if (hiddenField) {
      hiddenField.value = value;
    }
    if (form) {
      form.addEventListener("turbo:submit-end", (event) => {
        this.loadTableSkeleton(false);
      });

      form.requestSubmit();
    }
  }

  searchLeadSmartFilter(event) {
    const query = event.target.value.toLowerCase();
    const filterItems = document.querySelectorAll('#lead_all_filters_list li');
    let matchesFound = false;

    if (query.trim() === "") {
      filterItems.forEach(item => {
        item.style.display = "block";
      });

      let noFilterMessage = document.querySelector('#no-filter-message');
      if (noFilterMessage) {
        noFilterMessage.remove();
      }
      return;
    }

    filterItems.forEach(item => {
      const filterTitle = item.querySelector('label').textContent.toLowerCase();

      if (filterTitle.includes(query)) {
        item.style.display = "block";
        matchesFound = true;
      } else {
        item.style.display = "none";
      }
    });

    let noFilterMessage = document.querySelector('#no-filter-message');

    if (!matchesFound) {
      if (!noFilterMessage) {
        noFilterMessage = document.createElement('li');
        noFilterMessage.id = 'no-filter-message';
        noFilterMessage.textContent = 'No Filter Found';
        noFilterMessage.style.padding = '10px';
        noFilterMessage.style.color = '#999';
        noFilterMessage.classList = 'text-[14px] text-center leading-[20px] font-normal text-heading_color font-text_font';
        document.querySelector('#lead_all_filters_list').appendChild(noFilterMessage);
      }
    } else {
      if (noFilterMessage) {
        noFilterMessage.remove();
      }
    }
  }



  updateFiltersCount(names, type) {
    const fieldId = `lead_filter_${type}_count`;
    const length = names.length;
    const filter = document.getElementById(fieldId);
    if (filter && length) {
      filter.textContent = length;
    }
  }

  removeSearchValue(event) {
    const li = event.currentTarget.closest("li");
    if (li) {
      li.remove();
    }
    this.resetSearchCount();
  }

  removeMoreSearchValue(event) {
    const li = event.currentTarget.closest("li");
    const more_btn = document.getElementById("more_filters_button");

    if (li.parentElement.childElementCount === 1) {
      more_btn.classList.add("hidden!");
    } else {
      more_btn.textContent = `+ ${li.parentElement.childElementCount - 1} More`;
    }

    li.remove();
    this.resetSearchCount();
  }

  resetSearchCount() {
    const button = document.getElementById("more_filters_button");
    if (!this.hasLeadMoreValuesTarget) {
      return;
    }
    const children = this.leadMoreValuesTarget?.querySelector("ul")?.children;
    console.log(children.length);
    if (children) {
      if (children.length === 0) {
        button.classList.add("hidden!");
      }
      button.innerText = `${children.length} More`;
    }
  }

  clearFilter(event) {
    this.loadTableSkeleton(true);
    const fieldId = `lead_search_${event.currentTarget.id}`;
    this.submitSearchForm(fieldId, null);
  }

  applySorting(event) {
    const dataset = event.currentTarget.dataset;
    const hidden1 = document.getElementById("lead_sort_by");
    const hidden2 = document.getElementById("lead_sort_order");
    if (dataset) {
      hidden1.value = dataset.type;
      hidden2.value = dataset.sortOrder;
      const form = document.getElementById("lead_person_search_form");
      if (form) {
        form.requestSubmit();
      }
    }
  }

  UpdateActiveFilters(event) {
    const target = event.currentTarget;
    if (target) {
      const boolean = target.checked ? 1 : 0;
      this.submitPinnedLeadForm(boolean, target.value);
    }
  }

  removeActiveFilter(event) {
    const id = event.currentTarget.dataset.id;
    this.submitPinnedLeadForm(0, id);
  }

  submitPinnedLeadForm(is_pinned, value) {
    document.getElementById("lead_active_state").value = is_pinned;
    document.getElementById("lead_id_active_form").value = value;
    document.getElementById("lead_active_form").value = new URL(window.location.href).searchParams.get('lead');
    const form = document.getElementById("activeLeadForm");
    if (form) {
      form.requestSubmit();
    }
  }

  exportCSV() {
    const modal = document.getElementById("export-popup");
    const form = document.getElementById("lead-csv-export-form");
    if (form && modal) {
      form.requestSubmit();
      closeModal("export-popup");
    }
  }

  disabledAction(event) {
    event.preventDefault();
    const disabledItems = document.getElementsByClassName("should_disabled");
    Array.from(disabledItems).forEach((item) => {
      if (event.currentTarget.dataset.tab == "myProjects") {
        item.classList.add("db-disablebtn");
      } else {
        if (event.currentTarget.dataset.disabled == "false") {
          item.classList.remove("db-disablebtn");
        }
      }
    });
  }

  userLeadTitleSearch(event) {
    const value = event.currentTarget.value;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.submitLeadTitleSearch(value, "user_lead");
    }, 1000);
  }

  shareLeadTitleSearch(event) {
    const search_value = event.currentTarget.value;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.submitLeadTitleSearch(search_value, "share_lead");
    }, 1000);
  }

  submitLeadTitleSearch(title, type) {
    const form = document.getElementById("leadTitleSearchForm");
    if (form) {
      document.getElementById("lead_title_search_type").value = type;
      document.getElementById("lead_title_search_title").value = title;

      form.requestSubmit();
    }
  }
}
