export function validateInput(inputElement) {
  const ERROR_MESSAGE = "This field is required";
  if (inputElement.value == null || inputElement.value == "") {
    this.errorMessage(inputElement, ERROR_MESSAGE);
  }
}

export function errorMessage(element, error_message) {
  const spanElement = element.nextElementSibling;
  if (spanElement && spanElement.tagName === "SPAN") {
    spanElement.id = "element_error_message";
    spanElement.classList.add("text-rose-600", "text-sm");
    spanElement.textContent = error_message;
  }
}

export function removeErrorMessage(element) {
  if (!element) return;
  element.remove();
}

export function generateFileInfo(file) {
  if (!file) return;

  const fileName = file.name;
  const fileSizeInMB = file.size / 1024;
  const fileExtension = fileName
    .substring(fileName.lastIndexOf(".") + 1)
    .toLowerCase();

  return {
    fileName: fileName,
    fileSizeInMB: fileSizeInMB,
    fileExtension: fileExtension,
  };
}

export function isValidEmail(email) {
  if (!email) return;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function checkedAndUnCheckedAllCheckboxes(element, is_checked) {
  const record = document.querySelectorAll(`.${element}`);
  record.forEach((checkbox) => {
    checkbox.checked = is_checked;
  });
}

export function getCheckedValues(targetClass, targetName = "data-id") {
  const checkboxes = document.querySelectorAll(`.${targetClass}`);
  return Array.from(checkboxes)
    .filter((checkbox) => checkbox.checked)
    .map((checkbox) => checkbox.getAttribute(targetName));
}

export function checkedCheckboxesCount(className) {
  return document.querySelectorAll(`.${className}:checked`).length;
}

export function loaderForEnrichment(title){
  return `<div class="is-fetching flex gap-[6px] items-center bg-[rgba(5,18,55,0.04)] whitespace-nowrap w-full rounded-lg p-[7px_10px] justify-center">
    <span class="text-[12px] !hover:bg-none p-0! text-[rgba(5,18,55,0.76)] leading-[16px] font-text_font font-normal">${title}
    </span>
    <div class="loader"></div>
  </div>`;
}