import { Controller } from "@hotwired/stimulus";
import { closeModal, openModal } from "../components/modal";

let billingId = null;

export default class extends Controller {
  static targets = [];

  cancelSubscriptionForm(event) {
    event.preventDefault();
    if (billingId == null) return;
    event.currentTarget.classList.add("pointer-events-none", "opacity-50");
    this.togglerLoader();
    this.handleTeamDelete(billingId);
  }

  handleTeamDelete(id) {
    const form = document.getElementById(`billing_${id}`);
    if (!form) return;
    form.addEventListener(
      "turbo:submit-end",
      () => {
        closeModal("delete-shortlist--people-modal");
        this.togglerLoader();
      },
      { once: true }
    );
    form.requestSubmit();
  }

  openCancelSubscriptionModal(event) {
    event.preventDefault();
    billingId = event.currentTarget.dataset.id;
    openModal("delete-shortlist--people-modal");
  }

  closeCancelSubscriptionModal(event) {
    billingId = null;
    closeModal("delete-shortlist--people-modal");
  }

  togglerLoader() {
    const loader = document.getElementsByClassName("db-loader");
    if (loader.length == 0) return;

    loader.forEach((loader) => {
      if (loader.classList.contains("hidden!")) {
        loader.classList.remove("hidden!");
      } else {
        loader.classList.add("hidden!");
      }
    });
  }
}
