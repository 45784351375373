import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["portalSideBar", "teamDropdown"];

  connect() {
    if (window.innerWidth > 1023) {
      this.isSidebarActive();
    }
    const logoSm = document.querySelector(".logo-sm");
    if (window.innerWidth < 1024) {
      logoSm.style.display = "none";
    } else {
      logoSm.style.display = "";
    }
    this.navSidebar();

    const mediaQuery = window.matchMedia("(max-width: 1025px)");
    const childSidebar = document.getElementById("child_sidebar");
    if (mediaQuery.matches && childSidebar) {
      if (childSidebar.classList.contains("-translate-x-full")) {
        childSidebar.classList.remove("-translate-x-full");
      } else {
        childSidebar.classList.add("-translate-x-full");
      }
    }
    this.handleChildSidebar();
  }

  navSidebar() {
    const openNav = document.getElementById("openaside-nav");
    const sidebar = document.getElementById("db-sidebar");
    const closeNav = document.getElementById("closenav");
    const menuLinks = document.querySelectorAll(".menulist ul li a");
    const childSidebarToggle = document.getElementById("child_sidebar_toggle");

    if (!openNav) {
      return false;
    }

    if (!closeNav) {
      return false;
    }

    const toggleSidebar = () => {
      if (
        sidebar.classList.contains("max-w-[260px]!") &&
        sidebar.classList.contains("translate-x-0!")
      ) {
        sidebar.classList.remove("max-w-[260px]!", "translate-x-0!");
      } else {
        sidebar.classList.add("max-w-[260px]!", "translate-x-0!");
      }
    };

    const toggleChildSidebar = () => {
      const mediaQuery = window.matchMedia("(max-width: 1025px)");
      if (mediaQuery.matches) {
        const childSidebar = document.getElementById("child_sidebar");
        if (!childSidebar) {
          return false;
        }

        childSidebar.classList.toggle("-translate-x-full");
      }
    };

    openNav.addEventListener("click", (e) => {
      toggleSidebar();
    });

    childSidebarToggle &&
      childSidebarToggle.addEventListener("click", (e) => {
        toggleSidebar();
        toggleChildSidebar();
      });

    closeNav.addEventListener("click", function (event) {
      event.preventDefault();
      toggleSidebar();
    });

    menuLinks.forEach(function (link) {
      link.addEventListener("click", function (event) {
        setTimeout(() => {
          sidebar.classList.remove("max-w-[260px]!");
          sidebar.classList.remove("translate-x-0!");
        }, 50);
        toggleSidebar();
        toggleChildSidebar();
      });
    });
  }

  triggerFunctionForSmallScreens() {
    document.addEventListener("DOMContentLoaded", function () {
      document.addEventListener("click", function (e) {
        const trigger = e.target.closest("[data-dropdown-trigger]");
        if (trigger) {
          e.preventDefault();

          const targetDropdown = trigger.getAttribute("data-dropdown-trigger");

          document
            .querySelectorAll("[data-dropdown]")
            .forEach(function (dropdown) {
              if (dropdown.getAttribute("data-dropdown") !== targetDropdown) {
                dropdown.classList.remove("translate-y-0", "opacity-100");
                dropdown.classList.add("hidden", "opacity-0", "-translate-y-2");
              }
            });

          const dropdown = document.querySelector(
            `[data-dropdown="${targetDropdown}"]`
          );
          if (dropdown) {
            if (dropdown.classList.contains("hidden")) {
              dropdown.classList.remove(
                "hidden",
                "-translate-y-2",
                "lg:opacity-0"
              );
              dropdown.classList.add("translate-y-0", "opacity-100");
            } else {
              dropdown.classList.add("hidden", "-translate-y-2", "opacity-0");
              dropdown.classList.remove("translate-y-0", "opacity-100");
            }
          }
        }
      });
    });
  }

  handleChildSidebar() {
    const getDataAttribute = () => {
      const url = window.location.href.toLowerCase();
      if (
        url.includes("bookings") ||
        url.includes("clients") ||
        url.includes("manage-payments") ||
        url.includes("booking-settings") ||
        url.includes("manage-campaign") ||
        url.includes("availability-calendars")
      )
        return "booking";
      if (
        url.includes("leads") ||
        url.includes("leads-company") ||
        url.includes("campaign")
      )
        return "leads";
      if (
        url.includes("projects") ||
        url.includes("client") ||
        url.includes("payment")
      )
        return "projects";
      if (
        [
          "disputes",
          "pricing",
          "subscription",
          "team",
          "help",
          "notification",
          "integrations",
        ].some((keyword) => url.includes(keyword))
      )
        return "setting";
    };

    const attribute = getDataAttribute();
    if (attribute) {
      const element = document.querySelector(
        `[data-dropdown-trigger='${attribute}']`
      );
      if (element) {
        element.removeAttribute("href");
      }
    }
  }

  toggleSidebar(event) {
    const activeSidebar = (is_active) => {
      localStorage.setItem("is_sidebar_active", is_active);
    };

    if (window.innerWidth > 1023) {
      this.element.classList.toggle("side-navigation");
    }
    const is_active = this.element.classList.contains("side-navigation")
      ? true
      : false;
    activeSidebar(is_active);
  }

  isSidebarActive() {
    const state = localStorage.getItem("is_sidebar_active");
    if (state == "true") {
      this.element.classList.toggle("side-navigation");
    }
  }

  openAccountEvent() {
    if (this.hasTeamDropdownTarget) {
      this.teamDropdownTarget.classList.toggle("hidden");
    }
  }

  leaveSidebar() {
    if (
      this.hasTeamDropdownTarget &&
      !this.teamDropdownTarget.classList.contains("hidden")
    ) {
      this.teamDropdownTarget.classList.add("hidden");
    }
  }
}
