import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["goldFeatureModal", "switch"];

  closeFeatureModal() {
    document.body.style.overflow = "";
    if (this.hasGoldFeatureModalTarget && this.goldFeatureModalTarget) {
      this.goldFeatureModalTarget.remove();
    } else {
      document.getElementById("gold_subscription").remove();
    }
  }
  toggle() {
    const checkbox = this.switchTarget;
    checkbox.checked = !checkbox.checked;
  }
  openFeatureModal() {
    document.body.style.overflow = "hidden";
  }

  toggleTab(event) {
    const tabID = event.currentTarget.dataset.tab;

    const tabLinks = document.querySelectorAll(".tablinks");
    tabLinks.forEach((link) => {
      if (link.dataset.tab == tabID) {
        link.classList.add("active");
      } else {
        link.classList.remove("active");
      }
    });

    const tabContent = document.querySelectorAll(".tabcontent");
    tabContent.forEach((content) => {
      if (content.id == tabID) {
        content.style.display = "block";
        content.classList.add("active");
      } else {
        content.style.display = "none";
        content.classList.remove("active");
      }
    });
  }
}
