import { Controller } from "@hotwired/stimulus";
import {
  hideElement,
  openModal,
  closeModal,
  showElement,
  closeOnOutsideClick,
} from "../../components/modal";
import { toggleDropdown } from "../../components/dropdown";
import { checkedAndUnCheckedAllCheckboxes, loaderForEnrichment } from "../../components/validation";
export default class extends Controller {
  static targets = ["aiSearchButton"];
  static values = {
    searchHistory: String,
  };
  connect() {
    this.setSearchHistoryInParams();

    this.typingTimeout = null;
    if (this.hasAiSearchButtonTarget) {
      this.aiSearchButtonTarget.disabled = true;
    }

    document.addEventListener("click", (e) => {
      const three_dots = document.querySelector(".icon-dots-vertical");
      const three_dots_menu = document.querySelector(".db-dropdown");

      closeOnOutsideClick(three_dots, three_dots_menu, e.target);

      if (e.target.closest("#checkedAllInPeopleList")) {
        checkedAndUnCheckedAllCheckboxes("profile_checkbox", e.target.checked);
        document.getElementsByClassName("profile_list").forEach((item) => {
          e.target.checked
            ? item.classList.add("profile_select")
            : item.classList.remove("profile_select");
        });
      }
    });

    jQuery("#ai_search_sort_filter").off("change");
    jQuery("#ai_search_sort_filter")
      .select2()
      .on("change", (e) => {
        e.preventDefault();
        this.applySortFilter(e);
      });
  }

  disconnect() {
    const destroySelect2 = (selector) => {
      const element = $(this.element).find(selector);
      if (element.length && $.fn.select2 && element.data("select2")) {
        element.select2("destroy");
      }
    };

    destroySelect2("#ai_search_sort_filter");
  }

  setSearchHistoryInParams() {
    if (this.searchHistoryValue != "") {
      const searchHistory = document.querySelectorAll("#search_history");
      searchHistory.forEach((history) => {
        history.value = this.searchHistoryValue;
      });
      const params = new URLSearchParams(window.location.search);
      params.set("search_history", this.searchHistoryValue);

      const newUrl = `${window.location.pathname}?${params.toString()}`;
      history.pushState({}, "", newUrl);
    }
  }

  chatAndFilterToggle({ params: { tab } }) {
    if (tab === "filter") {
      showElement("chat-opener");
      hideElement("chat-content");

      hideElement("filter-opener");
      showElement("filter-content");
    }

    if (tab == "chat") {
      showElement("filter-opener");
      hideElement("filter-content");

      hideElement("chat-opener");
      showElement("chat-content");
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  submitFilter(event) {
    event.preventDefault();
    const currentTarget = event.currentTarget;
    const form = document.getElementById("search-filter-form");
    if (form) {
      currentTarget.disabled = true;
      form.querySelector("#show_results_count").value = "false";
      const loading_icon = currentTarget.lastElementChild;
      loading_icon.classList.remove("hidden");

      form.addEventListener(
        "turbo:submit-end",
        () => {
          currentTarget.disabled = false;
          loading_icon.classList.add("hidden");
        },
        { once: true }
      );
      form.requestSubmit();
    }
  }

  handleTyping() {
    this.aiSearchButtonTarget.disabled = true;

    clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(() => {
      this.aiSearchButtonTarget.disabled = false;
    }, 1000);
  }

  updateItemsListing(event) {
    const target = document.getElementById("ai_search_page_listing");
    const form = document.getElementById("search-filter-form");
    if (target && form) {
      document.getElementById("show_results_count").value = false;
      target.value = event.currentTarget.value;
      form.requestSubmit();
    }
  }

  openCriterialModal() {
    openModal("criteria-modal-popup");
  }

  closeCriterialModal() {
    closeModal("criteria-modal-popup");
  }

  onEnterCriteriaSubmit(event) {
    event.currentTarget.form.requestSubmit();
    document.getElementById(
      "criteria-action-buttons"
    ).innerHTML = `<span class="db-loader border-bg-white w-[10px]! h-[10px]! mt-[9px] mr-[8px]"></span>`;
  }

  updateCriteriaModalFrame(event) {
    const id = event.currentTarget.id;
    if (!id) return false;

    const path = `/criteria/edit?chat_id=${id}`;
    const target = document.getElementById("add-criteria-button");
    const link = `<a id="add-criteria-button" href="${path}" data-turbo-stream="true" class="mt-2 flex gap-[4px] items-center text-[12px] leading-[16px] font-medium text-[#3076FF] tracking-[0.2px] font-text_font flex-none">
                  <i class="anron-plus text-[13px] cursor-pointer"></i> Add Criteria
                  </a>`;

    target.outerHTML = link;
  }

  togglePastFilter(event) {
    event.target.checked
      ? showElement(event.params.togglediv)
      : hideElement(event.params.togglediv);
  }

  applySortFilter(event) {
    const target = document.getElementById("ai_search_page_sort");
    const form = document.getElementById("search-filter-form");
    if (target && form) {
      document.getElementById("show_results_count").value = false;
      target.value = event.currentTarget.value;
      form.requestSubmit();
    }
  }

  changeView(event) {
    event.preventDefault();

    const view = event.params.view;
    const urlParams = new URLSearchParams(window.location.search);
    const currentView = urlParams.get("view") || "grid";
    if (view === currentView) return false;

    const history = event.params.history || urlParams.get("search_history");
    urlParams.set("view", view);

    if (history) {
      urlParams.set("search_history", history);
    }
    window.location.href = `${
      window.location.pathname
    }?${urlParams.toString()}`;
  }

  openDropdown(el) {
    const selector = el.currentTarget;
    const dropdownID = selector?.dataset?.dropdownElementId;
    if (!selector || !dropdownID) return;

    toggleDropdown(selector, "v2--ai-search portal--shortlist");
  }

  submitSuggestedSearch(event){
    const text = event.currentTarget.innerText.trim()
    const form = document.getElementById("ai_assistant_search_form");
    const textArea = form.querySelector("textarea")

    if(text && form && textArea){
      textArea.value = text;
      form.requestSubmit();
    }
  }

  submitAiSearchonEnter(event) {
    if (event.key === "Enter") {
      const target = event.currentTarget;
      if(target.value.trim() !== ""){
        target.form.submit();
        this.aiSearchButtonTarget.disabled = true;
      }
    }
  }

  enrichmentSubmitLoader(event) {
    const target = event.currentTarget;
    const title = target.dataset.title;
    const loader = loaderForEnrichment(title);
    target.innerHTML = loader;
  }
}
