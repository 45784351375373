import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="profile-navigation"
export default class extends Controller {
  connect() {}

  navigate({ params }) {
    if (!params || !params?.id) return false;

    const viewDetailsButton = document.getElementById(
      `view_detail_${params?.id}`
    );
    if (viewDetailsButton && !params.hasvideo) {
      viewDetailsButton.click();
    }
  }
}
