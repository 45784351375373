import AirDatepicker from "air-datepicker";
import { createPopper } from "@popperjs/core";

export function initAirDatePicker(element, onSelectCallback) {
  new AirDatepicker(`#${element}`, defaultOptions(onSelectCallback));
}

const defaultOptions = (onSelectCallback) => {
  return {
    autoClose: true,
    startDate: new Date(),
    buttons: ["today", "clear"],
    onSelect(event) {
      if (typeof onSelectCallback === "function") {
        onSelectCallback(event);
      }
    },
    locale: {
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      monthsShort: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      today: "Today",
      clear: "Clear",
      dateFormat: "yyyy-MM-dd",
      timeFormat: "hh:mm aa",
      firstDay: 0,
    },
    position({ $datepicker, $target, $pointer, done }) {
      const popper = createPopper($target, $datepicker, {
        placement: "top",
        modifiers: [
          {
            name: "flip",
            options: { padding: { top: 14 } },
          },
          {
            name: "offset",
            options: { offset: [0, 10] },
          },
          {
            name: "arrow",
            options: { element: $pointer },
          },
        ],
      });

      return function completeHide() {
        popper.destroy();
        done();
      };
    },
  };
};
