import { Controller } from "@hotwired/stimulus";

let timeout;
let typing = true;
let charIndex = 0;
let textIndex = 0;
let mapboxInstance = null;

export default class extends Controller {
  static targets = [
    "loginModal",
    "people_ids",
    "mapboxzoom",
    "AiSearchInput",
    "loginM",
    "mainM",
    "signupM",
    "confirmM",
    "resetPasswordM",
  ];
  connect() {
    this.magicLinkInterval;
    this.getProfiles();
    this.searchTypingEffect();

    this.reinitializeMapBox();
    $("#user_name").val("");

    const animationSelector = document.querySelector(".animation");
    if (animationSelector) {
      this.animateWord(animationSelector, 2500);
    }
  }

  reinitializeMapBox() {
    const myObserver = new ResizeObserver((entries) => {
      entries.forEach(async (entry) => {
        if (!this.peoples_array || this.peoples_array.length == 0) {
          this.getProfiles();
          return;
        }
        this.destroyMapBox();

        const currentWidth =
          entry.contentRect.width != 0
            ? entry.contentRect.width
            : window.innerWidth;
        await this.initMap(
          this.calculateMapWidth(currentWidth),
          this.peoples_array
        );
      });
    });

    const someEl = document.getElementsByTagName("body")[0];
    myObserver.observe(someEl);
  }

  calculateMapWidth(browserWidth) {
    let zoomLevel = 1.5;

    // start mobile devices
    // cover devices
    // iphone 5,Se/X/XR/11/11 pro/11 pro max/ 12mini/12 pro/ 12 pro max/ 13 mini/ 13/ 13 pro max/ and other android devices
    if (browserWidth > 300 && browserWidth < 359) {
      zoomLevel = 1.3;
    }
    if (browserWidth >= 360 && browserWidth < 428) {
      zoomLevel = 1.4;
    }
    // end mobile devices

    // tablets start
    if (browserWidth >= 428 && browserWidth < 768) {
      zoomLevel = 1.5;
    }

    if (browserWidth >= 768 && browserWidth < 1024) {
      zoomLevel = 1.8;
    }

    // for microsoft surface duo
    if (browserWidth >= 1024 && browserWidth < 1115) {
      zoomLevel = 2.2;
      // zoomLevel = 3;
    }
    // end tablets

    // for laptops
    if (browserWidth >= 1115 && browserWidth < 1280) {
      zoomLevel = 2.8;
      // zoomLevel = 3.1;
    }

    // macbook pro
    if (browserWidth >= 1280 && browserWidth < 1600) {
      zoomLevel = 2.7;
      // zoomLevel = 3.5;
    }

    if (browserWidth >= 1600) {
      zoomLevel = 3;
      // zoomLevel = 4;
    }

    // end laptops
    return zoomLevel;
  }

  destroyMapBox() {
    if (mapboxInstance) {
      mapboxInstance.remove();
      delete window.mapboxInstance;
      mapboxInstance = null;
    }
  }
  disconnect() {
    this.destroyMapBox();
    clearTimeout(timeout);
  }

  searchTypingEffect() {
    if (!this.hasAiSearchInputTarget) return;

    const searchInput = this.AiSearchInputTarget;
    const textArray = searchInput.dataset.placeholdertext.split("|");
    this.type(textArray, searchInput);
  }

  type(texts, input) {
    if (typing) {
      if (charIndex < texts[textIndex].length) {
        input.placeholder += texts[textIndex].charAt(charIndex);
        charIndex++;
        timeout = setTimeout(() => this.type(texts, input), 50);
      } else {
        typing = false;
        timeout = setTimeout(() => this.type(texts, input), 1000);
      }
    } else {
      if (charIndex > 0) {
        input.placeholder = input.placeholder.substring(0, charIndex - 1);
        charIndex--;
        timeout = setTimeout(() => this.type(texts, input), 50);
      } else {
        typing = true;
        textIndex = (textIndex + 1) % texts.length;
        timeout = setTimeout(() => this.type(texts, input), 200);
      }
    }
  }

  toggleLoginModal(event) {
    event.preventDefault();
    this.loginModalTarget.classList.contains("hidden")
      ? this.loginModalTarget.classList.remove("hidden")
      : this.loginModalTarget.classList.add("hidden");

    this.confirmMTarget.classList.add("hidden");

    const staticClickElement = this.element.querySelector(".static-click");

    if (staticClickElement) {
      if (!this.loginModalTarget.classList.contains("hidden")) {
        staticClickElement.blur();
        staticClickElement.disabled = true;
      } else {
        staticClickElement.disabled = false;
      }
    }

    this.rerenderModal();
  }

  toggleGoldLoginModal(event) {
    event.preventDefault();

    // Show or hide the modal
    this.loginModalTarget.classList.contains("hidden")
      ? this.loginModalTarget.classList.remove("hidden")
      : this.loginModalTarget.classList.add("hidden");

    // Manually send the backend request using fetch
    this.sendBackendRequest();

    this.rerenderModal();
  }

  sendBackendRequest() {
    fetch("/gold_features/show_modal", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
      },
    })
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  rerenderModal() {
    this.mainMTarget.classList.remove("hidden");
    this.loginMTarget.classList.add("hidden");
    this.signupMTarget.classList.add("hidden");
    this.resetPasswordMTarget.classList.add("hidden");
  }
  swapModal() {
    this.mainMTarget.classList.add("hidden");
    this.loginMTarget.classList.remove("hidden");

    // reset the forms
    // document.getElementById("magic-link-form").classList.add("hidden");
    document.getElementById("password-form").classList.remove("hidden");
    this.removeMagicLinkForm();
  }
  hideLoginModal() {
    document.getElementById("password-form").classList.add("hidden");
  }

  removeMagicLinkForm() {
    const magicLink = document.getElementById("magic-link-form");
    if (magicLink) {
      magicLink.innerHTML = "";
    }
  }

  signupModal() {
    this.loginMTarget.classList.add("hidden");
    this.signupMTarget.classList.remove("hidden");
  }

  backToSignup() {
    this.signupMTarget.classList.add("hidden");
    this.resetPasswordMTarget.classList.add("hidden");
    this.loginMTarget.classList.remove("hidden");
  }

  resetPasswordModal() {
    this.loginMTarget.classList.add("hidden");
    this.signupMTarget.classList.add("hidden");
    this.resetPasswordMTarget.classList.remove("hidden");
  }

  backToMain() {
    this.loginMTarget.classList.add("hidden");
    this.mainMTarget.classList.remove("hidden");

    let magicLinkForm = document.getElementById("magic-link-form");
    let passwordForm = document.getElementById("password-form");
    if (magicLinkForm) {
      magicLinkForm.classList.remove("hidden");
    }
    if (passwordForm) {
      passwordForm.classList.add("hidden");
    }
  }

  async loginWithMagicLink(event) {
    event.preventDefault();
    const email = document.getElementById("magiclink_email").value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email == "" || !emailRegex.test(email)) {
      const error_message = document.getElementById("email-error-message");
      if (error_message) {
        error_message.innerText = "Please enter a valid email address";
        error_message.classList.remove("hidden");
      }
      return;
    }

    const turnsTileToken = document.querySelector(
      "input[name='cf-turnstile-response']"
    ).value;
    if (!turnsTileToken || turnsTileToken == "") return false;

    const canSubmit = await this.checkMagicLinkAlreadySendOrNot(
      event.currentTarget,
      email
    );
    if (canSubmit && event.currentTarget) {
      event.currentTarget.classList.add("loader-btn");
      const magicLinkSpinner = document.getElementById("magic-link-spinner");
      if (magicLinkSpinner) {
        magicLinkSpinner.classList.remove("hidden");
      }

      event.currentTarget.form.requestSubmit();
      document.getElementById("__maglick-email").innerText = email;

      if (this.hasLoginMTarget) {
        this.loginMTarget.classList.add("hidden");
      }

      if (this.hasConfirmMTarget) {
        this.confirmMTarget.classList.remove("hidden");
      }

      event.currentTarget.classList.remove("loader-btn");
      if (magicLinkSpinner) {
        magicLinkSpinner.classList.add("hidden");
      }

      document.getElementById("email-error-message").classList.add("hidden");
      document.getElementById("magiclink_email").value = "";
    }
  }

  handleMagicLinkInput(event) {
    const error_message = document.getElementById("email-error-message");
    const magicLinkButton = document.getElementById("magic-link-button");
    if (error_message && magicLinkButton) {
      error_message.innerText = "";
      error_message.classList.add("hidden");
      magicLinkButton.disabled = false;
      clearInterval(this.magicLinkInterval);
    }
  }

  checkMagicLinkAlreadySendOrNot(currentTarget, email) {
    return new Promise((resolve) => {
      const lastSentTime = localStorage.getItem(`magic_link_sent_to_${email}`);

      if (lastSentTime) {
        this.magicLinkInterval = setInterval(() => {
          const value = localStorage.getItem(`magic_link_sent_to_${email}`);
          const timeDiff = Math.floor((Date.now() - parseInt(value)) / 1000);

          const remainingTime = 90 - timeDiff;
          const error_message = document.getElementById("email-error-message");

          if (remainingTime > 0) {
            if (error_message) {
              error_message.innerText = `Please wait ${remainingTime} seconds before requesting the magic link again.`;
              error_message.classList.remove("hidden");
              currentTarget.disabled = true;
            }
          } else {
            if (error_message) {
              error_message.innerText = "";
              error_message.classList.add("hidden");
              currentTarget.disabled = false;
            }
            clearInterval(this.magicLinkInterval);
            this.clearMagicLinkStorage();
            resolve(true);
          }
        }, 1000);
      } else {
        localStorage.setItem(`magic_link_sent_to_${email}`, Date.now());
        resolve(true);
      }
    });
  }

  clearMagicLinkStorage() {
    Object.keys(localStorage).map((key) => {
      if (key.startsWith("magic_link_sent_to_")) {
        localStorage.removeItem(key);
      }
    });
  }

  async getProfiles() {
    try {
      if (this.hasPeople_idsTarget) {
        let personIds = JSON.parse(this.people_idsTarget.dataset.people);
        if (personIds.length > 0) {
          const peoples = await this.getMapViewData(personIds);
          this.peoples_array = peoples;
          await this.initMap(
            this.calculateMapWidth(window.innerWidth),
            peoples
          );
        }
      }
    } catch (error) {
      console.error("Error fetching map view data:", error);
      return null;
    }
  }

  async getMapViewData(personIds) {
    try {
      const response = await fetch("/search/map_view_data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ person_ids: personIds }),
      });
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error("Error fetching map view data:", error);
      return null;
    }
  }

  isValidCoordinate(lng, lat) {
    return lng >= -180 && lng <= 180 && lat >= -90 && lat <= 90;
  }

  async initMap(mapZoom, mapData) {
    const mapboxSelector = document.getElementById("__map");
    if (mapboxInstance != null || !mapboxSelector) return;

    mapboxgl.accessToken =
      "pk.eyJ1IjoiYWxleHRvcGxpbmUiLCJhIjoiY2x5NjFobzl4MDVvZTJrcHgxODY1YjJlNiJ9.2Ubqk43wiSfQTnlVya0W4w";
    mapboxInstance = new mapboxgl.Map({
      container: "__map",
      style: "mapbox://styles/alextopline/clyhc2ful00xq01pm53gefe5s/draft",
      center: [-100, 50],
      zoom: mapZoom,
      pitch: 60,
      projection: "globe",
    });

    mapboxInstance.on("load", function () {
      mapboxInstance.addSource("mapbox-streets", {
        type: "vector",
        url: "mapbox://mapbox.mapbox-streets-v8",
      });

      mapboxInstance.addLayer({
        id: "country-labels",
        type: "symbol",
        source: "mapbox-streets",
        "source-layer": "place_label",
        filter: ["==", ["get", "class"], "country"],
        layout: {
          "text-field": ["get", "name_en"],
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-size": 14,
        },
        paint: {
          "text-color": "#9A9EC1",
        },
      });
    });

    mapData.forEach((person) => {
      const el = document.createElement("div");
      el.className = "custom-marker cursor-pointer";
      el.style.backgroundImage = `url(${person.avatar_url})`;
      el.style.width = "35px";
      el.style.height = "35px";
      el.style.backgroundSize = "cover";
      el.style.borderRadius = "50%";
      const img = new Image();
      img.src = person.avatar_url;
      img.onload = () => {
        el.style.backgroundImage = `url(${person.avatar_url})`;
        this.createMarker(el, person, mapboxInstance);
      };
      img.onerror = () => {
        el.style.backgroundImage = `url(images/default_profile.svg)`;
        el.style.width = "35px";
        el.style.height = "35px";
        el.style.backgroundSize = "cover";
        el.style.borderRadius = "50%";
        el.dataset.name = "images/default_profile.svg";
        this.createMarker(el, person, mapboxInstance);
      };
    });

    mapboxInstance.on("zoom", () => {
      const zoom = mapboxInstance.getZoom();
      const globeContent = document.querySelector(".globe-content");
      const header = document.querySelector(".portal-header");
      if (zoom >= this.handleContentOverlay(window.innerWidth)) {
        globeContent.style.zIndex = -1;
      } else {
        globeContent.removeAttribute("style");
      }
      if (zoom >= this.handleMapboxIndex(window.innerWidth)) {
        header.style.zIndex = -1;
      } else {
        header.style.zIndex = 50;
      }
    });

    $(".wave-skeleton").addClass("hidden");
  }
  handleContentOverlay(browserWidth) {
    let zoomLevel = 3.7;
    if (browserWidth >= 360 && browserWidth < 428) {
      zoomLevel = 1.35;
    }
    if (browserWidth >= 1280 && browserWidth < 1600) {
      zoomLevel = 3;
    }
    return zoomLevel;
  }

  handleMapboxIndex(browserWidth) {
    let zoomLevel = 3.65;

    if (browserWidth > 300 && browserWidth < 359) {
      zoomLevel = 3.55;
    }
    if (browserWidth >= 360 && browserWidth < 428) {
      zoomLevel = 4.6;
    }
    // end mobile devices

    // tablets start
    if (browserWidth >= 428 && browserWidth < 768) {
      zoomLevel = 5;
    }

    if (browserWidth >= 768 && browserWidth < 1024) {
      zoomLevel = 5;
    }

    // for microsoft surface duo
    if (browserWidth >= 1024 && browserWidth < 1114) {
      zoomLevel = 5.2;
    }
    // end tablets

    // for laptops
    if (browserWidth >= 1114 && browserWidth < 1280) {
      zoomLevel = 4.3;
    }

    // macbook pro
    if (browserWidth >= 1280 && browserWidth < 1600) {
      zoomLevel = 4.8;
    }

    if (browserWidth >= 1600) {
      zoomLevel = 5.1;
    }

    // end laptops
    return zoomLevel;
  }

  flyToRandomProfiles(should_fly = true) {
    if (should_fly) {
      const randomIndex = Math.floor(
        Math.random() * this.peoples_array.length || 0
      );
      const people = this.peoples_array[randomIndex];
      var targetCoordinates = [
        parseFloat(people.longitude),
        parseFloat(people.latitude),
      ];
      if (this.isValidCoordinate(targetCoordinates[0], targetCoordinates[1])) {
        mapboxInstance.flyTo({
          center: targetCoordinates,
          zoom: 5.3,
          essential: true,
        });
      }
    } else {
      mapboxInstance.flyTo({
        center: [-95, 40],
        zoom: 3.1,
        essential: true,
      });
    }
  }

  createMarker(element, person, map) {
    const marker =
      element instanceof HTMLElement ? new mapboxgl.Marker(element) : element;

    const person_name =
      person.name.length > 15 ? person.name.slice(0, 15) + "..." : person.name;

    const person_position =
      person.position != null
        ? person.position.length > 25
          ? person.position.slice(0, 25) + "..."
          : person.position
        : "";

    let person_image = person.avatar_url;
    if (element?.dataset?.name) {
      person_image = element?.dataset?.name;
    }

    const content = `<div class="relative bg-white p-[18px] rounded-[10px] max-w-[260px] w-full">
    <div class="flex gap-[10px]">
        <img class="w-[38px] h-[38px] rounded-full" src="${person_image}" alt="user">
        <div class="flex flex-col">
            <div class="flex gap-[6px]">
                <span title="${
                  person.name
                }" class="text-[14px] font-medium leading-[20px] font-text_font text-heading_color"> ${person_name} </span>
            </div>
            ${
              person.position != null && person.position != "--"
                ? `<p title="${person.position}" class="font-normal mt-[3px] text-[12px] line-clamp-1 leading-4 not-italic tracking-[0.2px] font-text_font text-text_color">
            ${person_position}
          </p>`
                : ""
            }
            
            <ul class="p-0 m-[10px_0_0_0] flex flex-col gap-1">
            ${
              person.industry != null && person.industry != "--"
                ? `<li class="list-none">
                      <div class="flex justify-start items-center gap-[7px]">
                          <span class="h-4 w-4 flex items-center justify-center"><i class="icon-briefcase text-text_color"></i></span>
                          <em class="font-normal text-[12px] not-italic leading-4 font-text_font text-text_color block">${person.industry}</em>
                      </div>
                  </li>`
                : ""
            }

            ${
              person.city != null && person.city != ""
                ? `<li class="list-none">
                      <div class="flex justify-start items-center gap-[7px]">
                          <span class="h-4 w-4 flex items-center justify-center"><i class="icon-location-marker text-text_color"></i></span>
                          <em class="font-normal text-[12px] not-italic leading-4 font-text_font text-text_color block">${person.city}</em>
                      </div>
                  </li>`
                : ""
            }
            </ul>
        </div>
    </div>
      <a href="${window.location.origin}/people/${
      person.key
    }" class="db-secondary-button-sm mt-[10px]">View Details</a>
    </div>`;

    const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(content);

    if (person.longitude && person.latitude) {
      marker
        .setLngLat([person.longitude, person.latitude])
        .setPopup(popup)
        .addTo(map);
    }
  }

  animateWord(animNode, delay) {
    const animationWindow = animNode.querySelector(".animation-window");
    const ul = animationWindow?.querySelector("ul");
    const lis = ul?.querySelectorAll("li");

    if (!animationWindow || !ul || lis.length === 0) {
      return;
    }

    // Create a cloned gray list
    const grayList = animationWindow.cloneNode(true);
    const grayUl = grayList.querySelector("ul");

    grayList.classList.remove("animation-window");
    grayList.classList.add("animation-gray-list");
    animNode.insertBefore(grayList, animationWindow);

    // Utility to dynamically adjust the width of the `ul` based on the content
    const handleWidthAccordingToContent = (text) => {
      const tempSpan = document.createElement("span");
      tempSpan.style.visibility = "hidden";
      tempSpan.style.whiteSpace = "nowrap";
      tempSpan.style.position = "absolute";
      tempSpan.style.font = window.getComputedStyle(ul).font;
      tempSpan.textContent = text;
      document.body.appendChild(tempSpan);
      const width = tempSpan.offsetWidth + 2; // Add padding
      document.body.removeChild(tempSpan);
      return width;
    };

    // Function to navigate to the current list item
    const goTo = (liNum) => {
      const li = lis[liNum];
      const liTop = li.getBoundingClientRect().top;
      const ulTop = ul.getBoundingClientRect().top;
      const liOffset = liTop - ulTop;

      // Adjust width dynamically based on the text length
      ul.style.width = `${handleWidthAccordingToContent(
        li.textContent.trim()
      )}px`;
      ul.style.transition = "top 0.3s ease"; // Smooth transition for ul
      grayUl.style.transition = "top 0.3s ease"; // Smooth transition for grayUl
      ul.style.top = `${-liOffset}px`;
      grayUl.style.top = `${-liOffset}px`;
    };

    let current = 0;

    // Sequential animation logic
    const animateSequence = () => {
      goTo(current);
      current = (current + 1) % lis.length; // Loop back to the start
    };

    // Start the animation
    return setInterval(animateSequence, delay);
  }
}
