export function toggleClearOption(filterKey, isEnabled) {
  if (!filterKey) return false;

  const element = document.querySelector(`[data-filter-key="${filterKey}"]`);
  if (!element) return false;

  isEnabled
    ? element.classList.remove("hidden")
    : element.classList.add("hidden");
}
