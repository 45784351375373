import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="child-sidebar"
export default class extends Controller {
  connect() {}

  toggleMenu(event) {
    event.preventDefault();
    const eventDivElement = event.target.closest("div");
    if (!eventDivElement) return false;

    const elementHeight = parseInt(
      window.getComputedStyle(eventDivElement).getPropertyValue("max-height")
    );

    eventDivElement.style.maxHeight =
      elementHeight === 36 ? `${eventDivElement.scrollHeight}px` : "36px";
  }
}
