document.addEventListener("turbo:load", () => {
  $("[modal-target]").on("click", function (e) {
    e.preventDefault();
    var _this = $(this);
    var id = _this.attr("modal-target");
    $("#" + id).removeClass("hidden");
    $("body").addClass(`modal-open-${id}`);
  });

  $(".close-modal").on("click", function (e) {
    var _this = $(this);
    var id = _this.parents(".modal").attr("id");
    $("body").removeClass(`modal-open-${id}`);
    _this.parents(".modal").addClass("hidden");
  });

  $(".modal-close").on("click", function (e) {
    var _this = $(this);
    _this.parents(".modal").addClass("hidden");
  });

  $(
    ".has-children > li, .user-social-dropdown , .edit-popup-dropdown , .user-info , .notification-button , .notification-dropdownlist , .social-dropdown"
  ).click(function () {
    $(this).toggleClass("sub-menu-show");
  });

  const tabLinks = document.querySelectorAll(".tablinks");
  tabLinks.forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();

      var link_childNodes = link.parentNode.childNodes;

      for (let i = 0; i < link_childNodes.length; i++) {
        let currentNode = link_childNodes[i];
        if (
          currentNode.classList !== undefined &&
          currentNode.classList.contains("tablinks")
        ) {
          currentNode.classList.remove("active");
        }
      }

      const tabId = link.getAttribute("data-tab");

      var tab_content = document.getElementById(tabId);
      var tab_child_nodes = tab_content.parentNode.childNodes;

      for (let i = 0; i < tab_child_nodes.length; i++) {
        let currentNode = tab_child_nodes[i];
        if (
          currentNode.classList !== undefined &&
          currentNode.classList.contains("tabcontent")
        ) {
          currentNode.style.display = "none";
          currentNode.classList.remove("active");
        }
      }

      tab_content.style.display = "block";
      tab_content.classList.add("active");

      link.classList.add("active");
    });
  });

  var tab_content = document.querySelector(".tabcontent");
  var tablinks = document.querySelector(".tabcontent");

  if (tab_content) {
    tab_content.style.display = "block";
  }

  if (tablinks) {
    tablinks.classList.add("active");
  }
  document.querySelector(".open-search")?.addEventListener("click", (e) => {
    e.preventDefault();
    e.target.closest(".open-search-menu").classList.add("sub-menu-show");
  });
  document.querySelector(".close-search")?.addEventListener("click", (e) => {
    e.preventDefault();
    e.target.closest(".open-search-menu").classList.remove("sub-menu-show");
  });

  document
    .querySelector(".close-mobile-search")
    ?.addEventListener("click", (e) => {
      e.preventDefault();
      e.target.closest(".sidebar-open").classList.remove("sidebar-open-close");
    });

  var config = {
    "#select1": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select2": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select3": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select4": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select5": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select6": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select7": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select8": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select9": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select10": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select11": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select12": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select13": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select14": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select15": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select16": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select17": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select18": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select19": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select20": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select21": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select22": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select23": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select24": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select25": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select26": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select27": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select28": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select29": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select30": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select31": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select32": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select33": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select34": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select35": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select36": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select37": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select38": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select39": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select40": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select41": { allowClear: true, minimumResultsForSearch: Infinity },
    "#select42": { allowClear: true, minimumResultsForSearch: Infinity },
  };
  for (var selector in config) {
    if ($(selector).length) {
      jQuery(selector).select2(config[selector]);
    }
  }

  if ($("#select6").length) {
    $("#select6").select2({
      tags: true,
      tokenSeparators: [",", " "],
    });
  }
  var elements = document.getElementsByClassName("rails-select");
  Array.from(elements).forEach((e) => {
    jQuery(e).select2({
      allowClear: true,
      minimumResultsForSearch: Infinity,
    });
  });

  if ($("#skills_mutiple").length) {
    $("#skills_mutiple").select2({
      placeholder: "Skill (ex: Project Management)",
      tags: true,
    });

    $("#skills_mutiple").on("select2:open select2:close", function () {
      let searchField = $(".select2-search__field");
      searchField.attr("placeholder", "Skill (ex: Project Management)");
      searchField.on("input", function () {
        if ($(this).val() === "") {
          $(this).attr("placeholder", "Skill (ex: Project Management)");
        }
      });
    });

    $("#skills_mutiple").on("change", function () {
      var selectedOptions = $(this).val();
      var displayDiv = $("#selected-options");

      if (selectedOptions == null || selectedOptions == "") {
        $(".new_skills").remove();
      } else {
        $("#skill_fallback_case").addClass("hidden");
      }

      if (selectedOptions && selectedOptions.length > 0) {
        selectedOptions.forEach(function (option, index) {
          if (!displayDiv.find("li:contains('" + option + "')").length) {
            displayDiv.append(
              `<li id="__skill_${index}" class="new_skills">
              <span class="bg-[#0512370A] cursor-pointer text-[#051237C2] text-[12px] leading-4 font-medium font-text_font p-[8px_12px] inline-flex gap-[8px] items-center justify-center rounded-[50px] hover:bg-[#FFEDF0] hover:text-[#FD306E] [&_i:hover]:text-[#FD306E]">${option} 
                <i data-action="click->skills#removeSkill" class="icon-x" data-index="${index}"></i>
              </span>
            </li>`
            );
          }
        });
      } else {
        let is_shown = false;
        const skill_list = document.getElementById("selected-options");
        if (skill_list) {
          is_shown =
            skill_list.querySelectorAll("li").length == 0 ? true : false;
        }

        if (is_shown) {
          displayDiv.append(
            `<div id="skill_fallback_case" class='flex w-full flex-col items-center p-[30px_20px] bg-[#05123705] rounded-[8px]'><strong class='text-[16px] font-medium leading-6 font-text_font text-[#051237E0] block text-center'>No skills added yet</strong></div>`
          );
        }
      }
    });
  }
});
