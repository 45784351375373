export function profilebadge(badgeNumber) {
  const badges = {
    1: { badgeSVG: starOne(), text: "Top 1%" },
    5: { badgeSVG: starTwo(), text: "Top 5%" },
    10: { badgeSVG: starThree(), text: "Top 10%" },
  };

  return badges[badgeNumber] || { badge: starFour(), text: "Rising star" };
}

export function profilebadgeText(badgeNumber) {
  const badgesText = {
    1: "Top 1%",
    5: "Top 5%",
    10: "Top 10%",
  };
  return badgesText[badgeNumber] || "Rising star";
}

const starOne = () => {
  return `<svg width="17" height="17" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.19115 0.922462C9.15577 0.360482 10.3442 0.360482 11.3088 0.922461L17.1912 4.34946C18.1558 4.91144 18.75 5.95002 18.75 7.07398V13.928C18.75 15.0519 18.1558 16.0905 17.1912 16.6525L11.3088 20.0795C10.3442 20.6415 9.15577 20.6415 8.19115 20.0795L2.30885 16.6525C1.34423 16.0905 0.75 15.0519 0.75 13.928V7.07398C0.75 5.95002 1.34423 4.91144 2.30885 4.34946L8.19115 0.922462Z" fill="url(#paint0_linear_38940_36139)"/>
        <path d="M5.46469 9.11028C5.56832 8.77389 6.0195 8.77389 6.12312 9.11028L6.60438 10.6726C6.65073 10.823 6.78364 10.9248 6.9336 10.9248H8.491C8.82633 10.9248 8.96575 11.3774 8.69446 11.5853L7.4345 12.5509C7.31318 12.6439 7.26241 12.8087 7.30875 12.9591L7.79002 14.5214C7.89364 14.8578 7.52862 15.1375 7.25734 14.9296L5.99737 13.964C5.87605 13.8711 5.71177 13.8711 5.59044 13.964L4.33048 14.9296C4.05919 15.1375 3.69418 14.8578 3.7978 14.5214L4.27906 12.9591C4.32541 12.8087 4.27464 12.6439 4.15332 12.5509L2.89335 11.5853C2.62207 11.3774 2.76149 10.9248 3.09682 10.9248H4.65422C4.80418 10.9248 4.93709 10.823 4.98343 10.6726L5.46469 9.11028Z" fill="white"/>
        <path d="M13.3769 9.11028C13.4805 8.77389 13.9317 8.77389 14.0353 9.11028L14.5166 10.6726C14.5629 10.823 14.6958 10.9248 14.8458 10.9248H16.4032C16.7385 10.9248 16.8779 11.3774 16.6066 11.5853L15.3467 12.5509C15.2254 12.6439 15.1746 12.8087 15.2209 12.9591L15.7022 14.5214C15.8058 14.8578 15.4408 15.1375 15.1695 14.9296L13.9096 13.964C13.7882 13.8711 13.6239 13.8711 13.5026 13.964L12.2427 14.9296C11.9714 15.1375 11.6064 14.8578 11.71 14.5214L12.1912 12.9591C12.2376 12.8087 12.1868 12.6439 12.0655 12.5509L10.8055 11.5853C10.5343 11.3774 10.6737 10.9248 11.009 10.9248H12.5664C12.7164 10.9248 12.8493 10.823 12.8956 10.6726L13.3769 9.11028Z" fill="white"/>
        <path d="M9.42065 3.25326C9.52427 2.91688 9.97545 2.91688 10.0791 3.25326L10.5603 4.81555C10.6067 4.96598 10.7396 5.06784 10.8896 5.06784H12.4469C12.7823 5.06784 12.9217 5.52043 12.6504 5.72833L11.3905 6.69388C11.2691 6.78685 11.2184 6.95165 11.2647 7.10208L11.746 8.66437C11.8496 9.00075 11.4846 9.28047 11.2133 9.07258L9.95332 8.10703C9.832 8.01406 9.66772 8.01406 9.54639 8.10703L8.28643 9.07258C8.01514 9.28047 7.65013 9.00075 7.75375 8.66437L8.23501 7.10208C8.28136 6.95165 8.23059 6.78685 8.10927 6.69388L6.84931 5.72833C6.57802 5.52043 6.71744 5.06784 7.05277 5.06784H8.61017C8.76013 5.06784 8.89304 4.96598 8.93938 4.81555L9.42065 3.25326Z" fill="white"/>
        <defs>
          <linearGradient id="paint0_linear_38940_36139" x1="9.7499" y1="0.0135094" x2="9.7499" y2="20.9869" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFA104"/>
            <stop offset="1" stop-color="#FACD0E"/>
          </linearGradient>
        </defs>
    </svg>`;
};

const starTwo = () => {
  return `<svg width="17" height="17" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.19115 0.922462C9.15577 0.360482 10.3442 0.360482 11.3088 0.922461L17.1912 4.34946C18.1558 4.91144 18.75 5.95002 18.75 7.07398V13.928C18.75 15.0519 18.1558 16.0905 17.1912 16.6525L11.3088 20.0795C10.3442 20.6415 9.15577 20.6415 8.19115 20.0795L2.30885 16.6525C1.34423 16.0905 0.75 15.0519 0.75 13.928V7.07398C0.75 5.95002 1.34423 4.91144 2.30885 4.34946L8.19115 0.922462Z" fill="url(#paint0_linear_38940_36066)"/>
            <path d="M5.39349 7.27182C5.5057 6.9107 5.9943 6.9107 6.10651 7.27182L6.67577 9.10378C6.72595 9.26528 6.86988 9.37462 7.03227 9.37462H8.87442C9.23756 9.37462 9.38854 9.86051 9.09476 10.0837L7.60443 11.2159C7.47305 11.3157 7.41807 11.4926 7.46825 11.6541L8.03751 13.4861C8.14972 13.8472 7.75445 14.1475 7.46067 13.9243L5.97033 12.7921C5.83895 12.6923 5.66105 12.6923 5.52966 12.7921L4.03933 13.9243C3.74555 14.1475 3.35028 13.8472 3.46249 13.4861L4.03174 11.6541C4.08193 11.4926 4.02695 11.3157 3.89557 11.2159L2.40524 10.0837C2.11146 9.86051 2.26244 9.37462 2.62557 9.37462H4.46772C4.63012 9.37462 4.77405 9.26528 4.82423 9.10378L5.39349 7.27182Z" fill="white"/>
            <path d="M13.3935 7.27182C13.5057 6.9107 13.9943 6.9107 14.1065 7.27182L14.6758 9.10378C14.7259 9.26528 14.8699 9.37462 15.0323 9.37462H16.8744C17.2376 9.37462 17.3885 9.86051 17.0948 10.0837L15.6044 11.2159C15.473 11.3157 15.4181 11.4926 15.4683 11.6541L16.0375 13.4861C16.1497 13.8472 15.7544 14.1475 15.4607 13.9243L13.9703 12.7921C13.839 12.6923 13.661 12.6923 13.5297 12.7921L12.0393 13.9243C11.7456 14.1475 11.3503 13.8472 11.4625 13.4861L12.0317 11.6541C12.0819 11.4926 12.027 11.3157 11.8956 11.2159L10.4052 10.0837C10.1115 9.86051 10.2624 9.37462 10.6256 9.37462H12.4677C12.6301 9.37462 12.7741 9.26528 12.8242 9.10378L13.3935 7.27182Z" fill="white"/>
            <defs>
              <linearGradient id="paint0_linear_38940_36066" x1="9.75" y1="0.500977" x2="9.75" y2="20.501" gradientUnits="userSpaceOnUse">
                <stop stop-color="#6964FA"/>
                <stop offset="1" stop-color="#AE6AFB"/>
              </linearGradient>
            </defs>
          </svg>`;
};

const starThree = () => {
  return `<svg width="17" height="17" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.19115 0.922462C9.15577 0.360482 10.3442 0.360482 11.3088 0.922461L17.1912 4.34946C18.1558 4.91144 18.75 5.95002 18.75 7.07398V13.928C18.75 15.0519 18.1558 16.0905 17.1912 16.6525L11.3088 20.0795C10.3442 20.6415 9.15577 20.6415 8.19115 20.0795L2.30885 16.6525C1.34423 16.0905 0.75 15.0519 0.75 13.928V7.07398C0.75 5.95002 1.34423 4.91144 2.30885 4.34946L8.19115 0.922462Z" fill="url(#paint0_linear_38940_54613)"/>
          <path d="M9.27496 6.38181C9.42463 5.92115 10.0763 5.92115 10.226 6.38181L10.9853 8.71869C11.0523 8.9247 11.2442 9.06418 11.4608 9.06418H13.918C14.4023 9.06418 14.6037 9.68399 14.2119 9.96869L12.224 11.413C12.0488 11.5403 11.9754 11.766 12.0424 11.972L12.8017 14.3089C12.9513 14.7695 12.4241 15.1526 12.0322 14.8679L10.0444 13.4236C9.86914 13.2963 9.63184 13.2963 9.45659 13.4236L7.46872 14.8679C7.07687 15.1526 6.54963 14.7695 6.6993 14.3089L7.4586 11.972C7.52554 11.766 7.45221 11.5403 7.27697 11.413L5.2891 9.96869C4.89724 9.68399 5.09863 9.06418 5.58299 9.06418H8.04013C8.25674 9.06418 8.44872 8.9247 8.51566 8.71869L9.27496 6.38181Z" fill="white"/>
          <defs>
            <linearGradient id="paint0_linear_38940_54613" x1="9.75" y1="0.500977" x2="9.75" y2="20.501" gradientUnits="userSpaceOnUse">
              <stop offset="0.159423" stop-color="#3358F0"/>
              <stop offset="1" stop-color="#5DC5FC"/>
            </linearGradient>
          </defs>
          </svg>`;
};

const starFour = () => {
  return `<svg width="17" height="17" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.19115 0.922462C9.15577 0.360482 10.3442 0.360482 11.3088 0.922461L17.1912 4.34946C18.1558 4.91144 18.75 5.95002 18.75 7.07398V13.928C18.75 15.0519 18.1558 16.0905 17.1912 16.6525L11.3088 20.0795C10.3442 20.6415 9.15577 20.6415 8.19115 20.0795L2.30885 16.6525C1.34423 16.0905 0.75 15.0519 0.75 13.928V7.07398C0.75 5.95002 1.34423 4.91144 2.30885 4.34946L8.19115 0.922462Z" fill="url(#paint0_linear_38940_55111)"/>
          <mask id="mask0_38940_55111" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="5" y="-2" width="18" height="17">
            <path d="M9.10198 6.77344C9.2581 6.29296 9.93785 6.29296 10.094 6.77344L10.7689 8.85053C10.8387 9.06541 11.0389 9.21089 11.2648 9.21089H13.4488C13.954 9.21089 14.1641 9.85737 13.7554 10.1543L11.9885 11.438C11.8057 11.5708 11.7292 11.8062 11.799 12.0211L12.4739 14.0982C12.63 14.5787 12.0801 14.9782 11.6714 14.6813L9.90451 13.3976C9.72173 13.2648 9.47422 13.2648 9.29143 13.3976L7.52456 14.6813C7.11584 14.9782 6.56591 14.5787 6.72203 14.0982L7.39691 12.0211C7.46673 11.8062 7.39025 11.5708 7.20746 11.438L5.44059 10.1543C5.03187 9.85737 5.24192 9.21089 5.74713 9.21089H7.9311C8.15704 9.21089 8.35728 9.06541 8.4271 8.85053L9.10198 6.77344Z" fill="white"/>
            <path d="M17.3119 -1.1091L11.0403 4.88838C10.8936 5.03506 10.6247 5.03506 10.478 4.88838C10.3314 4.7417 10.3314 4.4728 10.478 4.32612L16.7497 -1.67136C16.8964 -1.81804 17.1653 -1.81804 17.3119 -1.67136C17.4586 -1.52469 17.4586 -1.25578 17.3119 -1.1091ZM19.7826 0.787051L13.3871 7.23522C13.2404 7.38189 12.9715 7.38189 12.8249 7.23522C12.6782 7.08854 12.6782 6.81963 12.8249 6.67296L19.2203 0.224791C19.367 0.0781145 19.6359 0.0781145 19.7826 0.224791C19.9293 0.371468 19.9293 0.640374 19.7826 0.787051ZM22.1646 3.13596L15.7339 9.58205C15.5873 9.72872 15.3184 9.72872 15.1717 9.58205C15.025 9.43537 15.025 9.16646 15.1717 9.01979L21.6023 2.5737C21.749 2.42703 22.0179 2.42703 22.1646 2.5737C22.3113 2.72038 22.3113 2.98928 22.1646 3.13596Z" fill="white"/>
          </mask>
          <g mask="url(#mask0_38940_55111)">
            <path d="M8.19115 0.923316C9.15577 0.361336 10.3442 0.361336 11.3088 0.923316L17.1912 4.35031C18.1558 4.91229 18.75 5.95088 18.75 7.07484V13.9288C18.75 15.0528 18.1558 16.0914 17.1912 16.6534L11.3088 20.0803C10.3442 20.6423 9.15577 20.6423 8.19115 20.0803L2.30885 16.6534C1.34423 16.0914 0.75 15.0528 0.75 13.9288V7.07483C0.75 5.95087 1.34423 4.91229 2.30885 4.35031L8.19115 0.923316Z" fill="white"/>
          </g>
          <defs>
            <linearGradient id="paint0_linear_38940_55111" x1="9.75" y1="0.500977" x2="9.75" y2="20.501" gradientUnits="userSpaceOnUse">
              <stop stop-color="#02A472"/>
              <stop offset="1" stop-color="#00FF95"/>
            </linearGradient>
          </defs>
          </svg>`;
};
