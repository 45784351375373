import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "textarea",
    "counter",
    "textarea1",
    "counter1",
    "textarea2",
    "counter2",
    "textarea3",
    "counter3",
    "endYear",
    "currentlyPresent",
  ];

  connect() {
    this.textareaTargets.forEach((textarea, index) => {
      textarea.addEventListener("input", () => {
        this.updateCounter(textarea, index);
      });
      this.updateCounter(textarea, index);
    });
    if (this.hascurrentlyPresentTarget) {
      this.toggleEndYear();
    }

    $("#educations").on("cocoon:after-insert", function (e) {
      let elements = e.target.getElementsByClassName("currently_present");
      Array.from(elements).forEach(function (element) {
        let uniqueId = "edu-present-" + Math.random().toString(36).substr(2, 9);
        element.id = uniqueId;
        element.nextElementSibling.setAttribute("for", uniqueId);
      });
    });
    $("#experiences").on("cocoon:after-insert", function (e) {
      let elements = e.target.getElementsByClassName("exp_currently_present");
      Array.from(elements).forEach(function (element) {
        let uniqueId = "exp-present" + Math.random().toString(36).substr(2, 9);
        element.id = uniqueId;
        element.nextElementSibling.setAttribute("for", uniqueId);
      });
    });
  }
  toggleEndYear(event) {
    const endYearSelect =
      event?.currentTarget?.parentElement?.previousElementSibling?.lastElementChild?.querySelector(
        ".endYear"
      );

    if (endYearSelect && endYearSelect?.tagName === "SELECT") {
      if (event.target.checked) {
        endYearSelect.disabled = true;
      } else {
        endYearSelect.disabled = false;
      }
    }
  }

  updateCounter(textarea, index) {
    const maxLength = parseInt(textarea.getAttribute("maxlength")) || 500;
    const remaining = maxLength - textarea.value.length;
    const counter = this.counterTargets[index];
    if (counter) {
      counter.textContent = `${remaining} characters remaining`;
      if (remaining <= 0) {
        textarea.value = textarea.value.substring(0, maxLength);
        counter.textContent = "0 characters remaining";
      }
    }
  }

  removeExpHiddenClass(event) {
    event.preventDefault();
    const template =
      document.getElementsByClassName("exp-node")[0]?.dataset
        ?.associationInsertionTemplate;
    const experiencesElement = this.element.querySelector(".experiences");
    experiencesElement.innerHTML = template;
    if (experiencesElement) {
      experiencesElement.classList.remove("hidden");
    }
  }

  addExpHiddenClass(event) {
    event.preventDefault();
    const experiencesElement = this.element.parentElement.parentElement;
    if (experiencesElement) {
      if (experiencesElement.dataset.experience != "") {
        if (this.element.previousElementSibling) {
          this.element.previousElementSibling.value = true;
        }
      }

      const targetParent =
        event.target.parentElement?.parentElement?.parentElement;
      if (targetParent && experiencesElement?.dataset?.experience == "") {
        targetParent.innerHTML = "";
      }
      const hiddenInputs = targetParent.querySelectorAll(
        'input[type="hidden"]'
      );
      const lastHiddenInput = hiddenInputs[hiddenInputs.length - 1];

      if (lastHiddenInput) {
        const clonedInput = lastHiddenInput.cloneNode(true);

        targetParent.innerHTML = "";

        targetParent.appendChild(clonedInput);
      }
    }
  }

  removeEduHiddenClass(event) {
    event.preventDefault();
    const template =
      document.getElementsByClassName("edu-node")[0]?.dataset
        ?.associationInsertionTemplate;
    const educationsElement = this.element.querySelector(".educations");
    if (educationsElement) {
      educationsElement.innerHTML = template;
      educationsElement.classList.remove("hidden");
    }
  }

  addEduHiddenClass(event) {
    event.preventDefault();
    const educationsElement = this.element.parentElement;
    if (educationsElement) {
      if (educationsElement.dataset.education != "") {
        const lastChild = this.element.lastElementChild;

        if (lastChild && lastChild.previousElementSibling) {
          lastChild.previousElementSibling.value = true;
        }
      }

      const greatGrandParentElement =
        event.target.parentElement?.parentElement?.parentElement;
      if (
        greatGrandParentElement &&
        educationsElement.dataset.education == ""
      ) {
        greatGrandParentElement.innerHTML = "";
      }
      greatGrandParentElement.classList.add("hidden");
    }
  }

  checkFormValidation(event) {
    const form = event.currentTarget.form;

    if (!form.checkValidity()) {
      const faqDrawer = form.querySelectorAll(".faq-drawer");
      faqDrawer.forEach((drawer) => {
        const title = this.getRequiredFieldValue(drawer, "experienceTitle");
        const startDate = this.getRequiredFieldValue(drawer, "startDate");
        const currentlyWorking = this.getRequiredFieldValue(
          drawer,
          "currentlyWorking"
        );

        let isWorking = false;
        if (currentlyWorking != "0") {
          const endDate = this.getRequiredFieldValue(drawer, "endDate");
          isWorking = !endDate ? true : false;
        }

        if ((!title || !startDate || !currentlyWorking) && !isWorking) {
          drawer.firstElementChild.checked = true;
        }
      });
    }
  }

  checkEducationFormValidation(event) {
    const form = event.currentTarget.form;

    if (!form.checkValidity()) {
      const faqDrawer = form.querySelectorAll(".faq-drawer");
      faqDrawer.forEach((drawer) => {
        const institute = this.getRequiredValue(drawer, "institute");
        const degree = this.getRequiredValue(drawer, "degree");
        const field = this.getRequiredValue(drawer, "field");
        const startYear = this.getRequiredValue(drawer, "startYear");
        const currentlyWorking = this.getRequiredFieldValue(
          drawer,
          "currentlyWorking"
        );

        let isWorking = false;
        if (currentlyWorking != "0") {
          const endDate = this.getRequiredValue(drawer, "endDate");
          isWorking = !endDate ? true : false;
        }

        if (
          (!institute ||
            !degree ||
            !field ||
            !startYear ||
            !currentlyWorking) &&
          !isWorking
        ) {
          drawer.firstElementChild.checked = true;
        }
      });
    }
  }

  getRequiredValue(drawer, element) {
    return drawer?.querySelector(`#${element}`)?.lastElementChild.value;
  }

  getRequiredFieldValue(drawer, element) {
    return drawer?.querySelector(`#${element}`)?.firstElementChild.value;
  }
}
