import { Controller } from "@hotwired/stimulus";
let firstRun = true;
export default class extends Controller {
  connect() {
    this.checkAndTrackLoginModal();
    if (!firstRun) return;
    firstRun = false;
    this.loadCloudflareTurnstile("turnstile-widget");
  }

  loadCloudflareTurnstile(elementID) {
    const turnstileElements = document.getElementById(elementID);
    if (!turnstileElements) return;

    if (
      !turnstileElements.hasAttribute("turnstile-rendered") &&
      window.turnstile
    ) {
      window.turnstile.render(turnstileElements, {
        callback: function (token) {},
      });
      turnstileElements.setAttribute("turnstile-rendered", "true");
    }
  }

  checkAndTrackLoginModal() {
    const loginModal = document.getElementById("login-modal");
    if (loginModal && !loginModal.classList.contains("hidden")) {
      this.loadCloudflareTurnstile("turnstile-widget-md");
    }
  }
}
