import { Controller } from "@hotwired/stimulus";
import {
  checkedAndUnCheckedAllCheckboxes,
  getCheckedValues,
} from "../components/validation";
import { toast } from "../components/snackbar";
export default class extends Controller {
  connect() {
    this.timeout = null;
    document.addEventListener("DOMContentLoaded", function () {
      document.body.addEventListener("click", (event) => {
        const button = this.getElementById("approve_all_button");
        if (event.target.classList.contains("profile_checkbox")) {
          if (event.target.checked) {
            button.disabled = true;
          } else {
            const values = getCheckedValues("profile_checkbox");
            if (values.length == 0) {
              button.disabled = false;
            }
          }
        }

        if (event.target.id == "select_all") {
          button.disabled = event.target.checked ? true : false;
        }
      });
    });
  }

  toggleSelectAll(event) {
    checkedAndUnCheckedAllCheckboxes(
      "profile_checkbox",
      event.currentTarget.checked
    );
  }

  approveSelectedProfiles(event) {
    event.preventDefault();
    const values = getCheckedValues("profile_checkbox");
    if (values.length == 0) {
      toast("Please select at least one profile", "error");
      return;
    }
    const form = event.currentTarget.form;
    if (!form) {
      return false;
    }
    const profile_ids = document.getElementById("profile_ids");
    profile_ids.value = JSON.stringify(values);

    form.addEventListener(
      "turbo:submit-end",
      () => {
        const approveButton = document.getElementById("approve_all_button");
        if (approveButton) {
          approveButton.disabled = false;
        }
      },
      { once: true }
    );

    event.currentTarget.form.requestSubmit();
  }

  debounceSearch(event) {
    const value = event.target.value;

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const form = document.getElementById("search-form");
      if (form) {
        form.requestSubmit();
      }
    }, 2000);
  }
}
