import { Controller } from "@hotwired/stimulus";

const SOURCE_EARTHQUAKE = "earthquakes";
const SPIDERIFY_AFTER_ZOOM = 14; // Spiderify after zoom N, zoom otherwise
const SPIDER_TYPE = "layer"; // marker: use Mapbox's Marker. layer: Use a Mabpbox point layer
const MAX_LEAVES_TO_SPIDERIFY = 255; // Max leave to display when spiderify to prevent filling the map with leaves
const CIRCLE_TO_SPIRAL_SWITCHOVER =
  SPIDER_TYPE.toLowerCase() === "marker" ? 10 : 15; // When below number, will display leave as a circle. Over, as a spiral

const CIRCLE_OPTIONS = {
  distanceBetweenPoints: 50,
};

const SPIRAL_OPTIONS = {
  rotationsModifier: 1250, // Higher modifier = closer spiral lines
  distanceBetweenPoints: SPIDER_TYPE.toLowerCase() === "marker" ? 42 : 32, // Distance between points in spiral
  radiusModifier: 50000, // Spiral radius
  lengthModifier: 1000, // Spiral length modifier
};

const SPIDER_LEGS = true;
const SPIDER_LEGS_LAYER_NAME = `spider-legs-${Math.random()
  .toString(36)
  .substr(2, 9)}`;

const SPIDER_LEAVES_LAYER_NAME = `spider-leaves-${Math.random()
  .toString(36)
  .substr(2, 9)}`;

let clusterMarkers = [];
let spiderifiedCluster = {};
let spiderLeavesCollection = [];

export default class extends Controller {
  static targets = ["map"];

  async connect() {
    try {
      let mapString = this.data.get("map");
      mapString = mapString.slice(1, -1);
      let mapArray = mapString.split(",");
      const personIds = mapArray.map(Number);
      const mapData = await this.getMapViewData(personIds);
      // this.initMapBox(mapData);
      this.spiderfyMapbox(mapData);
      $(".shimmer").addClass("hidden");
    } catch (error) {}
  }

  spiderfyMapbox(mapData) {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYWxleHRvcGxpbmUiLCJhIjoiY2x5NjFobzl4MDVvZTJrcHgxODY1YjJlNiJ9.2Ubqk43wiSfQTnlVya0W4w";
    let map = new mapboxgl.Map({
      container: "__flat_map",
      style: "mapbox://styles/mapbox/streets-v12",
      projection: "mercator",
      center: [-103.59179687498357, 40.66995747013945],
      zoom: 3,
      maxZoom: 14,
    });

    map.on("load", () => {
      map
        .on("click", "clusters", (e) => {
          let features = map.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });
          let clusterId = features[0].properties.cluster_id;

          if (map.getZoom() < SPIDERIFY_AFTER_ZOOM) {
            map
              .getSource(SOURCE_EARTHQUAKE)
              .getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;
                map.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom,
                });
              });
          } else {
            this.spiderifyCluster({
              map: map,
              source: SOURCE_EARTHQUAKE,
              clusterToSpiderify: {
                id: clusterId,
                coordinates: features[0].geometry.coordinates,
              },
            });
          }
        })
        .on("click", (e) => {
          this.clearSpiderifiedCluster(map);
        })
        .on("zoomstart", (e) => {
          this.clearSpiderifiedCluster(map);
          document
            .querySelectorAll(".unclustered_profiles")
            .forEach((el) => el.remove());
        })
        .on("zoomend", (e) => {
          this.clearSpiderifiedCluster(map);
          this.rerenderProfiles(map);
        });

      map.addSource(SOURCE_EARTHQUAKE, {
        type: "geojson",
        data: this.prepareGeoJSON(mapData),
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      map.addLayer({
        id: "clusters",
        type: "circle",
        source: SOURCE_EARTHQUAKE,
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#3076FF",
            10,
            "#3076FF",
            50,
            "#3076FF",
          ],
          "circle-radius": ["step", ["get", "point_count"], 15, 10, 25, 50, 35],
        },
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: SOURCE_EARTHQUAKE,
        filter: ["has", "point_count"],
        layout: {
          "text-field": ["get", "point_count_abbreviated"],
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
          "text-size": 16,
        },
        paint: {
          "text-color": "#fff",
        },
      });

      map.addLayer({
        id: "unclustered-point",
        type: "circle",
        source: SOURCE_EARTHQUAKE,
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": "#11b4da",
          "circle-radius": 4,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      map.addControl(
        new mapboxgl.ScaleControl({
          maxWidth: 80,
          unit: "metric",
        })
      );

      map.on("data", (e) => {
        if (e.isSourceLoaded) {
          document
            .querySelectorAll(".custom-marker")
            .forEach((el) => el.remove());

          const features = map.querySourceFeatures("earthquakes", {
            filter: ["!", ["has", "point_count"]],
          });

          features.forEach((feature) => {
            const [lng, lat] = feature.geometry.coordinates;
            const el = this.prepareUnClusterPreview(
              feature.properties,
              "unclustered_profiles"
            );

            new mapboxgl.Marker(el)
              .setLngLat([lng, lat])
              .setPopup(
                new mapboxgl.Popup({ offset: 25 }).setHTML(
                  this.prepareProfilePopUpHTML(feature.properties)
                )
              )
              .addTo(map);
          });
        }
      });

      map.on(
        "mouseenter",
        "clusters",
        () => (map.getCanvas().style.cursor = "pointer")
      );

      map.on(
        "mouseleave",
        "clusters",
        () => (map.getCanvas().style.cursor = "")
      );
    });
  }

  rerenderProfiles(map) {
    const features = map.querySourceFeatures(SOURCE_EARTHQUAKE, {
      filter: ["!", ["has", "point_count"]],
    });

    features.forEach((feature) => {
      const [lng, lat] = feature.geometry.coordinates;
      const el = this.prepareUnClusterPreview(
        feature.properties,
        "unclustered_profiles"
      );

      new mapboxgl.Marker(el)
        .setLngLat([lng, lat])
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }).setHTML(
            this.prepareProfilePopUpHTML(feature.properties)
          )
        )
        .addTo(map);
    });
  }

  removeOpenedSpider() {
    const elements = document.getElementsByClassName("spiderfy-profile-circle");
    Array.from(elements).forEach((marker) => marker.remove());
  }

  clearSpierifiedMarkers() {
    if (clusterMarkers.length > 0) {
      for (let i = 0; i < clusterMarkers.length; i++) {
        clusterMarkers[i].remove();
      }
    }
    clusterMarkers = [];
  }

  removeSourceAndLayer(map, id) {
    if (map.getLayer(id) != null) map.removeLayer(id);
    if (map.getSource(id) != null) map.removeSource(id);
  }

  clearSpiderifiedCluster(map) {
    spiderifiedCluster = {};
    spiderLeavesCollection = [];
    this.removeSourceAndLayer(map, SPIDER_LEGS_LAYER_NAME);
    this.removeSourceAndLayer(map, SPIDER_LEAVES_LAYER_NAME);
    this.clearSpierifiedMarkers();
    this.removeOpenedSpider();
  }

  generateEquidistantPointsInCircle({
    totalPoints = 1,
    options = CIRCLE_OPTIONS,
  }) {
    let points = [];
    let theta = (Math.PI * 2) / totalPoints;
    let angle = theta;
    for (let i = 0; i < totalPoints; i++) {
      angle = theta * i;
      points.push({
        x: options.distanceBetweenPoints * Math.cos(angle),
        y: options.distanceBetweenPoints * Math.sin(angle),
      });
    }
    return points;
  }

  generateEquidistantPointsInSpiral({
    totalPoints = 10,
    options = SPIRAL_OPTIONS,
  }) {
    let points = [{ x: 0, y: 0 }];
    // Higher modifier = closer spiral lines
    const rotations = totalPoints * options.rotationsModifier;
    const distanceBetweenPoints = options.distanceBetweenPoints;
    const radius = totalPoints * options.radiusModifier;
    // Value of theta corresponding to end of last coil
    const thetaMax = rotations * 2 * Math.PI;
    // How far to step away from center for each side.
    const awayStep = radius / thetaMax;
    for (
      let theta = distanceBetweenPoints / awayStep;
      points.length <= totalPoints + options.lengthModifier;

    ) {
      points.push({
        x: Math.cos(theta) * (awayStep * theta),
        y: Math.sin(theta) * (awayStep * theta),
      });
      theta += distanceBetweenPoints / (awayStep * theta);
    }
    return points.slice(0, totalPoints);
  }

  generateLeavesCoordinates({ nbOfLeaves }) {
    // Position cluster's leaves in circle if below threshold, spiral otherwise
    let points;
    if (nbOfLeaves < CIRCLE_TO_SPIRAL_SWITCHOVER) {
      points = this.generateEquidistantPointsInCircle({
        totalPoints: nbOfLeaves,
      });
    } else {
      points = this.generateEquidistantPointsInSpiral({
        totalPoints: nbOfLeaves,
      });
    }
    return points;
  }

  spiderifyCluster({ map, source, clusterToSpiderify }) {
    let spiderlegsCollection = [];
    let spiderLeavesCollection = [];
    map
      .getSource(source)
      .getClusterLeaves(
        clusterToSpiderify.id,
        MAX_LEAVES_TO_SPIDERIFY,
        0,
        (error, features) => {
          if (error) {
            console.warning("Cluster does not exists on this zoom");
            return;
          }

          let leavesCoordinates = this.generateLeavesCoordinates({
            nbOfLeaves: features.length,
          });

          let clusterXY = map.project(clusterToSpiderify.coordinates);

          // Generate spiderlegs and leaves coordinates
          features.forEach((element, index) => {
            let spiderLeafLatLng = map.unproject([
              clusterXY.x + leavesCoordinates[index].x,
              clusterXY.y + leavesCoordinates[index].y,
            ]);

            if (SPIDER_TYPE.toLowerCase() === "marker") {
              clusterMarkers.push(
                new mapboxgl.Marker().setLngLat(spiderLeafLatLng)
              );
            }
            if (SPIDER_TYPE.toLowerCase() === "layer") {
              spiderLeavesCollection.push({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [spiderLeafLatLng.lng, spiderLeafLatLng.lat],
                },
              });

              const el = this.prepareUnClusterPreview(
                element.properties,
                "spiderfy-profile-circle"
              );

              new mapboxgl.Marker(el)
                .setLngLat(spiderLeafLatLng)
                .setPopup(
                  new mapboxgl.Popup({ offset: 25 }).setHTML(
                    this.prepareProfilePopUpHTML(element.properties)
                  )
                )
                .addTo(map);
            }

            if (SPIDER_LEGS) {
              spiderlegsCollection.push({
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    clusterToSpiderify.coordinates,
                    [spiderLeafLatLng.lng, spiderLeafLatLng.lat],
                  ],
                },
              });
            }
          });

          // Draw spiderlegs and leaves coordinates
          if (SPIDER_LEGS) {
            map.addLayer({
              id: SPIDER_LEGS_LAYER_NAME,
              type: "line",
              source: {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: spiderlegsCollection,
                },
              },
              paint: {
                "line-width": 3,
                "line-color": "rgba(128, 128, 128, 0.5)",
              },
            });
          }

          if (SPIDER_TYPE.toLowerCase() === "marker") {
            clusterMarkers.forEach((marker) => marker.addTo(map));
          }

          if (SPIDER_TYPE.toLowerCase() === "layer") {
            map.addLayer({
              id: SPIDER_LEAVES_LAYER_NAME,
              type: "circle",
              source: {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: spiderLeavesCollection,
                },
              },
              paint: {
                "circle-color": "orange",
                "circle-radius": 6,
                "circle-stroke-width": 1,
                "circle-stroke-color": "#fff",
              },
            });
          }
        }
      );
  }

  async getMapViewData(personIds) {
    try {
      const response = await fetch("/search/map_view_data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ person_ids: personIds }),
      });
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.error("Error fetching map view data:", error);
      return null;
    }
  }

  prepareGeoJSON(data) {
    return {
      type: "FeatureCollection",
      features: data.map((item) => ({
        type: "Feature",
        properties: item,
        geometry: {
          type: "Point",
          coordinates: [parseFloat(item.longitude), parseFloat(item.latitude)],
        },
      })),
    };
  }

  prepareUnClusterPreview(person, elementClassName) {
    const createMarkerElement = (imageUrl) => {
      const el = document.createElement("div");
      Object.assign(el.style, {
        width: "37px",
        height: "37px",
        backgroundSize: "cover",
        borderRadius: "50%",
        border: "4px solid #fff",
        outline: "2px solid #D1E1FF",
        backgroundImage: `url(${imageUrl})`,
      });
      el.className = elementClassName;
      return el;
    };

    const marker = createMarkerElement("/images/default_profile.svg");

    const img = new Image();
    img.src = person.avatar_url;
    (img.onload = () => {
      marker.dataset.name = person.avatar_url;
      marker.style.backgroundImage = `url(${person.avatar_url})`;
    }),
      (img.onerror = () => {
        marker.dataset.name = "/images/default_profile.svg";
      });
    marker.id = person.key;
    return marker;
  }

  prepareProfilePopUpHTML(person) {
    const person_name =
      person.name.length > 15 ? person.name.slice(0, 15) + "..." : person.name;

    const person_position =
      person.position.length > 25
        ? person.position.slice(0, 25) + "..."
        : person.position;

    const element = document.getElementById(person.key);
    let person_image = person.avatar_url;
    if (element) {
      person_image = element?.dataset?.name || person.avatar_url;
    } else {
      person_image = person.avatar_url;
    }

    return `<div class="list-none border profile_list border-border_color rounded-[10px] w-full min-w-[220px] col-span-1 bg-white transition-all duration-300 ease-in-out p-[10px] relative max-sm:p-[5px]">
      <div class="flex h-full justify-between flex-col gap-5 md:gap-0 max-sm:gap-[0]">
        <div class="flex flex-1 w-full flex-col gap-3 md:items-center max-sm:flex-row cursor-pointer">
          <figure class="w-full h-[120px] rounded-[8px] border-none relative flex-none max-sm:w-[200px] max-sm:max-w-[100px] max-sm:h-[120px] max-sm:flex-[auto]">
          <img src="${person_image}" class="w-full h-full rounded-[10px] object-cover" />
          </figure>
          <div class="flex flex-col flex-1 w-full">
            <div class="flex justify-between items-start gap-[10px]">
              <div>
                <div class="sm:flex sm:gap-[10px]">
                  <a title="${
                    person.name
                  }" class="text-[16px] font-medium leading-[24px] font-text_font text-[#051237C2]" href="${
      window.location.origin
    }/people/${person.key}" >${person_name}</a>
                </div>
                ${
                  person.position
                    ? `<p title="${person.position}" class="font-normal text-[12px] line-clamp-1 leading-4 not-italic tracking-[0.2px] font-text_font text-text_color"> ${person_position} </p>`
                    : ""
                }
              </div>
            </div>
          </div>
        </div>
        <div class="flex gap-[10px] items-center mt-3">
          <a class="db-secondary-button flex-1" href="${
            window.location.origin
          }/people/${person.key}"> View Details </a>
        </div>
      </div>
    </div>`;
  }
}
