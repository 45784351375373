import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="loader"
export default class extends Controller {
  connect() {}

  disconnect() {
    this.hideAllLoaders();
  }

  showLoader(event) {
    event.currentTarget.classList.add("pointer-events-none", "opacity-50");
    const loader = event.currentTarget.querySelector(".db-loader");

    if (loader) {
      loader.classList.remove("hidden!");
    }
  }

  hideAllLoaders() {
    const loaders = document.getElementsByClassName("db-loader");
    if (loaders.length == 0) return;

    loaders.forEach((loader) => {
      const el = loader.parentNode;
      if (el) {
        el.classList.remove("pointer-events-none", "opacity-50");
      }
      if (loader) {
        loader.classList.add("hidden!");
      }
    });
  }
}
