import { Controller } from "@hotwired/stimulus";
import { removeModal } from "../components/modal";

// Connects to data-controller="inferred-rate"
export default class extends Controller {
  static values = { personId: String };
  static targets = ["thinkingLoader", "inferredRateAIThinking", "creditType" ];
  connect() {
    this.messages = document.getElementById("inferred_rate_ai_thinking");
    this.observer = new MutationObserver(this.handleMutation.bind(this));
    this.observer.observe(this.messages, {
      childList: true,
      subtree: true,
      characterData: true,
    });
  }

  startThinking(event) {
    event.preventDefault();
    const target = event.currentTarget;
    const credit = parseInt(target.dataset.credit, 10);
    const balance = parseInt(target.dataset.balance, 10);
    if (balance < credit) {
      this.creditTypeTarget.value = "insufficient_balance";
    }
    else{
      target.classList.add("hidden!");
      if (this.hasThinkingLoaderTarget) {
        this.thinkingLoaderTarget.classList.remove("hidden");
      }
    }
    target.form.requestSubmit();
  }

  refreshInferredRate(event) {
    const target = event.currentTarget;
    const credit = parseInt(target.dataset.credit, 10);
    const balance = parseInt(target.dataset.balance, 10);
    if (this.hasThinkingLoaderTarget) {
      if (balance < credit) {
        this.creditTypeTarget.value = "insufficient_balance";
      }else{
        const inferredRateElement = document.getElementById(
          "inferred_rate_ai_thinking"
        );
        inferredRateElement.classList.remove("hidden!");
        inferredRateElement.innerHTML = "";

        this.thinkingLoaderTarget.classList.remove("hidden");
      }
      target.parentNode.requestSubmit();
    }
  }

  closeModal() {
    const allInferredModals = document.getElementsByClassName(
      "inferred_rate_modal"
    );
    allInferredModals.forEach((item) => {
      if (item.id) removeModal(item.id);
    });
  }

  chargeCredit(event) {
    event.preventDefault();
    event.currentTarget.form.requestSubmit();
    const inferredRateClaim = document.getElementById("inferred-rate-claim");
    if (inferredRateClaim) {
      inferredRateClaim.classList.add("hidden!");
    }
    this.closeModal();
    if (this.hasThinkingLoaderTarget) {
      this.thinkingLoaderTarget.classList.remove("hidden");
    }
  }

  toggleThinking() {
    const toggleElement = this.hasInferredRateAIThinkingTarget
      ? this.inferredRateAIThinkingTarget
      : document.getElementById("inferred_rate_ai_thinking");

    toggleElement.classList.toggle("hidden!");
  }

  handleMutation(mutationsList) {
    mutationsList.forEach((mutation) => {
      const text = this.messages.innerText;
      if (text.length > 800) {
        this.messages.scrollTo({
          top: this.messages.scrollHeight,
          behavior: "smooth",
        });
      }
    });
  }
}
