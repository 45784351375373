import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "userCount",
    "teamPrice",
    "perUserPrice",
    "teamUserCount",
    "buyNowButton",
    "updateBtnForm",
    "planChangeDiv",
    "formButton",
  ];

  connect() {
    const currentValue = Number(this.userCountTarget.innerText);
    this.isUpdateSubscription = document.getElementById(
      "updateSubscriptionTarget"
    );
    this.updatePriceDisplay(currentValue);
  }

  increment(e) {
    e.preventDefault();
    let currentValue = Number(this.userCountTarget.innerText);
    currentValue += 1;
    this.userCountTarget.innerText = currentValue;

    if (this.isUpdateSubscription) {
      this.updateUserLimitInForm(currentValue);
      this.toggleFormButtonVisibility();
    }

    this.updatePriceDisplay(currentValue);
  }

  decrement(e) {
    e.preventDefault();
    let currentValue = Number(this.userCountTarget.innerText);
    currentValue = Math.max(currentValue - 1, 5);
    this.userCountTarget.innerText = currentValue;

    if (this.isUpdateSubscription) {
      this.updateUserLimitInForm(currentValue);
      this.toggleFormButtonVisibility(false);
    }

    this.updatePriceDisplay(currentValue);
  }

  updatePriceDisplay(userCount) {
    this.teamUserCountTarget.value = userCount;
    this.teamPriceTarget.innerText =
      Number(this.perUserPriceTarget.innerText) * userCount;
    if (!this.isUpdateSubscription) {
      this.updateBuyNowHref(userCount);
    }
  }

  updateBuyNowHref(userCount) {
    const plan = this.buyNowButtonTarget.dataset.planid;
    const newHref = `/subscriptions/new?plan=${plan}&user_count=${userCount}`;
    this.buyNowButtonTarget.setAttribute("href", newHref);
  }

  updateUserLimitInForm(userCount) {
    const userLimitInput = this.updateBtnFormTarget.querySelector(
      "input[name='user_limit']"
    );
    if (userLimitInput) {
      userLimitInput.value = userCount;
    }
  }

  toggleFormButtonVisibility() {
    const currentPlan = document.querySelector("#currentPlanDivTarget");
    const formButton = document.querySelector("#changePlanDivTarget");

    if (currentPlan && formButton) {
      currentPlan.style.display = "none";
      formButton.style.display = "inline-flex";
    }
  }
}
