import { Controller } from "@hotwired/stimulus";
import { closeModal } from "../components/modal";

export default class extends Controller {
  connect() {
    this.timeout = null;
    document.addEventListener("click", function (event) {
      if (event.target.closest(".db-dropdown-accor")) {
        return;
      }

      document.querySelectorAll("[data-dropdown-target]").forEach((menu) => {
        if (
          !menu.contains(event.target) &&
          !menu.classList.contains("hidden")
        ) {
          menu.classList.add("hidden");
        }
      });

      if (event.target.id == "close_delete_modal") {
        ["removeUserForm", "removeInvitationForm"].forEach((el) => {
          const form = document?.getElementById(el);
          if (!form) return;
          form.querySelector('input[name="id"]').value = "";
        });
        closeModal("record-delete-modal");
      }

      if (event.target.id == "__delete_record_button") {
        ["removeUserForm", "removeInvitationForm"].forEach((el) => {
          const form = document.getElementById(el);
          const inputHasID = form.querySelector('input[name="id"]').value;
          if (!form || inputHasID == "") return;
          form.requestSubmit();
        });
        closeModal("record-delete-modal");
      }
    });
  }

  searchTeam(event) {
    const form = event.currentTarget.form;
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      form.requestSubmit();
    }, 1000);
  }
}
