import { Controller } from "@hotwired/stimulus";
import { closeModal, closeTurboModal, openModal } from "../components/modal";

export default class extends Controller {
  static targets = ["removeInvitationForm", "removeUserForm"];
  connect() {}

  openDropdown(event) {
    event.preventDefault();

    const button = event.currentTarget;
    const menuId = button.getAttribute("data-dropdown-toggle");
    const menu = document.getElementById(menuId);
    if (!menu) return;

    const parentDivId = "teams-dropdown-list";
    let parentDiv = document.getElementById(parentDivId);

    if (!parentDiv) {
      parentDiv = document.createElement("div");
      parentDiv.id = parentDivId;
      parentDiv.className = "teams-dropdown-list";
      parentDiv.setAttribute("data-controller", "team-management-dropdown");
      document.body.appendChild(parentDiv);
    }

    if (!parentDiv.contains(menu)) {
      parentDiv.appendChild(menu);
    }

    const isMenuOpen = !menu.classList.contains("hidden");

    document.querySelectorAll("[data-dropdown-target]").forEach((m) => {
      if (m !== menu) {
        m.classList.add("hidden");
      }
    });

    if (isMenuOpen) {
      menu.classList.add("hidden");
    } else {
      menu.classList.remove("hidden");
      this.updateDropdownPosition(button, menu);
    }
  }

  updateDropdownPosition(button, dropdownMenu) {
    const buttonRect = button.getBoundingClientRect();
    const menuRect = dropdownMenu.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    let top = buttonRect.bottom + window.scrollY + 10;
    let left = buttonRect.left + window.scrollX - 10;

    if (menuRect.width > viewportWidth - buttonRect.left) {
      left = viewportWidth - menuRect.width - 10;
    }
    if (menuRect.height > viewportHeight - buttonRect.bottom) {
      top = buttonRect.top + window.scrollY - menuRect.height - 10;
    }
    if (top < 0) top = 10;
    if (left < 0) left = 10;

    dropdownMenu.style.top = `${top}px`;
    dropdownMenu.style.left = `${left - 60}px`;
    dropdownMenu.style.position = "absolute";
    dropdownMenu.style.zIndex = 2;
  }

  closeModal() {
    closeTurboModal("team-member-modal");
  }

  removeUserModal(event) {
    const accountID = event.currentTarget.dataset.id;
    this.handleTeamDelete(accountID, "removeUserForm");
  }

  removeInvitationModal(event) {
    const accountID = event.currentTarget.dataset.id;
    this.handleTeamDelete(accountID, "removeInvitationForm");
  }

  handleTeamDelete(id, form_id) {
    const form = document.getElementById(form_id);
    if (!form) return;
    form.querySelector('input[name="id"]').value = id;
    openModal("record-delete-modal");
  }
}
