import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { closeTurboModal, hideElement } from "../../components/modal";
import { toast } from "../../components/snackbar";
import { toggleClearOption } from "../../components/filters";
import { checkedAndUnCheckedAllCheckboxes } from "../../components/validation";

let should_close_drawer = false;
export default class extends Controller {
  static values = {
    locationRadius: Object,
  };

  static targets = [
    "tab",
    "tabs",
    "searchInput",
    "searchCategoryForm",
    "filtersForm",
    "applyFiltersButton",
    "toggleDiv",
    "closeChat",
    "chatDrawer",
    "chatSideDrawer",
    "vectorSearchInput",
    "manageAssignTagDiv",
    "filterBtn",
    "disableSearchDiv",
    "editTitleIcon",
  ];

  connect() {
    this.shouldSubmitByEnter = false;
    this.isSubmitting = false;
    this.timeout = null;
    jQuery("#ai_search_dropdown_filter").off("change");
    jQuery("#ai_search_dropdown_filter")
      .select2()
      .on("change", (e) => {
        e.preventDefault();
        e.currentTarget?.form?.requestSubmit();
      });

    jQuery("#ai_search_per_page_filter").off("change");
    jQuery("#ai_search_per_page_filter")
      .select2()
      .on("change", (e) => {
        e.preventDefault();
        e.currentTarget?.form?.requestSubmit();
      });

    document.addEventListener("click", (event) => {
      const asideDrawer = document.querySelector(".filter-drawer");
      const drawerContent = document.querySelector(".filter-drawer > div");
      if (
        asideDrawer &&
        !asideDrawer.classList.contains("hidden") &&
        !drawerContent.contains(event.target) &&
        !event.target.matches(".opendrawer, .opendrawer *")
      ) {
        this.closeFilter();
      }

      if (event.target.closest(".close_portal_modal")) {
        closeTurboModal("filter_generated_modal");
      }
    });

    document.addEventListener("DOMContentLoaded", () => {
      this.messages = document.getElementById("ai_search_query_thinking");
      if (this.messages) {
        this.observer = new MutationObserver(this.handleMutation.bind(this));
        this.observer.observe(this.messages, {
          childList: true,
          subtree: true,
          characterData: true,
        });
      }
    });
  }

  submitAndActiveFilter(event) {
    const form = event.target.form;
    if (form) {
      form.requestSubmit();
    }
    const selector = event.target.dataset.selector;
    if (event.target.checked) {
      $(`#${event.target.dataset.activeKey}`).addClass("has-dot");
      toggleClearOption(event.currentTarget?.dataset?.filterKey, true);
    } else {
      const totalChecked = this.getCheckedCount(selector);
      if (totalChecked == 0) {
        $(`#${event.target.dataset.activeKey}`).removeClass("has-dot");
        toggleClearOption(event.currentTarget?.dataset?.filterKey, false);
      }
    }
  }

  getCheckedCount(className) {
    const checkboxes = document.getElementsByClassName(className);
    let checkedCount = 0;

    Array.from(checkboxes).forEach((checkbox) => {
      if (checkbox.checked) {
        checkedCount++;
      }
    });

    return checkedCount;
  }

  activateTab(event) {
    const clickedTab = event.currentTarget;
    this.tabTargets.forEach((tab) => {
      tab.classList.remove("active");
    });
    clickedTab.classList.add("active");
    const current_view = event.currentTarget.dataset.view;
    this.appendStateInURL(current_view);

    const form_id = event.currentTarget.dataset.formId;
    const form = document.getElementById(form_id);
    if (form) {
      form.querySelector('input[name="view"]').value = current_view;
      form.submit();
    }
  }

  appendStateInURL(state) {
    let currentUrl = new URL(window.location);
    currentUrl.searchParams.set("view", state);
    window.history.pushState({}, "", currentUrl);
  }

  activateTabs(event) {
    const clickedTab = event.currentTarget;
    this.tabsTargets.forEach((tab) => {
      tab.classList.remove("border-b-2");
      tab.classList.remove("border-[#3076FF]");
    });
    clickedTab.classList.add("border-b-2");
    clickedTab.classList.add("border-[#3076FF]");
  }

  debounceSearch(event) {
    const value = this.searchInputTarget.value;
    const search_button = document.getElementById("search-button");

    if (event.key === "Enter") {
      event.preventDefault();

      if (search_button.disabled) {
        return false;
      }

      if (this.shouldSubmitByEnter) {
        this.disbableSearch(event.currentTarget.form, value);
        return;
      }
    }

    this.isSubmitting = true;

    clearTimeout(this.timeout);

    search_button.disabled = true;
    if (value.trim() == "") {
      search_button.disabled = false;
      const categories = document.getElementsByClassName("category-list");
      categories.forEach((category) => {
        const name = category.innerText;
        category.classList.remove("bg-[#EEFFF0]");
        category.classList.add("bg-[#0512370A]");
        category.innerHTML = "";
        category.innerText = name;
      });
      // this.showSearchLoading(false);
      return false;
    }
    // this.showSearchLoading(true);
    this.timeout = setTimeout(() => {
      this.searchCategories(value);
    }, 1000);
  }

  showSearchLoading(flag) {
    const loader = document.getElementById("search_filter_loader");
    if (flag) {
      loader && loader.classList.remove("hidden");
    } else {
      loader && loader.classList.add("hidden");
    }
  }

  handleMutation(mutationsList) {
    if (!navigator.onLine) {
      hideElement("search_filter_loader");
      toast(
        "You are currently offline. Please check your internet connection.",
        "error"
      );
      return;
    }
    if (this.messages) {
      mutationsList.forEach((mutation) => {
        const text = this.messages.innerText;
        if (text.length > 800) {
          this.messages.scrollTo({
            top: this.messages.scrollHeight,
            behavior: "smooth",
          });
        }
      });
    }
  }

  debounceVectorSearch(event) {
    const value = this.vectorSearchInputTarget.value;

    if (event.key === "Enter") {
      event.preventDefault();
      clearTimeout(this.timeout);
      return false;
    }

    if (!this.vectorSearchInputTarget) return;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.searchByVector(value);
    }, 1000);
  }

  searchByVector(query) {
    const form = document.getElementById("vector_search_form");

    form.addEventListener(
      "turbo:submit-start",
      () => {
        this.skeletonLoading(true);
      },
      { once: true }
    );

    form.requestSubmit();
  }

  searchCategories(query) {
    if (!this.hasSearchCategoryFormTarget) return;
    const search_categories_query = document.getElementById(
      "search_categories_query"
    );
    const search_button = document.getElementById("search-button");
    if (search_categories_query) {
      search_categories_query.value = query;
    }
    this.isSubmitting = true;
    const form = this.searchCategoryFormTarget;
    form.addEventListener(
      "turbo:submit-start",
      () => {
        if (search_button) {
          search_button.disabled = true;
        }
        this.shouldSubmitByEnter = false;
      },
      { once: true }
    );

    form.addEventListener(
      "turbo:submit-end",
      () => {
        if (search_button) {
          search_button.disabled = false;
        }
        this.showSearchLoading(false);
        this.shouldSubmitByEnter = true;
        this.isSubmitting = false;
        clearTimeout(this.timeout);
      },
      { once: true }
    );
    form.requestSubmit();
  }

  search(event) {
    if (this.searchInputTarget.value != "") {
      this.disbableSearch(
        event.currentTarget.form,
        this.searchInputTarget.value
      );
    }
  }

  disbableSearch(form, inputValue) {
    this.showSearchLoading(true);
    if (this.hasFilterBtnTarget) {
      this.filterBtnTarget.classList.add("db-disablebar-btn");
      this.filterBtnTarget.disabled = true;
    }
    if (this.hasDisableSearchDivTarget) {
      this.disableSearchDivTarget.classList.add("db-disablebar");
    }

    if (inputValue != "") {
      form.addEventListener("turbo:submit-end", () => {
        document.addEventListener("turbo:before-stream-render", (event) => {
          if (this.hasFilterBtnTarget) {
            this.filterBtnTarget.classList.remove("db-disablebar-btn");
            this.filterBtnTarget.disabled = false;
          }
          if (this.hasDisableSearchDivTarget) {
            this.disableSearchDivTarget.classList.remove("db-disablebar");
          }
          if (window.location.href.includes("companies")) {
            should_close_drawer = false;
            this.openCompanyDrawer();
          } else {
            this.openFilter();
          }
        });
      });
      form.requestSubmit();
    }
  }

  openCompanyDrawer() {
    // var body = document.body;
    // body.classList.add("overflow-hidden");

    const asideDrawer = document.getElementById("company-filter-drawer");
    const asideDrawerChildren = document.getElementById(
      "aside-drawer-children"
    );

    if (!asideDrawer || !asideDrawerChildren) return;

    asideDrawer.classList.remove("hidden");
    asideDrawer.classList.add("flex", "opacity-0");

    asideDrawer.classList.remove("opacity-0");
    asideDrawerChildren.classList.add("translate-x-0!");
  }

  openFilter() {
    const asideDrawer = document.querySelector(".filter-drawer");
    var body = document.body;
    body.classList.add("overflow-hidden");
    asideDrawer.classList.add("visible", "opacity-100");

    const firstChildDiv = asideDrawer.firstElementChild;
    if (!firstChildDiv) {
      return;
    }

    firstChildDiv.classList.add("right-0!");

    if (asideDrawer) {
      if (!asideDrawer.firstElementChild.classList.contains("max-w-[500px]")) {
        const button_wrap = document.getElementsByClassName("button-wrap");
        for (let wrap of button_wrap) {
          wrap.removeAttribute("style");
        }

        const filterSort = document.getElementsByClassName("filter-sort");
        for (let sort of filterSort) {
          sort.removeAttribute("style");
        }

        const filterItems = document.getElementsByClassName("filter-items");
        for (let item of filterItems) {
          item.removeAttribute("style");
        }

        const openFilters = document.querySelector(".openfilters");
        if (openFilters) {
          openFilters.innerHTML = `Edit Filters <i class="anron-edit-3 text-[14px]"></i>`;
        }
      }
    }
    should_close_drawer = false;
  }

  showVideoask(event) {
    event.preventDefault();
  }

  closeFilter() {
    const asideDrawer = document.querySelector(".filter-drawer");
    const firstChildDiv = asideDrawer.firstElementChild;
    if (!firstChildDiv) {
      return;
    }

    var body = document.body;
    asideDrawer.classList.remove("visible", "opacity-100");
    firstChildDiv.classList.remove("right-0!");
    body.classList.remove("overflow-hidden");
  }

  openAllFilters() {
    const asideDrawer = document.querySelector(".filter-drawer");
    if (asideDrawer) {
      [...asideDrawer.children].forEach((child) =>
        child.classList.toggle("max-w-[500px]")
      );
    }

    const filterItems = document.getElementsByClassName("filter-items");
    for (let item of filterItems) {
      item.style.display = item.style.display === "none" ? "flex" : "none";
    }

    const filterSort = document.getElementsByClassName("filter-sort");
    for (let sort of filterSort) {
      sort.style.display = sort.style.display === "flex" ? "none" : "flex";
    }

    const buttonWrap = document.getElementsByClassName("button-wrap");
    for (let wrap of buttonWrap) {
      wrap.style.display = wrap.style.display === "none" ? "flex" : "none";
    }

    const openFilters = document.querySelector(".openfilters");
    if (openFilters.innerHTML.includes("Edit Filters")) {
      openFilters.innerHTML = `Save Filters <i class="anron-check text-[14px]"></i>`;
    } else {
      openFilters.innerHTML = `Edit Filters <i class="anron-edit-3 text-[14px]"></i>`;
      document.getElementById("filters-form").requestSubmit();
    }

    const sortable_left = document.getElementById("example2-left");
    const sortable_right = document.getElementById("example2-right");
    this.initSortable(sortable_left);
    this.initSortable(sortable_right);
  }

  initSortable(element) {
    Sortable.create(element, {
      group: "shared",
      animation: 30,
      onStart: function (evt) {
        evt.item.classList.add("bg-white", "shadow-xs");
      },
      onEnd: function (evt) {
        evt.item.classList.remove("bg-white", "shadow-xs");

        const targetElement =
          evt?.item?.lastElementChild?.previousElementSibling;

        if (evt.to.id === "example2-left") {
          if (targetElement) {
            targetElement.value = true;
          }
        }

        if (evt.to.id === "example2-right") {
          if (targetElement) {
            targetElement.value = false;
          }
        }
      },
    });
  }

  disconnect() {
    const destroySelect2 = (selector) => {
      const element = $(this.element).find(selector);
      if (element.length && $.fn.select2 && element.data("select2")) {
        element.select2("destroy");
      }
    };

    destroySelect2("#ai_search_dropdown_filter");
    destroySelect2("#ai_search_per_page_filter");
  }

  applyFilters(event) {
    event.preventDefault();
    const show_results_count = document.getElementById("show_results_count");
    if (show_results_count) {
      show_results_count.value = false;
      should_close_drawer = true;
    }

    const should_update_filters = document.getElementById(
      "should_update_filters"
    );
    if (should_update_filters) {
      should_update_filters.value = true;
    }
    this.applyFiltersButtonTarget.disabled = true;
    this.applyFiltersButtonTarget.lastElementChild.classList.remove("hidden");
    const form = this.filtersFormTarget;

    const filters_query_search = form.querySelector("input[name='q']");
    const search_query = this.hasSearchInputTarget
      ? this.searchInputTarget.value
      : null;
    if (
      (search_query != "" || search_query != undefined) &&
      search_query != null
    ) {
      filters_query_search.value = search_query;
    }

    form.addEventListener("turbo:submit-end", () => {
      if (should_close_drawer) {
        document.addEventListener("turbo:before-stream-render", (event) => {
          this.applyFiltersButtonTarget.disabled = false;
          this.applyFiltersButtonTarget.lastElementChild.classList.add(
            "hidden"
          );
          this.closeFilter();
          filters_query_search.value = "";
          should_close_drawer = false;
        });
      }
    });
    form.requestSubmit();
  }

  show() {
    if (this.toggleDivTarget.classList.contains("hidden")) {
      this.toggleDivTarget.classList.remove("hidden");

      if (this.hasManageAssignTagDivTarget) {
        this.manageAssignTagDivTarget.classList.remove("hidden");
      }

      if (this.hasFilterBtnTarget) {
        this.filterBtnTarget.classList.remove("db-disablebar-btn");
        this.filterBtnTarget.disabled = false;
      }

      if (this.hasDisableSearchDivTarget) {
        this.disableSearchDivTarget.classList.remove("db-disablebar");
      }

      if (this.hasEditTitleIconTarget) {
        this.editTitleIconTarget.classList.remove("hidden");
      }
    }
  }

  hide() {
    if (!this.toggleDivTarget.classList.contains("hidden")) {
      this.toggleDivTarget.classList.add("hidden");

      if (this.hasManageAssignTagDivTarget) {
        this.manageAssignTagDivTarget.classList.add("hidden");
      }

      if (this.hasFilterBtnTarget) {
        this.filterBtnTarget.classList.add("db-disablebar-btn");
        this.filterBtnTarget.disabled = false;
      }

      if (this.hasDisableSearchDivTarget) {
        this.disableSearchDivTarget.classList.add("db-disablebar");
      }

      if (this.hasEditTitleIconTarget) {
        this.editTitleIconTarget.classList.add("hidden");
      }
    }
  }

  toggleHistory(event) {
    event.preventDefault();
    const isSmallScreen = window.matchMedia("(max-width: 1024px)").matches;

    if (isSmallScreen) {
      this.chatSideDrawerTarget.classList.toggle("slide-out");
    }
  }

  closeHistory(event) {
    event.preventDefault();
    if (this.chatSideDrawerTarget.classList.contains("slide-out")) {
      this.chatSideDrawerTarget.classList.remove("slide-out");
    }
  }

  toggleSelectProfiles(event) {
    if (event.target?.dataset?.view == "list") {
      return false;
    }
    const customDropDown = document.getElementById("custom-dropdown");
    const profileCheckBoxes = document.querySelectorAll(".profile_checkbox");
    const itemsValue = customDropDown ? customDropDown.dataset.items : null;
    if (itemsValue !== "all") {
      profileCheckBoxes.forEach((checkbox) =>
        checkbox.classList.remove("hidden")
      );
    }
  }

  checkedAllListView(e) {
    checkedAndUnCheckedAllCheckboxes("profile_checkbox", e.target.checked);
  }

  skeletonLoading(loading) {
    const listing = window.location.pathname.includes("search")
      ? document.getElementById("personListing")
      : document.getElementById("companyListing");
    if (!listing) {
      return;
    }

    const skeletonContent = this.getSkeleton();
    if (loading) {
      listing.innerHTML = "";
      listing.innerHTML = skeletonContent.repeat(8);
    } else {
      const allSkeleton = document.querySelectorAll(".skeleton");
      Array.from(allSkeleton).forEach((skeleton) => {
        skeleton.remove();
      });
    }
  }

  getSkeleton() {
    const search_grid_skeleton = `<li class="skeleton list-none border border-border_color rounded-[10px] col-span-1 bg-white [&:hover_.secondary-button]:bg-[#3076FF] transition-all duration-300 ease-in-out p-[14px] relative [&:hover]:shadow-shadow_fifteen">
        <div class="flex h-full justify-between flex-col gap-5 md:gap-0 max-sm:gap-[0]">
          <div class="flex flex-1 w-full flex-col gap-3 md:items-center max-sm:flex-row">
            <figure class="w-full h-[160px] flex-none! shimmer block rounded-[8px] border-none relative flex-none max-sm:w-[100px] max-sm:max-w-[100px] max-sm:h-[136px] max-sm:flex-[auto]"></figure>
            <div class="flex flex-col flex-1 w-full">
              <div class="sm:flex sm:gap-[10px]">
                <a class="w-[105px] h-[24px] rounded-[4px] shimmer block" data-turbo-frame="_top" href="/people/simon-fonseca-12417b117"></a>
              </div>
              <p class="w-[135px] h-[16px] rounded-[6px] shimmer block mt-1"></p>
              <ul class="mt-4 p-0 flex flex-col justify-start gap-2">
                <li class="list-none flex gap-[6px] max-sm:line-clamp-1">
                  <span class="h-5 w-[137px] rounded-[6px] shimmer block"></span>
                </li>
                <ul class="flex gap-[10px] flex-wrap order-[-1] mb-[8px]">
                  <li class="list-none flex gap-[6px] items-center">
                    <span class="h-5 w-[18px] rounded-[6px] shimmer block"></span>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          <div class="flex gap-[10px] items-center mt-3">
            <span class="h-9 w-[100%] rounded-[6px] shimmer block"></span>
            <span class="h-9 w-9 rounded-[6px] flex-none shimmer block"></span>
          </div>
        </div>
      </li>`;

    const search_list_skeleton = `<li class="skeleton list-none border border-border_color rounded-[10px] col-span-1 bg-white [&:hover_.secondary-button]:bg-[#3076FF] transition-all duration-300 ease-in-out p-[14px_16px] relative [&:hover]:shadow-shadow_fifteen">
          <div class="flex md:justify-between gap-5 md:gap-0 max-md:gap-3 max-md:flex-col">
              <div class="flex flex-1 w-full gap-4 md:items-center">
                  <figure class="w-[70px] h-[70px] rounded-[8px] shimmer flex-none block max-sm:w-[100px] max-sm:max-w-[100px] max-sm:h-[136px] max-sm:flex-[auto]"></figure>
                  <div class="flex flex-col flex-1">
                      <div class="sm:flex sm:gap-[10px]">
                          <span class="shimmer flex-none block w-[105px] h-[20px] rounded-[6px]"></span>
                      </div>
                      <span class="shimmer flex-none block w-[85px] mt-1 h-[16px] rounded-[6px]"></span>
                      <div class="flex md:flex-row flex-col-reverse gap-[10px] md:gap-0">
                          <ul class="mt-1 md:p-0 p-[10px_0] flex flex-col justify-start md:flex-row md:gap-4 gap-2 md:justify-center md:items-center max-sm:px-0">
                              <li class="list-none flex gap-[6px]">
                                  <span class="shimmer flex-none block w-[110px] mt-1 h-[16px] rounded-[6px]"></span>
                              </li>
                              <li class="list-none flex gap-[6px]">
                                  <span class="shimmer flex-none block w-[150px] mt-1 h-[16px] rounded-[6px]"></span>
                              </li>
                              <li class="list-none flex gap-[6px] items-center">
                                  <span class="h-5 w-[18px] shimmer flex-none block rounded-[6px]"></span>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <div class="flex gap-[10px] mt-0">
                  <span class="shimmer flex-none h-[36px] w-[100%] rounded-[6px] shimmer flex-none md:min-w-[108px] min-w-full max-w-full md:max-w-[108px] block"></span>
                  <span class="flex-none shimmer flex items-center justify-center h-9 w-9 rounded-[6px] absolute right-[10px] top-[12px] sm:right-[20px] md:relative md:top-auto md:right-auto"></span>
              </div>
          </div>
      </li>`;

    const company_grid_skeleton = `<li class="skeleton list-none border border-border_color rounded-[10px] col-span-1 bg-white [&:hover_.secondary-button]:bg-[#3076FF] transition-all duration-300 ease-in-out p-[14px] relative [&:hover]:shadow-shadow_fifteen">
        <div class="flex justify-between flex-col gap-5 relative h-full max-sm:p-[5px] max-sm:gap-[12px]">
          <div class="flex justify-between gap-[21px] flex-col max-sm:flex-row max-sm:gap-[12px]">
            <div class="flex">
              <figure class="w-[63px] h-[63px] rounded-[8px] sm:rounded-full shimmer flex-none block max-sm:w-[100px] max-sm:max-w-[100px] max-sm:h-[136px] max-sm:flex-[auto]"></figure>
            </div>
            <div class="flex flex-col flex-1 w-full">
              <div class="flex gap-[13px]">
                <span class="h-[16px] w-[133px] rounded-[6px] flex-none shimmer block"></span>
              </div>
              <div class="mt-[10px] flex gap-[8px] flex-col">
                <div class="flex gap-[7px] max-sm:line-clamp-1">
                  <span class="h-[20px] w-[123px] rounded-[6px] flex-none shimmer block"></span>
                </div>
                <div class="flex gap-[7px] max-sm:line-clamp-1">
                  <span class="h-[16px] w-[100px] rounded-[6px] flex-none shimmer block"></span>
                </div>
                <div class="flex gap-[7px] max-sm:line-clamp-1">
                  <span class="h-[20px] w-[165px] rounded-[6px] flex-none shimmer block"></span>
                </div>
              </div>
            </div>
          </div>
          <span class="h-[36px] w-[100%] rounded-[6px] shimmer block"></span>
        </div>
      </li>`;

    const company_list_skeleton = `<li class="skeleton list-none border border-border_color rounded-[10px] col-span-1 bg-white [&:hover_.secondary-button]:bg-[#3076FF] transition-all duration-300 ease-in-out p-[14px_16px] relative [&:hover]:shadow-shadow_fifteen">
        <div class="flex md:justify-between flex-row max-md:flex-col gap-5 max-sm:gap-[12px]">
            <div class="flex flex-1 w-full gap-4 md:items-center">
                <figure class="w-[63px] h-[63px] rounded-[8px] md:rounded-full shimmer flex-none block max-sm:w-[100px] max-sm:max-w-[100px] max-sm:h-[136px] max-sm:flex-[auto]"></figure>
                <div class="flex flex-col flex-1">
                    <div class="sm:flex sm:gap-[10px]">
                        <span class="shimmer flex-none block w-[133px] h-[16px] rounded-[6px]"></span>
                    </div>
                    <div class="flex md:flex-row flex-col-reverse gap-[10px] md:gap-0">
                        <ul class="mt-3 md:p-0 p-[10px] flex flex-col justify-start md:flex-row md:gap-4 gap-2 md:justify-center md:items-center max-sm:p-0">
                            <li class="list-none flex gap-[6px]">
                            <span class="shimmer flex-none block w-[143px] h-[20px] rounded-[6px]"></span>
                            </li>
                            <li class="list-none flex gap-[6px]">
                                <span class="shimmer flex-none block w-[103px] h-[20px] rounded-[6px]"></span>
                            </li>
                            <li class="list-none flex gap-[6px]">
                                <span class="shimmer flex-none block w-[163px] h-[20px] rounded-[6px]"></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <span class="shimmer flex-none h-[36px] w-[100%] rounded-[6px] shimmer flex-none md:min-w-[108px] min-w-full max-w-full md:max-w-[108px] block"></span>
        </div>
    </li>`;

    const view_type = document.getElementById("view");

    if (window.location.pathname.includes("search")) {
      return view_type.value == "list_view"
        ? search_list_skeleton
        : search_grid_skeleton;
    } else {
      return view_type.value == "list_view"
        ? company_list_skeleton
        : company_grid_skeleton;
    }
  }
}
