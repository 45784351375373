import { Controller } from "@hotwired/stimulus";
import { initAirDatePicker } from "../components/datepicker";

// Connects to data-controller="credit-transactions"
export default class extends Controller {
  connect() {
    initAirDatePicker("dateFilter", this.handleDateSelect);
  }

  handleDateSelect(response) {
    const form = document.getElementById("creditManagementForm");
    if (form) {
      form.requestSubmit();
    }
  }
}
