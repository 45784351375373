import { Controller } from "@hotwired/stimulus";
import noUiSlider from "nouislider";

export default class extends Controller {
  static targets = [
    "employeeRangeSlider",
    "creditSum",
    "sumresults",
    "results",
    "sumresultsUI",
    "creditPrice",
  ];

  connect() {
    this.initRadiusSlider();
  }

  initRadiusSlider() {
    if (!this.hasEmployeeRangeSliderTarget) return;
    if (
      this.employeeRangeSliderTarget &&
      !this.employeeRangeSliderTarget.noUiSlider
    ) {
      const labels = {
        0: "100",
        1000: "•",
        5000: "•",
        10000: "•",
        15000: "•",
        20000: "•",
        25000: "•",
        30000: "•",
        35000: "•",
        40000: "•",
        45000: "•",
        50000: "50k",
      };

      noUiSlider.create(this.employeeRangeSliderTarget, {
        start: 100,
        connect: [true, false],
        tooltips: {
          to: (value) => {
            return value >= 1000
              ? Math.round(value / 1000) + "k"
              : Math.round(value / 100) * 100;
          },
        },
        step: 100,
        range: {
          min: 100,
          "10%": [1000, 1000],
          max: 50000,
        },
        pips: {
          mode: "values",
          values: Object.keys(labels).map(Number),
          density: 4,
          format: {
            to: (value) => labels[value] || "",
          },
        },
      });

      this.employeeRangeSliderTarget.noUiSlider.on("update", (values) => {
        const tooltipValue = parseFloat(values[0]);
        this.resultsTarget.innerText = tooltipValue;
        const totalSum = Number(
          tooltipValue * (Number(this.creditPriceTarget.value) / 100)
        );

        this.sumresultsTarget.value = this.sumresultsUITarget.innerText = totalSum;
      });
    }
  }
}
