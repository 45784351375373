import { Controller } from "@hotwired/stimulus";
import { openModal, closeModal } from "../../components/modal";
import { toast } from "../../components/snackbar";
import { getCheckedValues } from "../../components/validation";
import { toggleDropdown } from "../../components/dropdown";
import { closeOnOutsideClick } from "../../components/modal";

let single_profile_id = null;
export default class extends Controller {
  static targets = [
    "statusButton",
    "statusMenu",
    "phoneButton",
    "phoneMenu",
    "emailButton",
    "emailMenu",
    "form",
    "input",
    "addToLeadForm",
    "inviteLinkInput",
    "iframeText",
  ];

  connect() {
    this.boundHandleClickOutside = this.handleClickOutside.bind(this);
    this.boundCloseDropdown = this.closeDropdown.bind(this);

    document.addEventListener("click", this.boundHandleClickOutside);
    document.addEventListener("closeDropdowns", this.boundCloseDropdown);

    document.addEventListener("click", function (event) {
      const button = document.getElementById("open_leads_dropdown");
      const enrichmentDropdown = document.getElementById("lead_all_filters");
      closeOnOutsideClick(button, enrichmentDropdown, event.target);
    });
  }

  disconnect() {
    document.removeEventListener("click", this.boundHandleClickOutside);
    document.removeEventListener("closeDropdowns", this.boundCloseDropdown);
  }

  closeDropdown() {
    const items = this.element.querySelectorAll(".lead-dropdown");
    if (items) {
      items.forEach((item) => {
        item.classList.add("hidden");
      });
    }
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.closeDropdown();
    }
  }

  enrichContact(event) {
    const data = event.currentTarget.dataset;
    if (data && data.contact === "true") {
      event.preventDefault();
    }
  }

  addCustomEmail(event) {
    clearTimeout(this.timeout);

    let currentEvent = event.currentTarget;
    const value = currentEvent.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value.trim() == "") {
      return false;
    }
    this.timeout = setTimeout(() => {
      if (emailRegex.test(value)) {
        currentEvent.form.requestSubmit();
      }
    }, 1000);
  }

  addCustomPhone(event) {
    clearTimeout(this.timeout);
    let currentEvent = event.currentTarget;
    const value = currentEvent.value;

    const phoneRegex = /^\+?\d+$/;

    if (value.trim() === "" || !phoneRegex.test(value)) {
      return false;
    }

    this.timeout = setTimeout(() => {
      currentEvent.form.requestSubmit();
    }, 1000);
  }

  removeLeadContact(event) {
    const group = event.currentTarget.closest(".db-form-group");
    const form = event.currentTarget.closest("form");
    if (group && form) {
      group.remove();
      form.requestSubmit();
    }
  }

  closeLeadModal(event) {
    event.preventDefault();
    closeModal("manage-lead-modal");
    this.resetCheckboxes();
  }

  removeNewForm() {
    const target = document.getElementById("add-new-lead");
    if (target) {
      target.innerHTML = "";
    }
  }

  openLeadedModal() {
    single_profile_id = null;
    openModal("manage-lead-modal");
  }

  addPeopleToLead(event) {
    event.preventDefault();
    const errorMessage = document.getElementById("showErrorMessage");
    const shortlist_ids = getCheckedValues("shortlist_checkbox");

    if (shortlist_ids.length == 0) {
      errorMessage.textContent = "Please select at least one shortlist";
      return;
    }

    const form = this.addToLeadFormTarget;
    if (form) {
      const lead_ids = getCheckedValues("lead-checkbox", "data-key");
      if (lead_ids.length == 0) {
        errorMessage.textContent = "Please select at least one lead";
        return;
      } else {
        document.getElementById("lead_profile_ids").value =
          JSON.stringify(lead_ids);
        document.getElementById("assigned_shortlist_ids").value =
          JSON.stringify(shortlist_ids);
        form.requestSubmit();
      }
    }
    errorMessage.textContent = "";
  }

  assignLead(event) {
    single_profile_id = event.currentTarget.dataset.id;
    this.resetCheckboxes();
    openModal("manage-lead-modal");
  }

  onEnterLeadSubmit(event) {
    if (event.key === "Enter" && event.currentTarget.value !== "") {
      event.preventDefault();
      const input = document.getElementById("add-new-lead");
      input.innerHTML = "";
    }
  }

  submitLeadModalForm(event) {
    const form = event.currentTarget.form;
    const inputField = form.querySelector('input[id="lead_name"]');

    if (inputField.value == "") {
      event.preventDefault();
      form.reportValidity();
      return;
    }

    if (form) {
      form.requestSubmit();
      const input = document.getElementById("add-new-lead");
      input.innerHTML = "";
    }
  }

  resetCheckboxes() {
    const checkboxes = document.querySelectorAll(".lead_checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    const errorMessage = document.getElementById("showErrorMessage");
    if (errorMessage) {
      errorMessage.textContent = "";
    }
  }

  openImportCSVModal() {
    openModal("import-csv-lead-modal");
    closeModal("lead-contact-popup");
  }

  closeImportCSVModal() {
    closeModal("import-csv-lead-modal");
  }

  showImportLoader(event) {
    const file = document.getElementById("csv-file-input").files[0];

    if (!file) {
      const errorMessage = document.getElementById("inputFieldError");
      errorMessage.textContent = "Please select a file";
      event.preventDefault();
      return;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (fileExtension !== "csv") {
      const errorMessage = document.getElementById("inputFieldError");
      errorMessage.textContent = "Please upload a CSV file";
      event.preventDefault();
      return;
    }

    openModal("import-loader-popup");
    closeModal("import-csv-lead-modal");
  }

  openLeadModal() {
    single_profile_id = null;
    openModal("manage-lead-modal");
  }

  openAddToCampaignModal() {
    openModal("add_to_campaign_modal_button");
  }

  closeAddToCampaignModal() {
    closeModal("add_to_campaign_modal_button");
  }

  openAllLeadModal(el) {
    const selector = el.currentTarget;
    const dropdownID = selector?.dataset?.dropdownElementId;
    if (!selector || !dropdownID) return;

    toggleDropdown(selector, "portal--lead");
  }

  closeAllLeadModal() {
    closeModal("lead_all_filters");
  }

  openShortlistModal() {
    openModal("manage-shortlist-modal");
  }

  selectAll(event) {
    const selectAll = document.getElementById("leadAllSelect");
    if (selectAll) {
      selectAll.value = event.currentTarget.dataset.type === "select-all";
    }
  }
}
