import { Controller } from "@hotwired/stimulus";
import { toast } from "../../../components/snackbar";
import {
  openModal,
  closeModal,
  closeOnOutsideClickAndToggleShow,
} from "../../../components/modal";

import { getCheckedValues } from "../../../components/validation";
export default class extends Controller {
  static targets = ["sendRequestDropdown", "importFormSubmitButton"];

  connect() {
    this.requestFeature = "shortlist";

    document.addEventListener("click", function (event) {
      const sendRequestButton = document.getElementById("sendRequestButton");
      const sendRequestDropdown = document.getElementById(
        "sendRequestDropdown"
      );
      closeOnOutsideClickAndToggleShow(
        sendRequestButton,
        sendRequestDropdown,
        event.target
      );
    });
  }
  openSendRequestDropdown() {
    this.element.classList.toggle("open");
    if (this.sendRequestDropdownTarget) {
      this.sendRequestDropdownTarget.classList.toggle("show");
    }
  }

  requestionManualProfileAddition() {
    toast("Request successfully sent to admin", "success");
    if (this.sendRequestDropdownTarget) {
      this.sendRequestDropdownTarget.classList.toggle("show");
    }
  }

  movePersonToShortlist(event) {
    event.preventDefault();

    const errorMessage = document.getElementById("showErrorMessage");
    const shortlist_ids = getCheckedValues("shortlist_checkbox");

    if (this.requestFeature == "profile_request") {
      const shortlistProfileRequestForm = document.getElementById(
        "__profileRequestForm"
      );
      if (!shortlistProfileRequestForm) return false;

      if (shortlist_ids.length == 0) {
        errorMessage.textContent = "Please select at least one shortlist";
        return;
      }
      errorMessage.textContent = "";
      shortlistProfileRequestForm.querySelector("#__shortlist_id").value =
        shortlist_ids[0];
      shortlistProfileRequestForm.requestSubmit();
      closeModal("manage-shortlist-modal");
      toast("Request successfully sent to admin", "success");
      return false;
    }

    if (shortlist_ids.length == 0) {
      errorMessage.textContent = "Please select at least one shortlist";
      return;
    }
    this.addemailInviteParam();
    const form = document.getElementById("importedShortlistProfiles");
    if (form) {
      document.getElementById("imported_profile_shortlist_ids").value =
        JSON.stringify(shortlist_ids);
      form.requestSubmit();
      if (this.requestFeature !== "invite") {
        openModal("import-loader-popup");
        toast("Your Profiles will be added to shortlist shortly", "success");
      } else {
        toast("Invite Email has been sent to unmatched contacts", "success");
      }
      closeModal("manage-shortlist-modal");
    }
  }

  selectShortlist() {
    this.requestFeature = "profile_request";
    openModal("manage-shortlist-modal");
  }

  openShortlistModal(event) {
    const request = event.currentTarget.dataset.request;
    if (request) {
      this.requestFeature = request;
    } else {
      this.requestFeature = "shortlist";
    }
    const checkboxes = document.querySelectorAll(".shortlist_checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
      checkbox.classList.remove("db-disablebtn");
    });
    openModal("manage-shortlist-modal");
  }

  disableCheckboxes(event) {
    if (
      this.requestFeature == "invite" ||
      this.requestFeature == "profile_request"
    ) {
      const selectedCheckbox = event.currentTarget;
      const isChecked = selectedCheckbox.checked;
      const checkboxes = document.querySelectorAll(".shortlist_checkbox");
      checkboxes.forEach((checkbox) => {
        if (!isChecked) {
          checkbox.classList.remove("db-disablebtn");
        } else if (checkbox !== selectedCheckbox) {
          checkbox.classList.add("db-disablebtn");
        }
      });
    }
  }

  addemailInviteParam() {
    const request = document.getElementById("email_invite_for_csv_import");
    if (this.requestFeature === "invite") {
      request.value = this.requestFeature == "invite";
    } else {
      request.value = false;
    }
  }

  keywordSearch() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      const form = document.getElementById("csvImportSearchForm");
      if (form) {
        form.requestSubmit();
      }
    }, 1000);
  }
}
